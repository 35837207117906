import React, { memo } from 'react';

import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { getProxyManagerState } from '../../../state/proxy/proxy-manager-modal-status.atom';
import { useCurrentTheme } from '../../../state/theme.atom';
import getSelectedThemeColor from '../../../utils/get-selected-theme-color';
import { ProxyManagerContainer, ProxyManagerContent, ProxyPopper } from './styles';

interface IProxyPopoverWrapper {
  children: React.ReactNode;
  isWrappedByModal?: boolean;
}

const ProxyPopoverWrapper: React.FC<IProxyPopoverWrapper> = props => {
  const { children, isWrappedByModal = false } = props;

  const { modalView } = getProxyManagerState();
  const theme = useCurrentTheme();
  const isDarkTheme = getSelectedThemeColor(theme) === 'dark';

  return (
    <ProxyPopper className='proxy-popper'>
      <ProxyManagerContainer
        className='proxy-popper-container'
        isDarkTheme={isDarkTheme}
        isWrappedByModal={isWrappedByModal}
        isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}
      >
        <ProxyManagerContent
          newStyle={!!NEW_FEATURES.header}
          isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}
          isProxyManagerWithGroupsAndNewAddForm={!!NEW_FEATURES.proxyGroupsV2 && !!NEW_FEATURES.proxyEditForm}
          isWrappedByModal={isWrappedByModal}
          modalView={modalView}
        >
          {children}
        </ProxyManagerContent>
      </ProxyManagerContainer>
    </ProxyPopper>
  );
};

export default memo(ProxyPopoverWrapper);
function useGeoproxy(): { modalView: any } {
  throw new Error('Function not implemented.');
}
