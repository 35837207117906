import React from 'react';
import { Trans } from 'react-i18next';

import { determineIsProxyCheckFailed } from '../../../../../../common/proxy/utils';
import { NEW_FEATURES } from '../../../../../state/feature-toggle/new-features';
import { ProxyContextMenuProxyLocation } from '../../../../../state/proxy-select-menu.atom';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../../../state/proxy/proxy-check/constants';
import { useIsProxyRestoring } from '../../../../../state/proxy/proxy-check/proxy-statuses.atom';
import { IGroupedProxy } from '../../../../../state/proxy/proxy-groups/types';
import { openProxyManager } from '../../../../../state/proxy/proxy-manager-modal-status.atom';
import TooltipWrapper from '../../../../../ui/tooltip-custom/tooltip-wrapper';
import CheckProxyBtn from '../../../check-proxy-button';
import ProxyEditButton from '../../../proxy-edit-button';
import { getIsProxyArchived, getIsProxyEditable, makeProxyTitle, restoreProxy } from '../../../proxy-helpers';
import ProxyItemCheckbox from '../proxy-item-checkbox';
import ProxyProfilesCounter from '../proxy-profiles-counter';
import { GroupedProxyStatusWrapper, ProxyInteractiveProfilesCounterWrapper } from '../styles';
import { ProxyTitleWrapper } from '../ungrouped-proxy-item/styles';
import GeoProxyTypeIcon from './geoproxy-type-icon';
import { GroupedProxyCheckboxWrapper, GroupedProxyItemWrapper } from './styles';

type GroupedProxyItemProps = {
  groupedProxy: IGroupedProxy;
  currentProfileId: string | null;
  isCheckboxVisible: boolean;
  handleRowClick: React.MouseEventHandler<HTMLDivElement>;
  handleContextMenu: (
    event: React.MouseEvent<Element>,
    proxyContextMenuProxyLocation: ProxyContextMenuProxyLocation,
  ) => void;
  localProxyElementLocation: string | null;
  selectorContainerElement?: React.RefObject<HTMLDivElement>;
  style: React.CSSProperties;
};

const GroupedProxyItem: React.FC<GroupedProxyItemProps> = props => {
  const {
    groupedProxy,
    currentProfileId,
    isCheckboxVisible,
    handleRowClick,
    handleContextMenu,
    localProxyElementLocation,
    selectorContainerElement,
    style,
  } = props;

  const isProxyRestoring = useIsProxyRestoring(groupedProxy.id);

  const title = makeProxyTitle(groupedProxy);
  const isProxyEditable = getIsProxyEditable(groupedProxy);
  const isCheckFailed = determineIsProxyCheckFailed(groupedProxy);

  const renderLeftControls = (): JSX.Element => {
    if (isCheckboxVisible) {
      return (
        <GroupedProxyCheckboxWrapper>
          <ProxyItemCheckbox proxy={groupedProxy} isCheckboxVisible={true} />
        </GroupedProxyCheckboxWrapper>
      );
    }

    return (
      <GroupedProxyStatusWrapper>
        <CheckProxyBtn
          proxy={groupedProxy}
          profileId={currentProfileId || ''}
          proxyTooltipView={PROXY_CHECK_TOOLTIP_LOCATIONS.proxyGroupedListItem}
          selectorContainerElement={selectorContainerElement}
        />
      </GroupedProxyStatusWrapper>
    );
  };

  const renderRightControls = (): JSX.Element => {
    const profilesCounter = groupedProxy.profilesCount || 0;

    const handleProxyInfoEditClick = async (): Promise<void> => {
      let proxyForEdit = groupedProxy;
      if (getIsProxyArchived(groupedProxy)) {
        proxyForEdit = await restoreProxy(groupedProxy);
      }

      openProxyManager({
        modalView: 'proxy-edit',
        currentProxy: proxyForEdit,
        currentProfileId,
        modalEditingProxyId: groupedProxy.id,
        proxySelectorLocation: localProxyElementLocation,
      });
    };

    return (
      <ProxyInteractiveProfilesCounterWrapper
        style={{ marginLeft: 10 }}
        onClick={(event): void => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        {isProxyEditable ? (
          <ProxyEditButton handleProxyInfoEditClick={handleProxyInfoEditClick} isProxyRestoring={isProxyRestoring} />
        ) : null}
        <ProxyProfilesCounter profilesCounter={profilesCounter} isProxyEditable={true} />
      </ProxyInteractiveProfilesCounterWrapper>
    );
  };

  return (
    <GroupedProxyItemWrapper
      style={style}
      isAlwaysOpaque={false}
      onClick={handleRowClick}
      onContextMenu={(event): void => handleContextMenu(event, PROXY_CHECK_TOOLTIP_LOCATIONS.proxyGroupedListItem)}
      isFailed={isCheckFailed}
    >
      {renderLeftControls()}
      <TooltipWrapper
        isTextEllipsis={false}
        hasTooltipAnimation={true}
        value={<Trans i18nKey={`proxyManager.connectionTypeTooltip.${groupedProxy.connectionType}`} />}
        placement='top'
        style={{ marginBottom: 8 }}
      >
        <GeoProxyTypeIcon id={groupedProxy.id} connectionType={groupedProxy.connectionType} />
      </TooltipWrapper>
      <ProxyTitleWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2} isSemiTransparent={isCheckFailed}>
        {title}
      </ProxyTitleWrapper>
      {renderRightControls()}
    </GroupedProxyItemWrapper>
  );
};

export default GroupedProxyItem;
