import { atom, getDefaultStore, useAtomValue } from 'jotai';

import {
  E_CURRENCY,
  E_PAYMENT_COUNTRY,
  E_PERIOD,
  IPaymentTabConfig,
  IPriceConfig,
  IPricingConfig,
  IPricingConfigCountry,
  LimitName,
  LimitRange,
  PaymentType,
  PlansAddonPricingConfig,
  TabShowCondition,
  TabState,
  TCurrency,
  TPaymentCountry,
  TPaymentTab,
} from '../../features/pricing/interfaces';
import { useHasAddonLimitsChosen, useSelectedDiscount, useSelectedPlan } from './pricing-page.atom';
import { ANNUAL_DISCOUNT, MONTH_DISCOUNT } from '../../features/modalsComponents/components/checkout-modal/constants';
import { getFirstPaymentTab } from '../../features/modalsComponents/components/checkout-modal/utils';
import { useIsUpgradeDiscountAvaliable } from './upgrade-discount.atom';
import { useHasUserEverPaid } from './has-user-ever-paid.atom';

const paymentModalPricingConfigAtom = atom<IPricingConfig>({} as IPricingConfig);
const paymentModalPaymentTypeAtom = atom<TPaymentTab>(PaymentType.Card);
const paymentModalPaymentCountryAtom = atom<TPaymentCountry>(E_PAYMENT_COUNTRY.ANY);
const plansAddonPricingConfigAtom = atom<PlansAddonPricingConfig>({} as PlansAddonPricingConfig);

const currentTabShownCurrencyAtom = atom((get) => {
  const paymentType = get(paymentModalPaymentTypeAtom);
  const paymentCountry = get(paymentModalPaymentCountryAtom);
  const pricingConfig = get(paymentModalPricingConfigAtom).pricingPageConfig;

  return pricingConfig[paymentCountry].tabs.find(tab => tab.type === paymentType)?.currency || E_CURRENCY.USD;
});

const checkoutCurrencyAtom = atom<TCurrency>((get) => {
  const paymentCountry = get(paymentModalPaymentCountryAtom);
  const pricingConfig = get(paymentModalPricingConfigAtom).pricingPageConfig;

  return pricingConfig[paymentCountry].checkoutCurrency;
});

const priceConfigAtom = atom<IPriceConfig>((get) => {
  const { currencyFactors, currencyLocales, currencyFractionDigits, minimumAmounts } = get(paymentModalPricingConfigAtom);

  return {
    currencyFactors,
    currencyLocales,
    currencyFractionDigits,
    minimumAmounts,
  };
});

export const usePriceConfig = (): IPriceConfig => useAtomValue(priceConfigAtom);
export const usePlansAddonPricingConfig = (): PlansAddonPricingConfig => useAtomValue(plansAddonPricingConfigAtom);
export const usePaymentModalPricingConfig = (): IPricingConfig => useAtomValue(paymentModalPricingConfigAtom);
export const usePaymentModalPaymentType = (): TPaymentTab => useAtomValue(paymentModalPaymentTypeAtom);
export const usePaymentModalPaymentCountry = (): TPaymentCountry => useAtomValue(paymentModalPaymentCountryAtom);
export const useCheckoutCurrency = (): TCurrency => useAtomValue(checkoutCurrencyAtom);
export const useCurrentTabShownCurrency = (): TCurrency => useAtomValue(currentTabShownCurrencyAtom);

export const usePaymentTabsState = (): TabState => {
  const selectedDiscount = useSelectedDiscount();
  const selectedPlan = useSelectedPlan();
  const isPurchaseWithAddonLimits = useHasAddonLimitsChosen(selectedPlan);
  const hasUserEverPaid = useHasUserEverPaid();
  const isUpgrade = useIsUpgradeDiscountAvaliable();

  const pricingTabsState: TabState = {
    selectedPeriod: selectedDiscount === ANNUAL_DISCOUNT ? E_PERIOD.ANNUAL : E_PERIOD.MONTHLY,
    isUpgrade, 
    hasUserEverPaid,
    isPurchaseWithAddonLimits,
  };

  return pricingTabsState;
};

export const useModalShownTabsConfig = (): IPricingConfigCountry => {
  const { pricingPageConfig } = usePaymentModalPricingConfig();
  const paymentType = usePaymentModalPaymentType();
  const country = usePaymentModalPaymentCountry();
  const paymentTabsState = usePaymentTabsState();
 
  const { tabs, checkoutCurrency } = pricingPageConfig[country];
  const countryPricingConfig = { checkoutCurrency, tabs };
  const availablePaymentTabs = getAvailablePaymentTabs(pricingPageConfig[country], paymentTabsState);
  countryPricingConfig.tabs = availablePaymentTabs;
  setPaymentTypeCustomTabs(availablePaymentTabs, paymentType);

  return countryPricingConfig;
};

const doesConditionMatch = (condition: TabShowCondition, pricingState: TabState): boolean => {
  for (const key in condition) {
    if (key !== 'visible' && condition[key as keyof TabShowCondition] !== pricingState[key as keyof TabState]) {
      return false;
    }
  }

  return true;
}

const getAvailablePaymentTabs = (tabConfig: IPricingConfigCountry, state: TabState): IPaymentTabConfig[] => {
  return tabConfig.tabs.filter(tab => {
    const { showConditions = [] } = tab;
    if (!showConditions.length) {
      return true;
    }

    let shouldShowTab = false;
    let noRestrictions = true;

    showConditions.forEach(condition => {
      const show = doesConditionMatch(condition, state);

      if (condition.visible) {
        shouldShowTab ||= show;
      } else {
        noRestrictions &&= !show;
      }
    });

    return shouldShowTab && noRestrictions;
  });
};



const setPaymentTypeCustomTabs = (tabs: IPaymentTabConfig[], paymentType: PaymentType): void => {
  const hasPaymentType = tabs.find(tab => tab.type === paymentType);
  const firstTabPaymentType = getFirstPaymentTab(tabs); 
  const paymentTypeForCustomTabs = hasPaymentType ? paymentType : firstTabPaymentType;
  setPaymentModalPaymentType(paymentTypeForCustomTabs);
};

export const getLimitRangeByPlanAndLimit = (planId: string, limitName: LimitName): LimitRange|null => {
  const limitsPricingSettings = getPlansAddonPricingConfig();
  const planLimitsPricingSettings = limitsPricingSettings[planId];
  if (!planLimitsPricingSettings) {
    return null;
  }

  const { limits } = planLimitsPricingSettings;
  if (limits[limitName]) {
    return limits[limitName];
  }

  return null;
};

export const getCurrentTabShownCurrency = (): TCurrency => getDefaultStore().get(currentTabShownCurrencyAtom);
export const setPaymentModalPaymentType = (paymentType: TPaymentTab): void => getDefaultStore().set(paymentModalPaymentTypeAtom, paymentType);
export const getPaymentModalPaymentType = (): TPaymentTab => getDefaultStore().get(paymentModalPaymentTypeAtom);
export const getModalShownTabsConfig = (country: TPaymentCountry): IPricingConfigCountry => {
  const pricingConfig = getDefaultStore().get(paymentModalPricingConfigAtom).pricingPageConfig;

  return pricingConfig[country];
};

export const getPlansAddonPricingConfig = (): PlansAddonPricingConfig => getDefaultStore().get(plansAddonPricingConfigAtom);
export const setPlansAddonPricingConfig = (data: PlansAddonPricingConfig): void => getDefaultStore().set(plansAddonPricingConfigAtom, data);

export const getCheckoutCurrency = (): TCurrency => getDefaultStore().get(checkoutCurrencyAtom);
export const setPricingConfig = (data: IPricingConfig): void => getDefaultStore().set(paymentModalPricingConfigAtom, data);
export const getPaymentModalPaymentCountry = (): TPaymentCountry => getDefaultStore().get(paymentModalPaymentCountryAtom);
export const getPricingConfig = (): IPricingConfig => getDefaultStore().get(paymentModalPricingConfigAtom);
export const setPaymentModalPaymentCountry = (data: string): void => {
  if (!data) {
    getDefaultStore().set(paymentModalPaymentCountryAtom, E_PAYMENT_COUNTRY.ANY);
  }

  const paymentCountry = data.toLowerCase() as TPaymentCountry;
  const pricingConfig = getDefaultStore().get(paymentModalPricingConfigAtom).pricingPageConfig;
  if (!pricingConfig) {
    return;
  }

  const COUNTRIES = Object.keys(pricingConfig);

  if (COUNTRIES.includes(paymentCountry)) {
    getDefaultStore().set(paymentModalPaymentCountryAtom, paymentCountry);
    const paymentType = getFirstPaymentTab(pricingConfig[paymentCountry].tabs);
    setPaymentModalPaymentType(paymentType);

    return;
  }

  getDefaultStore().set(paymentModalPaymentCountryAtom, E_PAYMENT_COUNTRY.ANY);
};

export const useDefaultCountryPaymentType = (): PaymentType => {
  const { pricingPageConfig } = usePaymentModalPricingConfig();
  const paymentCountry = usePaymentModalPaymentCountry();

  return getFirstPaymentTab(pricingPageConfig[paymentCountry].tabs);
};
