import { purgeAppCache } from '../object-pool/idb-cache/meta-db';
import { getCurrentWorkspaceId } from '../state/current-workspace-id.atom';
import { prepareDbgFeatureDef } from './feature';
import { log } from './log';
import { prepareDbgMonitorDef } from './monitor';

export const defineDbg = (): void => {
  (window as any).dbg = {
    wid: () => getCurrentWorkspaceId(),
    idbCache: { purgeAll: async (): Promise<void> => {
      await purgeAppCache();

      log('purged all idb cache');
    } },
    feature: prepareDbgFeatureDef(),
    monitor: prepareDbgMonitorDef(),
  };

  // TODO: add help
  log('defined window.dbg');
};
