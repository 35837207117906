import { atomWithStorage } from 'jotai/utils';
import { getDefaultStore, useAtomValue } from 'jotai';
import { SHOULD_SHOW_PROXY_TYPE_KEY_NAME } from '../../common/constants/constants';

export const ShouldShowType = ['direct', 'geolocation', 'custom'] as const;

type ProxyType = typeof ShouldShowType[number];

export const shouldShowProxyType = atomWithStorage<ProxyType>(SHOULD_SHOW_PROXY_TYPE_KEY_NAME, 'geolocation');

export const useShouldShowProxyType = (): ProxyType => useAtomValue(shouldShowProxyType);

export const setShouldShowProxyType = (value: ProxyType): void => {
  getDefaultStore().set(shouldShowProxyType, value);
};
