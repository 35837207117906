import React from 'react';
import { useTranslation } from 'react-i18next';

import { updateProxyForm, useProxyForm } from '../../../../../../state/proxy/custom-proxy-form-data.atom';
import { HostPortSplitter, ProxyFormInputEl } from '../../styles';
import { CustomProxyPastableInputProps } from '../custom-proxy-string-input';
import { useInputWidth } from '../../../../../../hooks/use-input-width.hook';

const HOST_AND_PORT_SEPARATOR = ':';

const CustomProxyHostAndIpInput: React.FC<CustomProxyPastableInputProps> = props => {
  const { handlePaste } = props;

  const customProxyForm = useProxyForm();
  const hostWidth = useInputWidth(customProxyForm.host, 82, 101);
  const { t: translation } = useTranslation();

  const hostPlaceholder = translation('proxies.formFields.hostPlaceholder');
  const portPlaceholder = translation('proxies.formFields.portPlaceholder');

  const handleHostChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const newHost = event.target.value;
    updateProxyForm({ host: newHost });
  };

  const handlePortChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const newPort = event.target.value;
    updateProxyForm({ port: newPort });
  };
  

  return (
    <>
      <ProxyFormInputEl
        value={customProxyForm.host}
        name="host"
        onChange={handleHostChange}
        onPaste={handlePaste}
        placeholder={hostPlaceholder}
        inputWidth={101} 
        style={{ 
          width: `${hostWidth}px`,
          boxSizing: 'border-box'
        }}
      />
      <HostPortSplitter>{HOST_AND_PORT_SEPARATOR}</HostPortSplitter>
      <ProxyFormInputEl
        value={String(customProxyForm.port)}
        name="port"
        onChange={handlePortChange}
        onPaste={handlePaste}
        placeholder={portPlaceholder}
        inputWidth={41}
      />
    </>
  );
};

export default CustomProxyHostAndIpInput;
