import styled from '@emotion/styled';
import React, { memo } from 'react';
import { Trans } from 'react-i18next';

import GroupsTableCustomizationMenu from './groups-table-customization-menu';
import {
  closeTableCustomization,
  openGroupsTableCustomization,
  useTableCustomizationMenu,
} from './table-customization-menu-state.atom';
import { useProfilesTableGroupField } from '../../../state/profiles-table/profiles-table-group-field.atom';
import { openProfilesTableModal, PROFILE_TABLE_COLUMNS_MODAL_NAME } from '../../../state/profiles-table-modal.atom';
import { IconArrow, IconColumns, IconGroups } from '../../../ui/gologin-header/icons';
import { IconWrapper, typeColorIcon } from '../../../ui/gologin-header/icons/wrapper';
import GologinPopover from '../../../ui/gologin-popover';
import { GologinPopoverItemRow } from '../../../ui/gologin-popover/gologin-popover-item-row';
import { sendActionAnalytics } from '../../common/api';

const ItemRowArrowContainer = styled('div')`
  display: flex;
  gap: 4px;
  align-items: center;
  color: var(--B5B5BA);
`;

const itemRowIconProps: { padding: number; styleType: typeColorIcon; iconColor: string } = {
  padding: 0,
  styleType: 'lightGray',
  iconColor: 'var(--B5B5BA)',
};

export const TABLE_CUSTOMIZATION_POPOVER_WIDTH = 183;

interface ICustomizationOption {
  icon: JSX.Element;
  titleKey: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  isSelected?: boolean;
  hasArrow?: boolean;
  arrowTextKey?: string;
}

const TableCustomizationMenu = (): JSX.Element => {
  const { rootAnchorEl, groupAnchorEl } = useTableCustomizationMenu();
  const currentGroupField = useProfilesTableGroupField();
  let groupArrowTextKey: string;
  switch (currentGroupField) {
    case 'custom-status':
      groupArrowTextKey = 'profiles.tableSettings.groupStatus';
      break;
    case 'folder':
      groupArrowTextKey = 'profiles.tableSettings.groupFolder';
      break;
    default:
      groupArrowTextKey = '';
  }

  const onColumnsClick = (): void => {
    closeTableCustomization();
    openProfilesTableModal(PROFILE_TABLE_COLUMNS_MODAL_NAME);
    sendActionAnalytics('opened edit columns').catch(() => null);
  };

  const customizationOptions: ICustomizationOption[] = [
    {
      icon: <IconColumns {...itemRowIconProps} />,
      titleKey: 'profiles.tableSettings.columns',
      onClick: onColumnsClick,
    },
    {
      icon: <IconGroups {...itemRowIconProps} />,
      titleKey: 'profiles.tableSettings.group',
      onClick: openGroupsTableCustomization,
      isSelected: !!groupAnchorEl,
      hasArrow: true,
      arrowTextKey: groupArrowTextKey,
    },
  ];

  return (
    <>
      <GologinPopover
        anchorEl={rootAnchorEl}
        onClose={closeTableCustomization}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        width={TABLE_CUSTOMIZATION_POPOVER_WIDTH}
        extraTranslate={{
          bottom: 8,
        }}
      >
        {customizationOptions.map(({ icon, titleKey, onClick, isSelected, hasArrow, arrowTextKey }) => (
          <GologinPopoverItemRow
            onClick={onClick}
            isSelected={isSelected}
            key={titleKey}
            style={{ gap: 8, padding: '6px 8px 6px 12px' }}
          >
            {icon}
            <div style={{ flex: '1' }}>
              <Trans i18nKey={titleKey} />
            </div>
            {hasArrow ? (
              <ItemRowArrowContainer>
                {arrowTextKey ? (
                  <div>
                    <Trans i18nKey={arrowTextKey} />
                  </div>
                ) : null}
                <IconWrapper
                  iconColor='var(--B5B5BA)'
                  iconType='stroke'
                  padding={0}
                >
                  <IconArrow padding='3px 5px' />
                </IconWrapper>
              </ItemRowArrowContainer>
            ) : null}
          </GologinPopoverItemRow>
        ))}
      </GologinPopover>
      <GroupsTableCustomizationMenu />
    </>
  );
};

export default memo(TableCustomizationMenu);
