import { Icon, message, Radio, Select } from 'antd';
import { RadioChangeEvent } from 'antd/es/radio';
import { OptionProps } from 'antd/es/select';
import React, { ReactElement, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import GeoProxyTypeRadioButton from './geoproxy-type-radio-button';
import { GeoProxyType, ORDERED_GEO_PROXY_TYPES_FOR_EDIT_BROWSER_PAGE } from '../../../../../common/constants/types';
import { calculateRemainingAllTypesTrafficLimitsObject } from '../../../../../common/proxy/traffic/utils';
import { IProfile } from '../../../../interfaces';
import { useIsTrafficDataLoading, useTrafficData } from '../../../../state/proxy/traffic-data.atom';
import { generateGeoProxyCustomName } from '../../../proxy/proxy-helpers';
import { getVpnUfoCountries, getVpnUfoProxy } from '../api';
import ProxyChecker from '../components/proxy-checker';
import {
  LoaderDivProxy,
  Component,
  GetProxyBtn,
  InLineComponent,
  RowComponent,
  SubTitle,
} from '../domElements';
import { IGetProxy, IProxyChecked } from '../interfaces';
import { checkProxy, PROXY_CHECKED_INIT } from '../utils/check-proxy';
import { VpnUfoProxyOptions } from '../vpn-ufo.types';
import { useGeoProxyCountriesAvailability } from '../../../../state/proxy/geoproxy-countries.atom';

const { Option } = Select;

export type GeolocationProxyFormProps = {
  profileId?: string;
  profileInfo: IProfile;
  geolocationProxy: any;
  isCreateProfile: boolean;
  isSaveButtonPressed?: boolean;
  saveProfile?: () => void;
  updateProfileInfo: (params: any) => any;
  setIsGeolocationScreen?: (arg: boolean) => void;
  setCurrentDisplay: (params: 'form'|'result') => void;
  setProxyCurrent: (params: any) => void;
  setInitialCheck: (params: boolean) => void;
  proxyLoading: boolean;
  setProxyLoading: (params: boolean) => void;
  connection: GeoProxyType | null;
  setConnection: (geoProxyType: GeoProxyType | null) => void;
  currentDisplay: 'result'|'form';
  currentProxyCountry: string;
  setCurrentProxyCountry: (arg: string) => void;
}

export const GeolocationProxyForm: React.FC<GeolocationProxyFormProps> = (props) => {
  const {
    profileInfo, profileId, updateProfileInfo, setCurrentDisplay, setProxyCurrent, setInitialCheck, proxyLoading, setProxyLoading,
    isSaveButtonPressed, geolocationProxy, setIsGeolocationScreen, saveProfile, isCreateProfile, connection, setConnection,
    currentDisplay, currentProxyCountry, setCurrentProxyCountry,
  } = props;

  const { availableProxies } = useGeoProxyCountriesAvailability();

  const [country, setCountry] = useState<string>('');
  const [disableSelects, setDisableSelects] = useState<boolean>(true);
  const [proxyChecked, setProxyChecked] = useState<IProxyChecked>(PROXY_CHECKED_INIT);
  const [countryOptions, setCountryOptions] = useState<JSX.Element[]>([]);

  const { t: translation } = useTranslation();

  const trafficData = useTrafficData();
  const isTrafficDataLoading = useIsTrafficDataLoading();

  const remainingTrafficObject = calculateRemainingAllTypesTrafficLimitsObject(trafficData);

  const [
    hasResidentTraffic,
    hasDataCenterTraffic,
    hasMobileTraffic,
  ] = [
    !!remainingTrafficObject[GeoProxyType.Resident],
    !!remainingTrafficObject[GeoProxyType.DataCenter],
    !!remainingTrafficObject[GeoProxyType.Mobile],
  ];

  useEffect(() => {
    if (proxyLoading) {
      return;
    }

    if (isTrafficDataLoading) {
      return setDisableSelects(true);
    }

    const NO_TRAFFIC_GEOPROXY_TYPE = GeoProxyType.Resident;
    const preferableGeoProxyTypeWithRemainingTraffic = ORDERED_GEO_PROXY_TYPES_FOR_EDIT_BROWSER_PAGE
      .find(type => !!remainingTrafficObject[type]) || NO_TRAFFIC_GEOPROXY_TYPE;

    setConnection(preferableGeoProxyTypeWithRemainingTraffic);
    setCurrentProxyCountry(geolocationProxy.country);
    if (countryOptions.length) {
      setDisableSelects(false);
    }
  }, [isTrafficDataLoading]);

  useEffect(() => {
    if (!connection) {
      return;
    }

    getCountryList().catch(() => null);
  }, [connection]);

  // Весь треш, который происходит в этом компоненте связан с одной фичей - создать geo прокси если юзер выбрал меню
  // gologin proxy и нажал save или create profile, не находя прокси вручную. Достигается это следующим методом - мы нажимаем на save и функция
  // saveProfile не отрабатывает, потому что мы делаем там return в зависимости от условий. Условий 2 штуки - мы находимся
  // на экране gologin proxy и у нас открыта форма выбора прокси. после того как мы сделали return в saveProfile, в случае с
  // vpnUfo мы ждем когда создастся прокси и вызываем saveProfile еще раз из этого компонента, чтобы сохранить в профиль.
  // В случае с luminati - мы делаем фейковую прокси на беке и вызываем saveProfile(это изза того, что люминати прокси - долго ищутся),
  // затем на беке находится нормальная прокси и подставляется в профиль

  useEffect(() => {
    if (isSaveButtonPressed && isCreateProfile) {
      updateProfileStateAndCreate().catch(() => null);

      return;
    }

    if (!isSaveButtonPressed || (geolocationProxy.country && (currentDisplay === 'result' || disableSelects))) {

      return;
    }

    generateGeoProxy().then(() => {
      setIsGeolocationScreen && setIsGeolocationScreen(false);
      saveProfile && saveProfile();
    });
  }, [isSaveButtonPressed]);

  const updateProfileStateAndCreate = async (): Promise<void> => {
    await updateProfileInfo({
      geoProxyInfo: {
        connection,
        country,
      },
    });

    saveProfile && saveProfile();
  };

  const filterOption = (input: string, option: ReactElement<OptionProps>): boolean =>
    (option.props.value || '').toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    (option.props.children || '').toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const generateGeoProxy = async (event: any = {}, options: IGetProxy = {}, isWithCheck = true): Promise<any> => {
    setDisableSelects(true);
    setProxyLoading(true);
    setProxyChecked(PROXY_CHECKED_INIT);
    event.stopPropagation && event.stopPropagation();
    event.preventDefault && event.preventDefault();

    isWithCheck && updateProfileInfo({
      geoProxyInfo: {
        isLoading: true,
      },
    });

    let optionsToCheckProxy = {};
    const isCurrentProxyCountryAvailable = countryOptions.find((option) => option.key === currentProxyCountry);
    const countryCode = isCurrentProxyCountryAvailable ? currentProxyCountry : country;
    const customName = generateGeoProxyCustomName(countryCode);
    const parameters: VpnUfoProxyOptions = {
      countryCode,
      browserId: profileId || '',
      isMobile: connection === GeoProxyType.Mobile,
      isDC: connection === GeoProxyType.DataCenter,
      customName,
    };

    const resultProxy = await getVpnUfoProxy(parameters).catch((error: any) =>
      error.message || error.body?.message,
    );

    if (options.isFake) {
      return resultProxy;
    }

    if (options.isQuickSave) {
      return;
    }

    if (!(resultProxy && Object.keys(resultProxy).length && typeof resultProxy !== 'string')) {
      message.error(resultProxy);
      setDisableSelects(false);
      setProxyLoading(false);

      return;
    }

    const { proxy } = profileInfo;
    updateProfileInfo({
      proxyEnabled: true,
      proxy: {
        ...proxy,
        ...resultProxy,
        mode: 'geolocation',
      },
    });

    optionsToCheckProxy = {
      proxy: resultProxy,
      setProxyChecked: (res: any) => setProxyCurrent({
        ...res,
        connectionType: connection,
        trafficUsed: resultProxy.trafficUsed || '',
        trafficLimit: resultProxy.trafficLimit || 0,
      }),
    };

    const processCbs = (): null => null;
    const resCbs = (): void => {
      setDisableSelects(false);
      setProxyLoading(false);
      isWithCheck && updateProfileInfo({
        geoProxyInfo: {
          isLoading: false,
        },
      });
    };

    const successCbs = (): void => {
      setCurrentDisplay('result');
      setInitialCheck(true);
    };

    checkProxy(optionsToCheckProxy, processCbs, resCbs, translation, successCbs);
  };

  const changeProxyCountry = (countryCode: string): void => {
    setCurrentProxyCountry('');
    setCountry(countryCode);
  };

  const getCountryList = async (): Promise<void> => {
    const countriesSearchParams = new URLSearchParams();
    switch (connection) { 
      case GeoProxyType.DataCenter:
        countriesSearchParams.append('isDC', 'true');
        break;
      default:
        break;
    }

    setDisableSelects(true);
    const countryList = await getVpnUfoCountries(countriesSearchParams);
    const [firstCountry = { countryCode: 'no options available' }] = countryList;
    const { countryCode: firstCountryCode } = firstCountry;
    setCountry(firstCountryCode);
    const countryOptionsArray: JSX.Element[] = countryList.map((countryFromRequest) => (
      <Option
        key={countryFromRequest.countryCode}
        value={countryFromRequest.countryCode}
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {countryFromRequest.countryName}
      </Option>
    ));

    setCountryOptions(countryOptionsArray);
    setDisableSelects(false);
  };

  const getContent = (): JSX.Element => {
    if (disableSelects) {
      return (
        <LoaderDivProxy>
          <Icon type='loading' />
        </LoaderDivProxy>
      );
    }

    const isCurrentProxyCountryAvailable = countryOptions.find((option) => option.key === currentProxyCountry);

    return (
      <div>
        <div style={{ marginBottom: '20px' }}>
          <RowComponent>
            <InLineComponent>
              <SubTitle>
                <Trans i18nKey='base.country' />
              </SubTitle>
              <Select
                style={{ width: 188 }}
                value={isCurrentProxyCountryAvailable ? currentProxyCountry : country}
                onChange={(targetCountry: string): void => changeProxyCountry(targetCountry)}
                showSearch={true}
                filterOption={filterOption}
                disabled={disableSelects}
              >
                {countryOptions}
              </Select>
            </InLineComponent>
          </RowComponent>
          <Component>
            <SubTitle>
              <Trans i18nKey='base.type' />
            </SubTitle>
            <Radio.Group
              value={connection}
              disabled={disableSelects}
              onChange={(event: RadioChangeEvent): void => setConnection(event.target.value)}
            >
              <GeoProxyTypeRadioButton
                geoProxyType={GeoProxyType.Resident}
                isDisabled={!hasResidentTraffic}
              />
              <GeoProxyTypeRadioButton
                geoProxyType={GeoProxyType.Mobile}
                isDisabled={!hasMobileTraffic}
                style={{ width: 100 }}
              />
              <GeoProxyTypeRadioButton
                geoProxyType={GeoProxyType.DataCenter}
                isDisabled={!hasDataCenterTraffic}
              />
            </Radio.Group>
          </Component>
          <Component style={{ flexDirection: 'row', alignItems: 'center' }}>
            <GetProxyBtn
              type='primary'
              onClick={generateGeoProxy}
              disabled={disableSelects}
            >
              <Trans i18nKey='proxy.btnSaveProxy' />
            </GetProxyBtn>
          </Component>
        </div>
        <ProxyChecker
          isLoading={false}
          proxyChecked={proxyChecked}
          setCurrentDisplay={setCurrentDisplay}
          geolocationShowOption={true}
          initialCheck={false}
          setProxyChecked={setProxyCurrent}
        />
      </div>
    );
  };

  return getContent();
};
