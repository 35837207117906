import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { selectItems } from './select-items';
import { IFullProfile, IProxy, IGeolocation } from '../../../../../interfaces';
import { useLocalUserDevice } from '../../../../../state/local-user-device.atom';
import { useProxyList } from '../../../../../state/proxy/proxy-list.atom';
import { InputTitle, SettingsInput } from '../../../../../ui/closeable-input/styles';
import { ModernSelect } from '../../../../../ui/modern-select';
import { GEOLOCATION_DATA } from '../../../../common/geolocations';
import { getTimezoneByBasedIp } from '../../../../common/get-data-by-based-ip';
import { IGetTimezoneByBasedIp } from '../../../../common/get-data-by-based-ip/interfaces';
import { ContainerParams, ContainerSpaceBetween, ContainerSubRow, DrawerParams, GreyDarkTextContainer } from '../../../styles';

interface IGeolocationComponent {
  profileGeolocation: IGeolocation;
  changeSetting: (profileData: Partial<IFullProfile>) => void;
  proxy: IProxy;
  isFreeOrTorProxy: boolean;
}

const Geolocation: FC<IGeolocationComponent> = (props) => {
  const { changeSetting, profileGeolocation, proxy, isFreeOrTorProxy } = props;

  const localUserDevice = useLocalUserDevice();

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [data, setData] = useState<IGetTimezoneByBasedIp>({ timezone: '', country: '' });
  const [geolocation, setGeolocation] = useState<IGeolocation>(profileGeolocation);

  const proxyList = useProxyList();

  const { t: translation } = useTranslation();
  const proxyFromCtx = proxyList.find(listedProxy => listedProxy.id === proxy.id);

  const getData = (): void => {
    const timezoneLocation = getTimezoneByBasedIp({
      proxy,
      translation,
      proxyFromCtx,
      localUserDevice,
    });

    setData(timezoneLocation);
  };

  useEffect(() => {
    getData();
  }, [proxy, proxyFromCtx, isFreeOrTorProxy, localUserDevice.timezone]);

  useEffect(() => {
    setGeolocation(profileGeolocation);
  }, [profileGeolocation]);

  const automaticTitle = translation('quickSettings.parameters.basedIP');
  const hasOpenCoordinates = geolocation.isCustomCoordinates;

  const saveGeolocation = (): void => {
    const { latitude = 0, longitude = 0 } = geolocation;
    const minAccuracy = 10;
    const maxAccuracy = 100;
    let { accuracy = minAccuracy } = geolocation;

    if (accuracy < minAccuracy) {
      accuracy = minAccuracy;
    }

    if (accuracy > maxAccuracy) {
      accuracy = maxAccuracy;
    }

    changeSetting({
      geolocation: {
        ...geolocation,
        accuracy,
        latitude,
        longitude,
      },
    });
  };

  const onKeyPressInput = (event: React.KeyboardEvent): void => {
    if (event.key !== 'Enter') {
      return;
    }

    saveGeolocation();
  };

  const onGeolocationChanged = (type: 'latitude'|'longitude'|'accuracy', event: ChangeEvent<HTMLInputElement>): void => {
    const regex = /^-?\d*\.?\d*$/;
    const { value } = event.target;
    if (!regex.test(value)) {
      return;
    }

    setGeolocation({ ...geolocation, [type]: value });
  };

  const isFillBasedOnIp = (value: string): boolean => {
    if (value === 'automatic') {
      return true;
    }

    if (value !== 'block') {
      return false;
    }

    return !!geolocation.fillBasedOnIp;
  };

  const onChangeMode = (type: string): void => {
    if (!type) {
      return;
    }

    const isCustomCoordinates = type === 'custom coordinates';
    setIsEdit(isCustomCoordinates);
    const selectedLocation = GEOLOCATION_DATA.find(({ city }) => city === type);
    let { latitude, longitude } = geolocation;
    if (type !== 'block' && type !== 'automatic' && selectedLocation) {
      const { latitude: latitudeFromFoundLocation, longitude: longitudeFromFoundLocation } = selectedLocation;
      latitude = latitudeFromFoundLocation;
      longitude = longitudeFromFoundLocation;
    }

    changeSetting({
      geolocation: {
        mode: type === 'block' ? 'block' : 'prompt',
        fillBasedOnIp: isFillBasedOnIp(type),
        isCustomCoordinates,
        latitude,
        longitude,
      },
    });
  };

  const renderGeolocationData = (): JSX.Element|null => {
    if (!(isEdit && hasOpenCoordinates)) {
      return null;
    }

    const geolocationRenderData: { type: 'latitude'|'longitude'|'accuracy' }[] = [
      { type: 'latitude' },
      { type: 'longitude' },
      { type: 'accuracy' },
    ];

    return (
      <ContainerSubRow
        hasMarginTop={true}
        hasGap={true}
        onClick={(event): void => event.stopPropagation()}
      >
        {geolocationRenderData.map(({ type }) => (
          <>
            <InputTitle hasMarginTop={true}>
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </InputTitle>
            <SettingsInput
              onChange={(event): void => onGeolocationChanged(type, event)}
              onBlur={saveGeolocation}
              onKeyPress={onKeyPressInput}
              value={geolocation[type]?.toString()}
              key={type}
              style={{ padding: '6px 0', marginTop: 8 }}
              onFocus={(event): void => event.currentTarget.select()}
            />
          </>
        ))}
      </ContainerSubRow>
    );
  };

  const isWithinTolerance = (value1: number, value2: number, tolerance = 0.01): boolean => Math.abs(value1 - value2) <= tolerance;

  const currentValueFromSelectItems = useMemo((): string => {
    const { latitude: profileLatitude = 0, longitude: profileLongitude = 0, isCustomCoordinates } = geolocation;

    const selectedCity = GEOLOCATION_DATA.find(({ latitude, longitude }) =>
      isWithinTolerance(latitude, profileLatitude) &&
      isWithinTolerance(longitude, profileLongitude)
    )?.city || '';

    const isCustom = !isCustomCoordinates && selectedCity;
    let value = isCustom ? selectedCity : 'custom coordinates';
    if (geolocation.fillBasedOnIp) {
      value = 'automatic';
    }

    if (geolocation.mode === 'block') {
      value = 'block';
    }

    return value;
  }, [geolocation]);

  const renderSelectTitle = (): string => {
    const isBasedOnIp = geolocation.fillBasedOnIp && geolocation.mode !== 'block';
    const { timezone = '' } = data;
    const [countryByIp, cityByIp] = timezone.split('/');
    if (isFreeOrTorProxy && isBasedOnIp) {
      return countryByIp;
    }

    let selectTitle = 'Custom geolocation';
    const currentValue = isBasedOnIp ? cityByIp : currentValueFromSelectItems;
    selectItems(translation).forEach(item => {
      item.selectItems.forEach(subItem => {
        if (subItem.value !== currentValue) {
          return;
        }

        selectTitle = subItem.title;
      });
    });

    return selectTitle;
  };

  const renderDescription = (): JSX.Element|null => {
    const { fillBasedOnIp, mode } = geolocation;
    if (mode === 'block') {
      return null;
    }

    const descriptions = fillBasedOnIp ?
      automaticTitle :
      selectItems(translation).find(item => item.selectItems.find(subItem => subItem.value === currentValueFromSelectItems))?.groupTitle || '';

    return (
      <GreyDarkTextContainer hasCursorPointer={currentValueFromSelectItems === 'custom coordinates'}>
        {descriptions}
      </GreyDarkTextContainer>
    );
  };

  const changeEditMode = (): void => {
    if (!hasOpenCoordinates) {
      return;
    }

    setIsEdit(!isEdit);
  };

  return (
    <ContainerParams>
      <DrawerParams
        style={{ flexDirection: 'column' }}
        onClick={changeEditMode}
        hasCursorPointer={hasOpenCoordinates}
      >
        <ContainerSpaceBetween>
          <ModernSelect
            currentValue={currentValueFromSelectItems}
            title={renderSelectTitle()}
            itemList={selectItems(translation)}
            onSelected={onChangeMode}
            hasSearch={true}
            popoverMaxHeight='317px'
            popoverWidth='303px'
            maxWidth='170px'
          />
          {renderDescription()}
        </ContainerSpaceBetween>
        {renderGeolocationData()}
      </DrawerParams>
    </ContainerParams>
  );
};

export default React.memo(Geolocation);
