import React from 'react';
import { Trans } from 'react-i18next';

import { resetProxyForm, useProxyForm } from '../../../../../state/proxy/custom-proxy-form-data.atom';
import { pasteProxyToForm } from '../../../../../state/proxy/proxy-operations/paste-proxies-operations';
import { IconPaste } from '../../../../../ui/gologin-header/icons';
import { IconClean } from '../../../../../ui/icons/IconClean';
import TooltipWrapper from '../../../../../ui/tooltip-custom/tooltip-wrapper';
import { ProxyFormLeft, ProxyFormRight, ProxyFormRowEl } from '../styles';
import CustomProxyChangeIpUrlInput from './custom-proxy-change-ip-url-input';
import CustomProxyHostAndIpInput from './custom-proxy-host-and-ip-input';
import CustomProxyModeSelect from './custom-proxy-mode-select';
import CustomProxyStringInput from './custom-proxy-string-input';

const CustomProxyEditFormFields: React.FC = () => {
  const customProxyForm = useProxyForm();

  const handlePaste: React.ClipboardEventHandler<HTMLInputElement> = async event => {
    const clipboardText = await navigator.clipboard.readText();
    const pasteProxyRes = await pasteProxyToForm(customProxyForm, clipboardText);
    if (pasteProxyRes) {
      event.preventDefault();
    }
  };

  return (
    <>
      <ProxyFormRowEl>
        <ProxyFormLeft>
          <Trans i18nKey='proxies.formFields.type' />
        </ProxyFormLeft>
        <ProxyFormRight>
          <CustomProxyModeSelect />
        </ProxyFormRight>
      </ProxyFormRowEl>
      <ProxyFormRowEl>
        <ProxyFormLeft>
          <Trans i18nKey='proxies.formFields.customName' />
        </ProxyFormLeft>
        <ProxyFormRight>
          <CustomProxyStringInput fieldName='customName' handlePaste={handlePaste} />
        </ProxyFormRight>
      </ProxyFormRowEl>
      <ProxyFormRowEl>
        <ProxyFormLeft>
          <Trans i18nKey='proxies.formFields.host' />
        </ProxyFormLeft>
        <ProxyFormRight style={{ display: 'flex', width: '100%' }}>
          <CustomProxyHostAndIpInput handlePaste={handlePaste} />
          <div style={{ marginLeft: 'auto', display: 'flex' }}>
            <div
              style={{
                margin: '2px 0 0 16px',
                marginLeft: 'auto',
                padding: '0 4px',
                height: '20px',
                cursor: 'pointer',
              }}
              onClick={resetProxyForm}
            >
              <TooltipWrapper isTextEllipsis={false} value={<Trans i18nKey='proxies.clearForm' />}>
                <IconClean padding={0} iconColor='var(--98989F)' iconHoveredColor='var(--36363D)' />
              </TooltipWrapper>
            </div>
            <div
              style={{ margin: '2px 0 0 4px', padding: '0 0 1px 4px', height: '20px', cursor: 'pointer' }}
              onClick={handlePaste}
            >
              <TooltipWrapper
                isTextEllipsis={false}
                hasTooltipAnimation={true}
                value={<Trans i18nKey='proxies.pasteProxy' />}
              >
                <IconPaste padding={0} iconColor='var(--98989F)' iconHoveredColor='var(--36363D)' />
              </TooltipWrapper>
            </div>
          </div>
        </ProxyFormRight>
      </ProxyFormRowEl>
      <ProxyFormRowEl>
        <ProxyFormLeft>
          <Trans i18nKey='proxies.formFields.username' />
        </ProxyFormLeft>
        <ProxyFormRight>
          <CustomProxyStringInput fieldName='username' handlePaste={handlePaste} />
        </ProxyFormRight>
      </ProxyFormRowEl>
      <ProxyFormRowEl>
        <ProxyFormLeft>
          <Trans i18nKey='proxies.formFields.password' />
        </ProxyFormLeft>
        <ProxyFormRight>
          <CustomProxyStringInput fieldName='password' handlePaste={handlePaste} />
        </ProxyFormRight>
      </ProxyFormRowEl>
      <ProxyFormRowEl>
        <ProxyFormLeft>
          <Trans i18nKey='proxies.formFields.changeIpUrl' />
        </ProxyFormLeft>
        <ProxyFormRight>
          <CustomProxyChangeIpUrlInput />
        </ProxyFormRight>
      </ProxyFormRowEl>
    </>
  );
};

export default CustomProxyEditFormFields;
