export const RESOLUTIONS = [
  '800x600',
  '960x720',
  '1024x576',
  '1024x600',
  '1024x640',
  '1024x768',
  '1152x648',
  '1152x864',
  '1280x720',
  '1280x768',
  '1280x800',
  '1280x960',
  '1280x1024',
  '1360x768',
  '1366x768',
  '1400x1050',
  '1440x900',
  '1440x1080',
  '1536x864',
  '1600x900',
  '1600x1200',
  '1680x1050',
  '1856x1392',
  '1920x1080',
  '1920x1200',
  '1920x1440',
  '2048x1152',
  '2048x1536',
  '2304x1440',
  '2560x1440',
  '2560x1600',
  '2560x1664',
  '2560x2048',
  '2880x1800',
  '3840x2160',
  '4096x2304',
  '5120x2880',
  '7680x4320',
] as const;

export const ANDROID_RESOLUTIONS = [
  '360x640',
  '360x720',
  '360x740',
  '360x760',
  '360x780',
  '393x851',
  '412x732',
  '412x846',
  '412x869',
  '412x892',
  '412x915',
] as const;
