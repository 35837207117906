import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled';

import { flagIcons } from '../../../../flag-icons';
import { textFont } from '../../../ui/style-templates';
import { CheckProxyButtonWrapper } from '../check-proxy-button/styles';
import {
  GROUPED_PROXY_MANAGER_SIZE,
  GROUPED_PROXY_MANAGER_SIZE_WITH_NEW_FORM,
  UNGROUPED_PROXY_MANAGER_SIZE,
} from '../proxy-manager/styles';
import { PROXY_SETTINGS_MODAL_CONTENT_SIZE } from '../proxy-page/proxy-settings-modal/styles';
import { ProxySelectorContentWrapper } from '../proxy-selector/styles';

export const ProxyPopper = styled.div`
  ${flagIcons}
`;

export const ProxyManagerContainer = styled('div')<{
  isDarkTheme: boolean;
  isProxyManagerWithGroups: boolean;
  isWrappedByModal?: boolean;
}>`
  outline: var(--gologin-popover-outline);
  border-radius: ${(props): number => (props.isProxyManagerWithGroups && !props.isWrappedByModal ? 4 : 8)}px;

  ${textFont()}

  background: var(--FFFFFF-proxy-manager-popup);

  overflow: hidden;
  background: ${(props): React.CSSProperties['background'] =>
    props.isProxyManagerWithGroups ? 'var(--FFFFFF-proxy-manager-popup-grouped)' : 'var(--FFFFFF-proxy-manager-popup)'};

  ${(props): SerializedStyles => {
    switch (true) {
      case props.isWrappedByModal:
        return css`
          border: 0.5px solid var(--E5E5E6-proxy-settings-modal-content-border);
        `;
      case props.isProxyManagerWithGroups:
        return css`
          box-shadow: var(--box-shadow);
        `;
      case props.isDarkTheme:
        return css`
          box-shadow: -2px 0 8px var(--00000026-antd);
        `;
      default:
        return css`
          box-shadow: 0px 4px 8px var(--0000001F-proxy-manager-popup), 0px 6px 12px var(--0000001F-proxy-manager-popup),
            0px 1px 16px var(--0000001F-proxy-manager-popup);
        `;
    }
  }}
`;

export const ProxyManagerContent = styled.div<{
  isProxyManagerWithGroups: boolean;
  newStyle: boolean;
  isWrappedByModal?: boolean;
  isProxyManagerWithGroupsAndNewAddForm: boolean;
  modalView: string | null;
}>`
  width: ${(props): string => {
    if (props.isWrappedByModal) {
      return `${PROXY_SETTINGS_MODAL_CONTENT_SIZE.width}px`;
    }

    if (props.isProxyManagerWithGroupsAndNewAddForm && props.modalView === 'proxy-add') {
      return `${GROUPED_PROXY_MANAGER_SIZE_WITH_NEW_FORM.width}px`;
    }

    if (props.isProxyManagerWithGroups) {
      return `${GROUPED_PROXY_MANAGER_SIZE.width}px`;
    }

    return `${UNGROUPED_PROXY_MANAGER_SIZE.width}px`;
  }};

  position: relative;

  ${(props): SerializedStyles | null =>
    props.newStyle
      ? css`
          ${CheckProxyButtonWrapper} {
            display: flex;
          }

          ${ProxySelectorContentWrapper} {
            z-index: 104;
          }
        `
      : null}
`;
