import React, { memo } from 'react';
import { areEqual } from 'react-window';

import { message } from 'antd';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS } from '../../../../../common/constants/analytics';
import { determineIsProxyTruthy, determineIsTorOrFreeProxy } from '../../../../../common/constants/types';
import { isNotNull } from '../../../../../common/typescript/predicates';
import { IArchivedProxy, IProxy } from '../../../../interfaces';
import PerformanceObserverService from '../../../../services/performance-observer/performance-observer.service';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import {
  openProxyContextMenu,
  ProxyContextMenuProxyLocation,
  updateProxySelectMenuPosition,
  useIsSelectProxyModeOpened,
} from '../../../../state/proxy-select-menu.atom';
import { updateGeoProxyCustomNameCounters } from '../../../../state/proxy/default-geoproxy.atom';
import { useGeoProxyCountries } from '../../../../state/proxy/geoproxy-countries.atom';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../../state/proxy/proxy-check/constants';
import { showProxyCheckTooltip } from '../../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { addProxyStatuses, removeProxyStatuses } from '../../../../state/proxy/proxy-check/proxy-statuses.atom';
import {
  determineIsProxyManagerEmptyListPlaceholder,
  determineIsProxyManagerListSection,
  getIsProxyGroupAddButton,
  getIsProxyGroupHeader,
  IProxyManagerListEntity,
} from '../../../../state/proxy/proxy-groups/types';
import { pushManyProxies, updateProxyInListById } from '../../../../state/proxy/proxy-list.atom';
import {
  closeProxyManager,
  getProxyManagerState,
  useProxyManagerState,
} from '../../../../state/proxy/proxy-manager-modal-status.atom';
import {
  checkGeoProxy,
  evaluateSelectableGeoProxyConnectionType,
  fetchGeoProxyWithCredentials,
  GeoProxyWithCredentialsParams,
  handleGeoProxyWithCredentialsError,
} from '../../../../state/proxy/proxy-operations/create-geoproxy.operations';
import {
  decrementProfileLinkedProxyProfilesCounter,
  handleLinkArtificialProxy,
  linkProfileProxy,
  linkProfileProxyInState,
} from '../../../../state/proxy/proxy-operations/link-proxy.operations';
import { updateRecentSelectedCountriesAtom } from '../../../../state/proxy/recent-selected-proxies.atom';
import {
  toggleIsProxySelected,
  updateSelectedProxies,
  useSelectedProxies,
} from '../../../../state/proxy/selected-proxies.atom';
import { useTrafficData } from '../../../../state/proxy/traffic-data.atom';
import { sendReactErrorToSentry } from '../../../../utils/sentry.helper';
import { sendActionAnalytics } from '../../../common/api';
import {
  ARTIFICIAL_GEOPROXY_CONTEXT_MENU_WARN_MESSAGE,
  ARTIFICIAL_GEOPROXY_CONTEXT_MENU_WARNING,
  DEFAULT_SELECTED_GEOPROXY_COUNTRY,
  GEOPROXY_TYPE_TAG,
  PROXY_COUNTRY_TAG,
  PROXY_GROUP_ID_TAG,
  PROXY_ID_TAG,
  PROXY_OBSERVED_USER_ACTIONS,
} from '../../constants';
import {
  generateProxyAnalyticsData,
  getIsProxyArchived,
  getIsProxyEditable,
  restoreProxy,
  selectProxiesWithShift,
} from '../../proxy-helpers';
import connectionType from '../../proxy-page/proxy-table/cells/connection-type';
import { generateArtificialGeoProxyId, generateProxyGroupId, getNoIdProxyId } from '../../utils/proxy-id';
import { EmptyProxyListPlaceholder } from './empty-proxy-list-placeholder';
import GroupedProxyItem from './grouped-proxy-item';
import ProxyGroupAddButton from './proxy-group-add-button';
import ProxyGroupHeader from './proxy-group-header';
import ProxySectionItem from './proxy-section-item';
import UngroupedProxyItem from './ungrouped-proxy-item';

type ProxyRowProps = {
  data: {
    proxyManagerEntities: (IProxy | IArchivedProxy | IProxyManagerListEntity | null)[];
    currentProxy?: IProxy;
    localProxyElementLocation: string | null;
  };
  index: number;
  style: React.CSSProperties;
};

const makeArtificialGeoProxy = ({ country, city, connectionType, customName, profilesCount, groupId }: any): IProxy => {
  const proxyId = generateArtificialGeoProxyId();
  updateGeoProxyCustomNameCounters(country);

  return {
    id: proxyId,
    groupId,
    host: '',
    port: 80,
    mode: 'geolocation',
    country,
    city,
    connectionType,
    customName,
    profilesCount,
    customSortingInGroup: 'end',
    createdAt: new Date(),
    selectionDate: profilesCount ? Date.now() : 0,
  };
};

const ProxyRow: React.FC<ProxyRowProps> = props => {
  const { data, index, style } = props;

  const { currentProfileId } = useProxyManagerState();
  const isSelectProxyModeOpened = useIsSelectProxyModeOpened();
  const { lastSelectedProxy, selectedProxies } = useSelectedProxies();
  const geoProxyCountries = useGeoProxyCountries();
  const trafficData = useTrafficData();

  const { proxyManagerEntities, currentProxy, localProxyElementLocation } = data;
  let proxyEntity = proxyManagerEntities[index];
  // to keep the last proxy visible right above the proxy-manager multi-select panel
  if (!proxyEntity) {
    return <div />;
  }

  if (getIsProxyArchived(currentProxy) && proxyEntity.id === currentProxy.id) {
    proxyEntity = currentProxy;
  }

  const areCheckboxesVisible = !!selectedProxies.length;
  const styleBase: React.CSSProperties = { ...style, width: 'auto', right: 0 };

  const handleRowClick: React.MouseEventHandler<Element> = async event => {
    const isArtificialProxy = handleLinkArtificialProxy(proxyEntity, 'proxy-item');
    const isTorOrFreeProxy = determineIsTorOrFreeProxy(proxyEntity);
    const { handleProxySelect } = getProxyManagerState();

    if (isArtificialProxy && !isTorOrFreeProxy) {
      return;
    }

    if (!proxyEntity.id) {
      const groupId = generateProxyGroupId('geolocation', proxyEntity.country);
      const currentCountryWithTypes = geoProxyCountries.find(
        ({ countryCode }) => countryCode.toLowerCase() === proxyEntity.country.toLowerCase(),
      );
      const availableTypes = currentCountryWithTypes ? currentCountryWithTypes.types : [];
      const selectableConnectionType = evaluateSelectableGeoProxyConnectionType({
        country: proxyEntity.country,
        groupId,
        selectedConnectionType: connectionType,
        availableConnectionTypes: availableTypes,
        trafficData,
      });

      if (!selectableConnectionType) {
        return;
      }

      const profilesCount = currentProfileId && !handleProxySelect ? 1 : 0;
      const artificialGeoProxy = makeArtificialGeoProxy({
        country: proxyEntity.country,
        city: proxyEntity.city,
        customName: proxyEntity.customName,
        groupId,
        profilesCount,
      });

      const geoProxyWithCredentialsParams: GeoProxyWithCredentialsParams = {
        country: proxyEntity.country,
        city: proxyEntity.city,
        connectionType: selectableConnectionType,
        profileId: '',
        customName: proxyEntity.customName,
      };

      closeProxyManager();

      addProxyStatuses([artificialGeoProxy], [currentProfileId]);
      pushManyProxies([artificialGeoProxy]);
      if (handleProxySelect) {
        let proxyId = artificialGeoProxy.id;
        if (!proxyId && determineIsProxyTruthy(artificialGeoProxy)) {
          proxyId = getNoIdProxyId(artificialGeoProxy);
        }

        handleProxySelect(proxyId);
      } else {
        decrementProfileLinkedProxyProfilesCounter(currentProfileId);
        linkProfileProxyInState({
          profileId: currentProfileId,
          proxy: artificialGeoProxy,
          shouldUpdateProxyInList: false,
        });

        geoProxyWithCredentialsParams.profileIdToLink = currentProfileId;
      }

      const geoProxyWithCredentialsResponse = await fetchGeoProxyWithCredentials(geoProxyWithCredentialsParams).catch(
        error => {
          handleGeoProxyWithCredentialsError(artificialGeoProxy, currentProfileId);
          let errorMessage = 'tableProfiles.notification.trafficLimit';
          if (typeof error?.body?.message === 'string') {
            errorMessage = error.body.message;
          }

          return errorMessage;
        },
      );

      if (typeof geoProxyWithCredentialsResponse === 'string') {
        return message.error(<Trans i18nKey={geoProxyWithCredentialsResponse} />);
      }

      const proxyWithCredentials = geoProxyWithCredentialsResponse;
      const fullProxy: IProxy = {
        ...artificialGeoProxy,
        ...proxyWithCredentials,
        mode: 'geolocation',
      };

      const checkResult = await checkGeoProxy(
        fullProxy,
        currentProfileId,
        PROXY_CHECK_TOOLTIP_LOCATIONS.proxyManagerEditForm,
      );

      if (typeof checkResult === 'string') {
        handleGeoProxyWithCredentialsError(artificialGeoProxy, currentProfileId);

        return message.error(<Trans i18nKey={checkResult} />);
      }

      const checkedProxy = checkResult;
      updateProxyInListById(artificialGeoProxy.id, checkedProxy);
      removeProxyStatuses([artificialGeoProxy], [currentProfileId]);

      const fullCheckedProxy: IProxy = { ...checkedProxy, checkDate: moment().toDate() };
      showProxyCheckTooltip({
        profileIds: [currentProfileId],
        proxies: [fullCheckedProxy],
        view: PROXY_CHECK_TOOLTIP_LOCATIONS.selectorProfileTable,
        timeout: 2000,
      });

      if (handleProxySelect) {
        let proxyId = checkedProxy.id;
        if (!proxyId && determineIsProxyTruthy(checkedProxy)) {
          proxyId = getNoIdProxyId(checkedProxy);
        }

        handleProxySelect(proxyId);
      } else {
        linkProfileProxyInState({
          profileId: currentProfileId,
          proxy: checkedProxy,
          shouldUpdateProxyInList: false,
        });
      }

      return;
    }

    let proxyToLink = proxyEntity;

    if (getIsProxyArchived(proxyEntity)) {
      proxyToLink = await restoreProxy(proxyEntity);
    }

    event.preventDefault();

    if (isSelectProxyModeOpened) {
      if (!event.shiftKey) {
        return toggleIsProxySelected(proxyToLink.id);
      }

      return selectProxiesWithShift({
        shiftKey: event.shiftKey,
        selectedProxies,
        proxiesToSelectFrom: proxyManagerEntities.filter(isNotNull),
        proxy: proxyToLink,
        lastSelectedProxy,
      });
    }

    if (NEW_FEATURES.proxyGroupsV2 && proxyEntity?.groupId) {
      const proxyAnalyticsData = generateProxyAnalyticsData(proxyToLink);
      sendActionAnalytics(
        GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS.choseLocationViaBuiltInProxyClickedOnServerTypeInExpandedGroup,
        proxyAnalyticsData,
      ).catch(() => null);
    }

    if (handleProxySelect) {
      let proxyId = proxyEntity.id;
      if (!proxyId && determineIsProxyTruthy(proxyToLink)) {
        proxyId = getNoIdProxyId(proxyToLink);
      }

      handleProxySelect(proxyId);

      return;
    }

    if (currentProfileId) {
      const performanceObserverService = PerformanceObserverService.getInstance();
      performanceObserverService.handleUserAction({ userAction: PROXY_OBSERVED_USER_ACTIONS.linkProxyManagerProxy });

      closeProxyManager();
      linkProfileProxy({
        profileId: currentProfileId,
        proxy: proxyToLink,
      });
    }
    if (proxyToLink.country) {
      updateRecentSelectedCountriesAtom({
        countryCode: proxyToLink.country,
      });
    }
  };

  const handleContextMenu = (
    event: React.MouseEvent<Element>,
    proxyContextMenuProxyLocation: ProxyContextMenuProxyLocation,
  ): void => {
    event.preventDefault();
    const isProxyEditable = getIsProxyEditable(proxyEntity);
    if (!isProxyEditable) {
      return sendReactErrorToSentry({
        transactionName: ARTIFICIAL_GEOPROXY_CONTEXT_MENU_WARNING,
        message: ARTIFICIAL_GEOPROXY_CONTEXT_MENU_WARN_MESSAGE,
        level: 'warning',
        tags: [
          [PROXY_ID_TAG, proxyEntity?.id],
          [PROXY_GROUP_ID_TAG, proxyEntity?.groupId],
          [GEOPROXY_TYPE_TAG, proxyEntity?.connectionType],
          [PROXY_COUNTRY_TAG, proxyEntity?.country],
        ],
      });
    }

    updateSelectedProxies({ selectedProxy: proxyEntity });
    updateProxySelectMenuPosition({ x: event.pageX, y: event.pageY });
    openProxyContextMenu(proxyContextMenuProxyLocation);
  };

  const isCurrentProxy = currentProxy ? currentProxy.id === proxyEntity.id : false;

  switch (true) {
    case !NEW_FEATURES.proxyGroupsV2:
      return (
        <UngroupedProxyItem
          key={proxyEntity.id}
          ungroupedProxy={proxyEntity}
          currentProfileId={currentProfileId}
          isCurrentProxy={isCurrentProxy}
          isCheckboxVisible={areCheckboxesVisible}
          handleRowClick={handleRowClick}
          handleContextMenu={handleContextMenu}
          localProxyElementLocation={localProxyElementLocation}
          style={styleBase}
        />
      );
    case determineIsProxyManagerListSection(proxyEntity):
      return (
        <ProxySectionItem
          id={proxyEntity.id}
          title={proxyEntity.title}
          isContextMenuEnabled={proxyEntity.isContextMenuEnabled}
          style={styleBase}
        />
      );
    case determineIsProxyManagerEmptyListPlaceholder(proxyEntity):
      return <EmptyProxyListPlaceholder localProxyElementLocation={localProxyElementLocation} style={styleBase} />;
    case getIsProxyGroupHeader(proxyEntity):
      return (
        <ProxyGroupHeader
          key={proxyEntity.id}
          proxyGroupHeader={proxyEntity}
          currentProfileId={currentProfileId}
          availableTypes={proxyEntity.types}
          style={styleBase}
        />
      );
    case getIsProxyGroupAddButton(proxyEntity):
      return (
        <ProxyGroupAddButton
          key={proxyEntity.id}
          country={proxyEntity.country || DEFAULT_SELECTED_GEOPROXY_COUNTRY}
          groupId={proxyEntity.groupId}
          availableTypes={proxyEntity.types}
          style={styleBase}
        />
      );
    case !!proxyEntity.groupId:
      return (
        <GroupedProxyItem
          key={proxyEntity.id}
          groupedProxy={proxyEntity}
          currentProfileId={currentProfileId}
          isCheckboxVisible={areCheckboxesVisible}
          handleRowClick={handleRowClick}
          handleContextMenu={handleContextMenu}
          style={styleBase}
          localProxyElementLocation={localProxyElementLocation}
        />
      );
    default:
      return (
        <UngroupedProxyItem
          key={proxyEntity.id}
          ungroupedProxy={proxyEntity}
          currentProfileId={currentProfileId}
          isCurrentProxy={isCurrentProxy}
          isCheckboxVisible={areCheckboxesVisible}
          handleRowClick={handleRowClick}
          handleContextMenu={handleContextMenu}
          isOfferedProxy={proxyEntity?.isOfferedProxy}
          localProxyElementLocation={localProxyElementLocation}
          style={styleBase}
        />
      );
  }
};

export default memo(ProxyRow, areEqual);
