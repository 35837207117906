import { getBasicTableEntities } from './basic-table-entities.atom';
import { isNoEntitiesGroupHeader } from './generate-group-headers';
import {
  forceReloadProfilesQuery,
  forceReloadProfilesQueryWithoutForceReset,
  resetProfilesQueryOffset,
  setShouldProfilesQueryMakeRequests,
} from './profiles-query';
import { PAGE_SIZE } from '../../../common/constants/constants';
import { addTagRequest } from '../../features/tags/api';
import { ITag } from '../../features/tags/interfaces/tag.interface';
import { GroupHeader } from '../../interfaces/group-header.interface';
import { getCurrentWorkspaceId } from '../current-workspace-id.atom';
import { getFolderById } from '../folders/folders-list.atom';
import { handleFolderProfiles, ManageFoldersAction } from '../folders/profiles-folders.operations';
import {
  getGroupHeaderById,
  getGroupProfilesCount,
  getProfilesTableGroupHeaders,
  setProfilesTableGroupHeaders,
} from '../profiles-list.atom';
import { getTagById } from '../tags/tags-list.atom';

export const updateGroupHeader = (groupHeaderId: GroupHeader['id'], fieldsToUpdate: Partial<GroupHeader>): void => {
  const groupHeaders = getProfilesTableGroupHeaders();
  setProfilesTableGroupHeaders(groupHeaders.map((groupHeader) => {
    if (groupHeader.id === groupHeaderId) {
      return { ...groupHeader, ...fieldsToUpdate };
    }

    return groupHeader;
  }));
};

export const openProfilesTableGroupHeader = (groupHeaderId: GroupHeader['id']): void => {
  const groupHeaders = getProfilesTableGroupHeaders();
  const groupHeaderIdx = groupHeaders.findIndex((groupHeader) => groupHeader.id === groupHeaderId);
  const groupHeader = groupHeaders[groupHeaderIdx];
  if (!groupHeader || groupHeader.isOpen) {
    return;
  }

  if (groupHeader.loadingStatus === 'loaded') {
    const groupProfilesCount = getGroupProfilesCount(groupHeaderId);
    if (groupProfilesCount === groupHeader.totalProfiles) {
      updateGroupHeader(groupHeaderId, { isOpen: true });

      return;
    }
  }

  updateGroupHeader(groupHeaderId, { isOpen: true, loadingStatus: 'loading-initiated' });
  const { isChanged } = resetProfilesQueryOffset();
  if (!isChanged) {
    forceReloadProfilesQueryWithoutForceReset();
  }
};

export const closeProfilesTableGroupHeader = (groupHeaderId: GroupHeader['id']): void => {
  const groupHeader = getGroupHeaderById(groupHeaderId);
  if (!groupHeader?.isOpen) {
    return;
  }

  setShouldProfilesQueryMakeRequests(false);
  updateGroupHeader(groupHeaderId, { isOpen: false, loadingStatus: 'unloaded' });
  if (getBasicTableEntities().length < PAGE_SIZE) {
    const { isChanged } = resetProfilesQueryOffset();
    if (!isChanged) {
      forceReloadProfilesQuery();
    }
  }

  setShouldProfilesQueryMakeRequests(true);
};

const putNewProfilesIntoFirstStatusGroup = async (profileIds: string[], customStatus: ITag | null): Promise<void> => {
  if (!customStatus) {
    return;
  }

  const workspaceId = getCurrentWorkspaceId();
  await addTagRequest({ browserIds: profileIds, workspace: workspaceId, ...customStatus });
};

const putNewProfilesIntoFirstFolderGroup = async (profileIds: string[], folderName?: string): Promise<void> => {
  if (!folderName) {
    return;
  }

  const action: ManageFoldersAction = 'add';
  await handleFolderProfiles(folderName, profileIds, action);
};

export const putNewProfilesIntoFirstGroup = async (profileIds: string[]): Promise<void> => {
  const [groupHeader] = getProfilesTableGroupHeaders();
  if (!groupHeader) {
    return;
  }

  const isNoEntitiesGroup = isNoEntitiesGroupHeader(groupHeader);
  if (isNoEntitiesGroup) {
    return;
  }

  const { filter: groupFilter } = groupHeader;
  switch (groupFilter.type) {
    case 'custom-status': {
      const customStatus = getTagById(groupFilter.customStatusId);
      await putNewProfilesIntoFirstStatusGroup(profileIds, customStatus);
      break;
    }
    case 'folder': {
      const { folderId } = groupFilter;
      const { name: folderName } = getFolderById(folderId) || {};
      await putNewProfilesIntoFirstFolderGroup(profileIds, folderName);
      break;
    }
    default:
      break;
  }

  openProfilesTableGroupHeader(groupHeader.id);
};
