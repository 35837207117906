import React, { FC, useEffect, useState } from 'react';

import { useDoesProfilesQueryHaveMoreProfiles } from '../../../state/profiles-table/profiles-query';
import InfiniteScrollInTable, { IInfiniteScrollInTable } from '../../common/custom-table/components/infinite-scroll-in-table';
import useScrollPosition from '../../../hooks/use-scroll-position';
import { WrapperForTable } from './styles';
import { useRouteMatch } from 'react-router-dom';
import { PROFILE_LIST_PAGE } from '../../../../common/constants/routes';

type IInfiniteScrollProfilesTable = Omit<IInfiniteScrollInTable, 'hasMore'>;

export const InfiniteScrollProfilesTable: FC<IInfiniteScrollProfilesTable> = (props) => {
  const { dataLength, initialScrollY, loader, next, children } = props;

  const isMoreProfilesAvailable = useDoesProfilesQueryHaveMoreProfiles();

  const { scrollTop } = useScrollPosition('scrollableComponent');

  const [lastScrollTop, setLastScrollTop] = useState(0); 

//  useEffect(() => {
//     if (scrollTop !== lastScrollTop) {
//       if (scrollTop > lastScrollTop) {
//         setIsVisible(false);
//         console.log('from top')

//       } else {
//         setIsVisible(true);
//         console.log('from down')
//       }
//       setLastScrollTop(scrollTop);
//     }
//   }, [scrollTop, lastScrollTop]);
  const isProfileListPage = !!useRouteMatch(PROFILE_LIST_PAGE);

  return (
    <WrapperForTable id='scrollableComponent'>   
      <InfiniteScrollInTable
        dataLength={dataLength}
        next={next}
        hasMore={isMoreProfilesAvailable}
        loader={loader}
        initialScrollY={initialScrollY}
      >
        {children}
      </InfiniteScrollInTable>
    </WrapperForTable>
  );
};
