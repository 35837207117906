import { PROFILE_TABLE_COLUMNS } from '../../state/columns-settings.atom';

const LOCATION_FIRST_SESSION_DEFAULT_COLUMNS_ORDER: typeof PROFILE_TABLE_COLUMNS = [
  'name', 'status', 'notes', 'customStatus', 'proxy',
  'tags', 'lastUpdate', 'lastLaunch', 'createdAt', 'sharing',
  'proxyType', 'proxyPort', 'proxyUsername', 'proxyPassword', 'os', 'orbitaVersion',
];

const DEFAULT_COLUMNS_ORDER: typeof PROFILE_TABLE_COLUMNS = [
  'name', 'status', 'notes', 'customStatus', 'tags',
  'lastUpdate', 'lastLaunch', 'createdAt', 'sharing',
  'proxyType', 'proxy', 'proxyPort', 'proxyUsername', 'proxyPassword', 'os', 'orbitaVersion',
];

type ProfilesTableColumnsOrderEvaluateParams = {
  isProxyColumnShownAmongFirstColumns: boolean;
}

export const evaluateProfilesTableColumnsOrder = ({
  isProxyColumnShownAmongFirstColumns,
}: ProfilesTableColumnsOrderEvaluateParams): typeof PROFILE_TABLE_COLUMNS => {
  if (isProxyColumnShownAmongFirstColumns) {
    return LOCATION_FIRST_SESSION_DEFAULT_COLUMNS_ORDER;
  }

  return DEFAULT_COLUMNS_ORDER;
};
