import { useTranslate } from '@tolgee/react';
import React, { MouseEvent, FC, useContext, Dispatch, SetStateAction, useEffect } from 'react';
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';

import { ModalUpgradeButtonContainer, ModalUpgradeCryptoButton } from './domElements';
import { ButtonPay } from './styles';
import { E_ACTION_INFO, E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { FREE_PLAN_ID } from '../../../../common/constants/constants';
import { IPlan } from '../../../interfaces/plan';
import { Limits } from '../../../interfaces/workspaces/limits';
import {
  useCurrentTabShownCurrency,
  userContext,
  workspaceContext,
} from '../../../state';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { useIsUpgradeDiscountAvaliable } from '../../../state/pricing/upgrade-discount.atom';
import { openPaddleCheckoutPage } from '../../../utils/open-site';
import { sendActionAnalytics } from '../../common/api';
import { checkPlanMaxProfiles, isDiscountAnnual, getSelectedPlan } from '../../modalsComponents/components/checkout-modal/utils';
import { E_PAYMENT_METHODS, PRODUCT_TYPE } from '../interfaces';
import { OpenPaddlePlanCheckoutPageParams } from './paddle-checkout/types/paddle-checkout.types';

const isElectron = !!window.require;

export type PaddleForProps = {
  selectedPlan: string;
  selectedDiscount: string;
  plans: IPlan[];
  getCheckmarkLine?: () => JSX.Element;
  setIsLoadingModalShown: Dispatch<SetStateAction<boolean>>;
  closePaymentModal: (set: boolean) => void;
  openSuccessModal: (planId: string) => void;
  pricingPlan?: IPlan;
  workspaceId?: string;
  isLimitsPurchase?: boolean;
  isRenewWithAddonLimits?: boolean;
  addonLimits?: Limits;
}

const PaddleForm: FC<PaddleForm> = (props) => {
  const {
    selectedPlan,
    selectedDiscount,
    plans,
    getCheckmarkLine,
    workspaceId = '',
    addonLimits,
  } = props;

  const isUpgrade = useIsUpgradeDiscountAvaliable();
  const tabCurrency = useCurrentTabShownCurrency();

  const { profiles: userProfilesCount } = useContext(userContext);
  const { profilesCount: workspaceProfilesCount } = useContext(workspaceContext);

  const { t: translation } = useTranslate();

  const isAnnual = isDiscountAnnual(selectedDiscount);
  const profilesCount = NEW_FEATURES.workspaces ? workspaceProfilesCount : userProfilesCount;

  const handleCheckout = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    const actionInfo = isUpgrade ? E_ACTION_INFO.UPGRADE : E_ACTION_INFO.EMPTY;
    sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedPayNow, { actionInfo, paymentMethod: E_PAYMENT_METHODS.PADDLE }).catch(() => null);
    event.preventDefault();
    event.stopPropagation();
    if (selectedPlan === FREE_PLAN_ID) {
      return;
    }

    const planToPay = getSelectedPlan(plans, selectedPlan);
    const status = planToPay && checkPlanMaxProfiles({ translation, planToPay, profiles: profilesCount, paymentMethod: E_PAYMENT_METHODS.PADDLE });
    if (!status) {
      return;
    }

    const checkoutParams: OpenPaddlePlanCheckoutPageParams = {
      productType: PRODUCT_TYPE.Plan,
      checkoutData: {
        selectedPlan,
        selectedDiscount,
        workspaceId,
        currency: tabCurrency,
      },
    };
  
    if (addonLimits) {
      checkoutParams.checkoutData.limits = addonLimits;
    }

    await openPaddleCheckoutPage({ isElectron, ...checkoutParams });
  };

  return (
    <>
      {getCheckmarkLine ? getCheckmarkLine() : null}
      <ModalUpgradeButtonContainer paddingBottom={isAnnual}>
        <ButtonPay>
          <ModalUpgradeCryptoButton
            onClick={handleCheckout}
          >
            {translation('pricing.forms.stripe.submitBtnTxt')}
          </ModalUpgradeCryptoButton>
        </ButtonPay>
      </ModalUpgradeButtonContainer>
    </>
  );
};

export default PaddleForm;
