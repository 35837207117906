import React from 'react';

import { IconWrapper, IIconWrapper } from './wrapper';

export const IconArrow: React.FC<IIconWrapper> = props => (
  <IconWrapper {...props}>
    <svg width='10' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0.5 9.5L5 5L0.5 0.5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  </IconWrapper>
);
