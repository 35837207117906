/* eslint-disable max-lines */
import * as Sentry from '@sentry/react';
import { useTolgee, useTranslate } from '@tolgee/react';
import { Icon, message } from 'antd';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  ColumnContainer,
  ContentContainer,
  ScoreContainer,
  MainContainer,
  PageTitle,
  PricingPageCancelContainer,
  ReviewCardsContainer,
  ReviewCardsGrid,
  ReviewSubtitle,
  ReviewTitle,
  ScoreTextContainer,
  StarsContainer,
  TitleContainer,
  TrustCompaniesContainer,
  TrustTitle,
  TariffDetailsTitle,
  Wrapper,
} from './domElements';
import { FreePlanTable } from './freePlan-table';
import PaymentModal from './payment-modal';
import PlanTable from './plan-table';
import PreviewCards from './preview-cards';
import ReviewCard from './review-card';
import SuccessPaymentStaticPage from './success-payment-static-page';
import SupportLinksWidget from './support-links-element';
import Switch from './switch';
import { E_ACTION_INFO, E_ANALYTICS_ACTIONS, E_ANALYTICS_ACTIONS_INFO } from '../../../../common/constants/analytics';
import {
  AFFILIATE_COOKIE_NAME,
  FREE_PLAN_ID, MS_IN_SECOND,
  NEW_PRICING_WORKSPACE_ID_KEY_NAME,
  TWO_FA_TOKEN_KEY_NAME,
} from '../../../../common/constants/constants';
import { getCookie } from '../../../cookies/utils';
import { sendGA4PurchaseEvent } from '../../../GA4';
import { useQuery } from '../../../hooks';
import useGlobalAntStyles from '../../../hooks/use-global-ant-styles';
import { IPlan } from '../../../interfaces/plan';
import { ThemeType } from '../../../interfaces/theme.type';
import { history } from '../../../services';
import { ACCESS_TOKEN } from '../../../services/http/config';
import {
  IUser,
  setDiscountsList,
  setPlansAddonPricingConfig,
  setIsPromoDiscountAvaliable,
  setPaymentModalPaymentCountry,
  setPlansList,
  setPricingConfig,
  setPromoDiscount,
  usePaymentModalPaymentCountry,
  userContext,
  workspaceContext,
} from '../../../state';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { setWorkspaceLimits, setIsWorkspaceLimitsAvaliable } from '../../../state/limits/workspace-limits.atom';
import { refreshLocalUserDevice, useLocalUserDevice } from '../../../state/local-user-device.atom';
import {
  setDefaultChosenLimits,
  setSelectedDiscount,
  setSelectedPlan,
  useSelectedDiscount,
  useSelectedPlan
} from '../../../state/pricing/pricing-page.atom';
import { setUpgradeDiscount, useIsUpgradeDiscountAvaliable } from '../../../state/pricing/upgrade-discount.atom';
import { usePricingData } from '../../../state/pricing-data.atom';
import { closeQuickPricing } from '../../../state/quick-pricing.atom';
import { switchTheme } from '../../../state/theme.atom';
import { IconShapeStar } from '../../../ui/gologin-header/icons';
import { IconTrustCompanies } from '../../../ui/gologin-header/icons/icon-trust-companies';
import { IconCloseV2 } from '../../../ui/icons/icon-close-v2';
import { E_SENTRY_MESSAGES } from '../../../utils/sentry-messages';
import { sendActionAnalytics } from '../../common/api';
import LoadingPage from '../../common/loading-page';
import {
  checkAffiliateProgram,
  checkPaypalSubscription,
  postSelectedTrialPlan,
  requestPricingData,
  requestPricingDataByTempToken,
} from '../api';
import { DISCOUNT_IDS, PLAN_IDS, REVIEW_STARS } from '../constants';
import {
  IDiscount,
  IPricingDataByTempTokenProps,
  IRawPricingData,
  IRawPricingDataByTempToken,
} from '../interfaces';
import { getFreePlan } from '../plans-data';
import { SwitchContainer } from '../styles';
import { checkSeparatedCheckoutUrl } from '../utils';
import { setHasUserEverPaid } from '../../../state/pricing/has-user-ever-paid.atom';

const SELECT_PLAN_INIT_VALUE = PLAN_IDS.Professional;
const isElectron = !!window.require;
const queryTimestampName = 'timestamp';
const queryAutoLoginToken = 'auto-login-token';
const queryWorkspaceId = 'workspaceId';
const queryTheme = 'theme';
const queryLocale = 'locale';
const checkoutModalRoute = '/payment-methods';
const checkoutModalQuery = 'checkout';

const level: Sentry.SeverityLevel = 'log';
let transaction: Sentry.Transaction | null = null;
let spanCheckTimezone: Sentry.Span | null = null;

let startTimestamp = 0;
let pricingMountedTimestamp = 0;

interface IPricingPage {
  variant?: 'page' | 'popup' | 'popup-buy-locked-proxy';
}

const PricingPage: FC<IPricingPage> = (props) => {
  const { variant = 'page' } = props;

  const localUserDevice = useLocalUserDevice();
  const preloadedPricingData = usePricingData();
  const isUpgrade = useIsUpgradeDiscountAvaliable();
  const selectedPlan = useSelectedPlan();
  const selectedDiscount = useSelectedDiscount();

  const [isLoading, setIsLoading] = useState<boolean>(!preloadedPricingData.userData._id);
  const [plans, setPlans] = useState<IPlan[]>(preloadedPricingData.plans);
  const [freePlan, setFreePlan] = useState<IPlan>();

  const [paymentModalVisible, setPaymentModalVisible] = useState<boolean>(false);
  const [successPaymentModalVisible, setSuccessPaymentModalVisible] = useState<boolean>(false);

  const [billedMonthlySwitch, setBilledMonthlySwitch] = useState<boolean>(false);
  const [selectedCustomPlanId, setSelectedCustomPlanId] = useState<string>(PLAN_IDS.Custom2k);
  const [rawPricingData, setRawPricingData] = useState<IRawPricingData>(preloadedPricingData);

  const userCtx = useContext(userContext);
  const workspaceCtx = useContext(workspaceContext);

  const { t: translation } = useTranslate();
  const tolgee = useTolgee(['pendingLanguage']);

  const {
    email,
    firstPlanSelected = false,
    plan = {},
    realPayments,
    profiles = 0,
    invitedByLink = '',
    invitedByUser = '',
    updateUser,
  } = userCtx;

  const {
    id: workspaceId,
    planId: workspacePlanId,
    updateWorkspace,
  } = workspaceCtx;

  const query = useQuery();

  const location = useLocation();

  const { areAntStylesLoading } = useGlobalAntStyles();

  useEffect(() => {
    setIsPromoDiscountAvaliable(false);
  }, [location]);

  useEffect(() => {
    checkSeparatedCheckoutUrl();
    setDefaultChosenLimits();
    setSelectedDiscount(DISCOUNT_IDS.Annual);
    const queryStartTimestamp = Number(query.get(queryTimestampName) || '');
    if (!queryStartTimestamp) {
      return;
    }

    deleteQueryParam(queryTimestampName);

    transaction = Sentry.startTransaction({ name: 'load-pricing-page' });
    startTimestamp = queryStartTimestamp / MS_IN_SECOND;
    transaction.startTimestamp = startTimestamp;
    const mountedSpan = transaction.startChild({ op: 'ui', description: 'page-pricing-mounted', startTimestamp });
    pricingMountedTimestamp = (performance.now() + performance.timeOrigin) / MS_IN_SECOND;
    mountedSpan.finish(pricingMountedTimestamp);
    updateLocalUserDevice();
  }, []);

  useEffect(() => {
    if (!localUserDevice?.country) {
      return;
    }

    spanCheckTimezone && spanCheckTimezone.finish();
  }, [localUserDevice]);

  useEffect(() => {
    if (!(invitedByLink && invitedByUser)) {
      affiliateCheck();
    }
  }, [email]);

  useEffect(() => {
    if (query.get('sigma_error')) {
      message.error(translation('notification.error.paymentError'));
    }

    let locale = query.get(queryLocale) || localStorage.getItem('@gologin:selected_language');
    if (locale) {
      if (locale === 'en') {
        locale = 'en-US';
      }

      tolgee.changeLanguage(locale);
    }

    const theme = query.get(queryTheme) as ThemeType;
    if (theme) {
      switchTheme(theme);
      deleteQueryParam(queryTheme);
    }
  }, []);

  const upsertPaymentModalPath = (state: boolean): void => {
    const urlParams = new URLSearchParams(window.location.search);
    let pathName = window.location.pathname;
    const endsWithSlash = pathName.endsWith('/');
    if (state && !pathName.includes(checkoutModalRoute)) {
      if (endsWithSlash) {
        pathName = pathName.slice(0, -1);
      }

      pathName += checkoutModalRoute;
    }

    if (!state && pathName.includes(checkoutModalRoute)) {
      if (endsWithSlash) {
        pathName = pathName.slice(0, -1);
      }

      pathName = pathName.replace(checkoutModalRoute, '');
    }

    let newUrl = pathName;
    const queryParams = urlParams.toString();
    if (queryParams) {
      newUrl += `?${queryParams}`;
    }

    window.history.replaceState({}, '', newUrl);
  };

  const changePaymentModalVisibility = (state: boolean): void => {
    if (!isElectron) {
      upsertPaymentModalPath(state);
    }

    setPaymentModalVisible(state);
  };

  const checkConditionShowPaymentModal = (): void => {
    const isNeedToShowPaymentModalOldParam = query.get(checkoutModalQuery) === 'true';
    if ((window.location.href.includes(checkoutModalRoute) || isNeedToShowPaymentModalOldParam) && !isUpgrade) {
      changePaymentModalVisibility(true);
    }

    if (isUpgrade) {
      changePaymentModalVisibility(false);
    }

    const actionInfo = isUpgrade ? E_ACTION_INFO.UPGRADE : E_ACTION_INFO.EMPTY;
    sendActionAnalytics(E_ANALYTICS_ACTIONS.visitedCheckout, { actionInfo });
  };

  const checkPaypalSubAndSendAnalytics = async (subscriptionId: string): Promise<void> => {
    const checkResult = await checkPaypalSubscription(subscriptionId);
    if (!checkResult) {
      return;
    }

    const { paymentId = '' } = checkResult || {};
    if (!paymentId) {
      return;
    }

    if (!isElectron) {
      sendGA4PurchaseEvent({
        userId: 'gologin_user',
        amount: '0',
        planId: 'gologin_plan',
        planName: 'gologin_plan',
        paymentId,
      });
    }
  };

  const deleteQueryParam = (queryName: string): void => {
    if (isElectron) {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(queryName);

    const newUrl = window.location.pathname + '?' + urlParams.toString();
    window.history.replaceState({}, '', newUrl);
  };

  const initWithAccessToken = async (): Promise<void> => {
    const pricingDataLoadSpan = transaction && transaction.startChild({ op: 'http', description: 'pricing-data-load-by-access-token' });
    const result = await getPricingData();
    pricingDataLoadSpan && pricingDataLoadSpan.finish();

    if (!result) {
      return;
    }

    checkConditionShowPaymentModal();
    if (!preloadedPricingData.userData?._id) {
      updateLocalUserDevice();
    }
  };

  const initWithAutoLoginToken = async (): Promise<void> => {
    const dataGbCount = query.get('dataGbCount');
    const dataType = query.get('dataType');
    const bundle = query.get('bundle');
    const autoLoginToken = query.get(queryAutoLoginToken) || '';
    deleteQueryParam(queryAutoLoginToken);

    const workspaceIdFromQuery = query.get(queryWorkspaceId) || localStorage.getItem(NEW_PRICING_WORKSPACE_ID_KEY_NAME) || '';
    localStorage.removeItem(NEW_PRICING_WORKSPACE_ID_KEY_NAME);

    const pricingDataLoadSpan = transaction && transaction.startChild({ op: 'http', description: 'pricing-data-load-by-temp-token' });
    const pricingData = await getPricingDataByTempToken({
      autoLoginToken,
      workspaceId: workspaceIdFromQuery,
      accessToken: localStorage.getItem(ACCESS_TOKEN) || '',
    });

    pricingDataLoadSpan && pricingDataLoadSpan.finish();

    if (!pricingData) {
      return;
    }

    checkConditionShowPaymentModal();
    updateLocalUserDevice();

    if (dataGbCount && dataType) {
      history.push(`/checkout/proxy?dataType=${dataType}&dataGbCount=${dataGbCount}&bundle=${bundle === 'true'}`);
    }
  };

  useEffect(() => {
    const checkoutStatus = query.get('status');
    const sessionId = query.get('session_id');
    const orderId = query.get('order_id');
    const subscriptionId = query.get('subscription_id');

    if (checkoutStatus === 'success' && (sessionId || orderId || subscriptionId)) {
      return;
    }

    if (query.get(queryAutoLoginToken)) {
      initWithAutoLoginToken();

      return;
    }

    initWithAccessToken();
  }, []);

  useEffect(() => {
    const checkoutStatus = query.get('status');
    const sessionId = query.get('session_id');
    const orderId = query.get('order_id');
    const subscriptionId = query.get('subscription_id');
    if (!(NEW_FEATURES.newStripeCheckout || orderId)) {
      return;
    }

    if (!checkoutStatus) {
      return;
    }

    if (checkoutStatus === 'canceled') {
      message.error('Payment failure');

      return;
    }

    if (checkoutStatus === 'success' && (sessionId || orderId || subscriptionId)) {
      if (subscriptionId) {
        checkPaypalSubAndSendAnalytics(subscriptionId);
      }

      setSuccessPaymentModalVisible(true);
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (startTimestamp) {
      try {
        transaction && transaction.finish();
      } catch (err) { /* Sentry was crashing pricing */ }
    }
  }, [isLoading]);

  useEffect(() => {
    if (!(email && rawPricingData.plans?.length && localUserDevice?.country)) {
      return;
    }

    setupPlans();
    setIsLoading(false);
  }, [email, rawPricingData, localUserDevice]);

  const affiliateCheck = async (): Promise<void> => {
    const affiliateCookieValue = getCookie(AFFILIATE_COOKIE_NAME);
    if (!affiliateCookieValue) {
      return;
    }

    await checkAffiliateProgram(affiliateCookieValue)
      .catch((error) => console.log(error));
  };

  const updateLocalUserDevice = (): void => {
    spanCheckTimezone = transaction && transaction?.startChild({ op: 'http', description: 'check-timezone' });
    refreshLocalUserDevice();
  };

  const updateUserContextPlan = (id: string, hasTrial?: boolean): void => {
    const updateObj: Partial<IUser> = {
      hasTrial,
      firstPlanSelected: true,
    };

    const planInfo = plans.find(planObj => planObj.id === id);
    if (planInfo) {
      updateObj.plan = planInfo;
    }

    closeQuickPricing();
    updateUser(updateObj);

    if (NEW_FEATURES.workspaces && planInfo) {
      updateWorkspace({
        planId: planInfo.id,
        planName: planInfo.name,
        planProfilesMax: planInfo.maxProfiles,
        planMembersMax: planInfo.maxAccountShares,
      });
    }
  };

  const saveSelectedPlan = (userData: IUser): IUser => {
    if (variant !== 'page' && !userData.firstPlanSelected) {
      if (userData.plan && userData.plan?._id) {
        postSelectedTrialPlan(userData.plan._id);
      }

      userData.firstPlanSelected = true;
    }

    return userData;
  };

  const setPricingData = (data: IRawPricingData): void => {
    const {
      workspace,
      discounts,
      plans: plansData,
      promoDiscount,
      userData,
      pricingConfig,
      upgradeDiscount,
      limits,
      plansAddonPricingConfig,
    } = data;

    const changedUserData = saveSelectedPlan(userData);
    const { hasUserEverPaid } = userData;

    setHasUserEverPaid(!!hasUserEverPaid);
    setPlansList(plansData);
    setDiscountsList(discounts);
    setPromoDiscount(promoDiscount);
    setPricingConfig(pricingConfig);
    setUpgradeDiscount(upgradeDiscount);
    updateUser(changedUserData);
    updateWorkspace(workspace);

    if (limits && plansAddonPricingConfig) {
      setWorkspaceLimits(limits);
      setIsWorkspaceLimitsAvaliable(true);
      setPlansAddonPricingConfig(plansAddonPricingConfig);
      setDefaultChosenLimits();
    }

    setRawPricingData({
      userData,
      workspace,
      discounts,
      plans: plansData,
      promoDiscount,
      pricingConfig,
      upgradeDiscount,
      limits,
      plansAddonPricingConfig,
    });
  };

  const updatePaymentPlan = async (planId: string, isUpgrade?: boolean): Promise<void> => {
    setSelectedPlan(planId);
    changePaymentModalVisibility(!isUpgrade);
  };

  const onPlanSelect = async (id: string, isUpgrade?: boolean): Promise<void> => {
    if (firstPlanSelected) {
      updatePaymentPlan(id, isUpgrade);

      return;
    }

    updateUserContextPlan(id, true);
    await postSelectedTrialPlan(id)
      .catch((error: any) => message.error(error.body?.message || error.message));
  };

  const closePricingHandler = async (): Promise<void> => {
    if (!firstPlanSelected) {
      updateUserContextPlan(plan._id, true);
      if (plan && plan._id) {
        await postSelectedTrialPlan(plan._id)
          .catch((error: any) => console.log(error.body?.message || error.message));
      }
    }

    history.push('/profileList');
  };

  const getPricingData = async (): Promise<IRawPricingData|void> => {
    const workspaceForRequest = query.get(queryWorkspaceId) || workspaceId || '';

    let data: void | IRawPricingData = preloadedPricingData;
    if (!data.userData?._id) {
      data = await requestPricingData({
        workspaceId: workspaceForRequest,
      });
    }

    if (!data) {
      return;
    }

    setPricingData(data);

    return data;
  };

  const getPricingDataByTempToken = async ({
    autoLoginToken,
    workspaceId: workspace,
    accessToken,
  }: IPricingDataByTempTokenProps): Promise<IRawPricingDataByTempToken|void> => {
    const data = await requestPricingDataByTempToken({
      autoLoginToken,
      workspaceId: workspace,
      accessToken,
    });

    localStorage.removeItem(ACCESS_TOKEN);

    if (!data) {
      return;
    }

    Sentry.setUser({ id: data.userData?._id });
    Sentry.captureMessage(
      `${E_SENTRY_MESSAGES.visitedCheckoutPage}`, {
        level,
      },
    );

    localStorage.setItem(ACCESS_TOKEN, data.accessToken);

    if (data.twoFaToken) {
      localStorage.setItem(TWO_FA_TOKEN_KEY_NAME, data.twoFaToken);
    }

    sendActionAnalytics(E_ANALYTICS_ACTIONS.loggedInWithToken);
    setPricingData(data);

    return data;
  };

  const setupPlans = (): void => {
    if (plan && plan._id === 'undefined') {
      plan._id = '';
    }

    let rawPlans: IPlan[] = rawPricingData.plans;
    const [userPlan] = rawPlans.filter(planFromList => planFromList.selected);
    rawPlans.forEach((planObj) => {
      let disabled = false;
      if (profiles > (planObj.maxProfiles || 0)) {
        disabled = true;
      }

      planObj.disabled = disabled;
    });

    if (userPlan) {
      if (userPlan.id !== PLAN_IDS.Easy) {
        rawPlans = rawPlans.filter(planObj => planObj.id !== PLAN_IDS.Easy);
      }

      setSelectedPlan(userPlan.id);
    }

    if (workspacePlanId) {
      const paidPlan = plan?.priceForMonth ? plan?._id : SELECT_PLAN_INIT_VALUE;
      const workspacePlanToUpdate = workspacePlanId === 'unpaid' ? paidPlan : workspacePlanId;
      setSelectedPlan(workspacePlanToUpdate);
    }

    const freePlanFromList = getFreePlan(rawPlans);
    setFreePlan(freePlanFromList);
    setPlans(rawPlans);
    setPlansList(rawPlans);
  };

  const closeBusinessPlanModal = (): void => {
    changePaymentModalVisibility(false);
    sendActionAnalytics(E_ANALYTICS_ACTIONS.visitedPricing, { actionInfo: E_ANALYTICS_ACTIONS_INFO.closedPaymentMethods });
  };

  const getPlanInfo = (planId: string, field: keyof IPlan, getFeatureStatus = false): JSX.Element | string => {
    const planObj = plans.find(planFromList => planFromList.id === planId) || {} as Partial<IPlan>;

    if (!planObj[field]) {
      return <Icon type='close' style={{ fontSize: 13 }} />;
    }

    if (getFeatureStatus) {
      return <Icon type='check' style={{ fontSize: 13 }} />;
    }

    return planObj[field]?.toString() || '';
  };

  const changeMonthlyPaymentPeriod = (checked: boolean): void => {
    const discountObj = rawPricingData.discounts.find((el: IDiscount) => el.monthCount === (checked ? 1 : 12));
    if (!discountObj) {
      return;
    }

    setSelectedDiscount(discountObj.id);
    setBilledMonthlySwitch(checked);
    setIsPromoDiscountAvaliable(!realPayments && checked);
  };

  const getPaymentModal = (): JSX.Element|undefined => {
    if (!firstPlanSelected) {
      return;
    }

    if (paymentModalVisible && selectedPlan === FREE_PLAN_ID) {
      changePaymentModalVisibility(false);
    }

    return (
      <PaymentModal
        closePaymentModal={closeBusinessPlanModal}
        modalVisible={paymentModalVisible}
        changeMonthlyPaymentPeriod={changeMonthlyPaymentPeriod}
        getPlanInfo={getPlanInfo}
        workspaceId={workspaceId}
        userData={userCtx}
      />
    );
  };

  const renderInfoHeader = (): JSX.Element|null => {
    if (!(isElectron && variant === 'page')) {
      return null;
    }

    return (
      <div style={{ position: 'relative' }}>
        <PricingPageCancelContainer onClick={closePricingHandler}>
          <IconCloseV2 iconColor={'var(--696973-new-pricing)'} />
        </PricingPageCancelContainer>
      </div>
    );
  };

  const renderPageTitle = (): JSX.Element => {
    if (variant === 'popup-buy-locked-proxy') {
      return (
        <PageTitle style={{ lineHeight: '50px' }}>
          <Trans i18nKey='pricing.newTitle.textForLockedLocationFirstSession' />
        </PageTitle>
      );
    }

    if (!NEW_FEATURES.renameProxyToLocation) {
      return (
        <PageTitle>
          <Trans i18nKey='pricing.newTitle.text' />
        </PageTitle>
      );
    }

    return (
      <PageTitle style={{ lineHeight: '50px' }}>
        <Trans i18nKey='pricing.newTitle.textForLocationFirstSession' />
      </PageTitle>
    );
  };

  const getContent = (): JSX.Element => {
    if (successPaymentModalVisible) {
      return <SuccessPaymentStaticPage />;
    }

    if (isLoading || areAntStylesLoading) {
      return <LoadingPage />;
    }

    return (
      <Wrapper>
        {renderInfoHeader()}
        <MainContainer style={{ paddingBottom: 48 }} isPopup={variant !== 'page'}>
          <ContentContainer>
            <TitleContainer>
              {renderPageTitle()}
              <SwitchContainer>
                <Switch
                  onChangeHandler={changeMonthlyPaymentPeriod}
                  checked={billedMonthlySwitch}
                />
              </SwitchContainer>
            </TitleContainer>
            <ColumnContainer>
              <PreviewCards
                selectedDiscount={selectedDiscount}
                onPlanSelect={onPlanSelect}
                getPlanInfo={getPlanInfo}
                selectedCustomPlanId={selectedCustomPlanId}
                setSelectedCustomPlanId={setSelectedCustomPlanId}
              />
            </ColumnContainer>
          </ContentContainer>
          <TrustCompaniesContainer>
            <div>
              <TrustTitle>
                {translation('pricing.RewiewService.title')}
              </TrustTitle>
              <ScoreContainer>
                <StarsContainer>
                  {[...Array(5)].map(index => (
                    <IconShapeStar key={index} padding={0} iconColor='var(--F5D23D-new-pricing)' iconType='fill' />
                  ))}
                </StarsContainer>
                <ScoreTextContainer>
                  {translation('pricing.RewiewService.text')}
                </ScoreTextContainer>
              </ScoreContainer>
            </div>
            <IconTrustCompanies padding={0} />
          </TrustCompaniesContainer>
          <ReviewTitle>
            {translation('pricing.userReview.title')}
          </ReviewTitle>
          <ReviewSubtitle>
            {translation('pricing.userReview.subtitle')}
          </ReviewSubtitle>
          <ReviewCardsContainer>
            <ReviewCardsGrid>
              {REVIEW_STARS.map((starsCount, index) => <ReviewCard key={index} starsCount={starsCount} order={index + 1} />)}
            </ReviewCardsGrid>
          </ReviewCardsContainer>
          <TariffDetailsTitle>
            {translation('pricing.planTable.title')}
          </TariffDetailsTitle>
          <PlanTable
            plans={plans}
            selectedDiscount={selectedDiscount}
            selectedCustomPlanId={selectedCustomPlanId}
            setSelectedCustomPlanId={setSelectedCustomPlanId}
            onPlanSelect={onPlanSelect}
            getPlanInfo={getPlanInfo}
          />
          <FreePlanTable
            updateUserContextPlan={updateUserContextPlan}
            freePlan={freePlan}
            workspaceId={rawPricingData.workspace.id}
          />
        </MainContainer>
        {variant === 'page' ?
          <SupportLinksWidget />
          : null}
        {getPaymentModal()}
      </Wrapper>
    );
  };

  return (
    <>
      {getContent()}
    </>
  );
};

export default PricingPage;
