import styled from '@emotion/styled';

export const HeaderContainer = styled.header<{ isSticky: boolean }>`
  width: 100%;
  padding: 20px 24px;
  position: ${(props) => (props.isSticky ? 'sticky' : 'static')};
  top: ${(props) => (props.isSticky ? '0' : 'auto')};
  background-color: var(--F9F9F9-header-container);
  z-index: ${(props) => (props.isSticky ? ' 200' : '149')};
`;
