import { Popper, PopperProps } from '@material-ui/core';
import React, { memo, useCallback, useEffect, useRef } from 'react';

import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { useProfilesList } from '../../../state/profiles-list.atom';
import { useHandleOutsideClick } from '../../../state/proxy/handle-proxy-outside-click.atom';
import { useProxyManagerHoverState } from '../../../state/proxy/proxy-manager-hover-state.atom';
import {
  closeProxyManager,
  ProxySelectorLocation,
  useProxyManagerState,
} from '../../../state/proxy/proxy-manager-modal-status.atom';
import { DEFAULT_LOCATION, EMPTY_PROXY } from '../constants';
import ProxyPopoverWrapper from '../proxy-popover-wrapper';
import ProxyDeletionConfirm from './proxy-deletion-confirm';
import ProxyManagerForm from './proxy-edit-view/proxy-manager-form';
import ProxyList from './proxy-list';

const PROFILES_TABLE_PROXY_MANAGER_MARGIN = '-8px 35px 8px -35px';
const DRAWER_PROXY_MANAGER_MARGIN = '8px 35px -8px -35px';

type ProxyManagerProps = {
  proxyManagerTargetRef: React.RefObject<HTMLElement>;
};
const ProxyManager: React.FC<ProxyManagerProps> = ({ proxyManagerTargetRef }) => {
  const profilesList = useProfilesList();
  const {
    modalView,
    currentProfileId,
    currentProxy: currentProxyInManager,
    containerElement,
    proxySelectorLocation,
  } = useProxyManagerState();

  const { setProxyManagerHoverState } = useProxyManagerHoverState();
  const popperRef = useRef<HTMLDivElement>(null);

  const handleGlobalKeyDown = useCallback((event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      closeProxyManager();
    }
  }, []);

  const handleOutsideClick = useHandleOutsideClick({ modalView, popperRef });

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleGlobalKeyDown, true);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleGlobalKeyDown, true);
    };
  }, [handleOutsideClick, handleGlobalKeyDown]);

  useEffect(() => {
    setProxyManagerHoverState(!!modalView);

    const rootElement = document.getElementById('root');
    if (!rootElement) return;

    if (modalView) {
      rootElement.style.overflow = 'hidden';
    } else {
      rootElement.style.overflow = '';
    }

    return () => {
      rootElement.style.overflow = '';
    };
  }, [modalView]);

  const currentProfile = profilesList.find(profile => profile.id === currentProfileId);
  const currentProxy = currentProxyInManager || currentProfile?.proxy || EMPTY_PROXY;

  const renderContent = (): JSX.Element => {
    if (modalView !== 'proxy-list') {
      return <ProxyManagerForm proxyManagerTargetRef={proxyManagerTargetRef} />;
    }

    return <ProxyList currentProxy={currentProxy} localProxyElementLocation={DEFAULT_LOCATION} />;
  };

  let popperMargin: React.CSSProperties['margin'] = NEW_FEATURES.proxyGroupsV2
    ? PROFILES_TABLE_PROXY_MANAGER_MARGIN
    : '0px 32px 0px -32px';
  switch (true) {
    case proxySelectorLocation === ProxySelectorLocation.profileSettingsPage:
      popperMargin = 0;
      break;
    case proxySelectorLocation && ['pinned-proxy', 'proxy-proxy'].includes(proxySelectorLocation):
      popperMargin = NEW_FEATURES.proxyGroupsV2 ? DRAWER_PROXY_MANAGER_MARGIN : '16px 32px -16px -32px';
      break;
    default:
      break;
  }

  const popperProps: Omit<PopperProps, 'children'> = {
    open: !!modalView,
    style: { zIndex: 1000, margin: popperMargin },
    disablePortal: false,
    anchorEl: containerElement,
    placement: 'bottom-start',
    modifiers: {
      preventOverflow: { enabled: true, boundariesElement: 'window' },
      offset: { enabled: true },
    },
  };

  return (
    <Popper {...popperProps}>
      <ProxyPopoverWrapper>
        <div ref={popperRef}>
          {renderContent()}
          <ProxyDeletionConfirm />
        </div>
      </ProxyPopoverWrapper>
    </Popper>
  );
};

export default memo(ProxyManager);
