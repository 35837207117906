import { useState, useEffect } from 'react';

const useScrollPosition = (elementId: string) => {
  const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 });
  
  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById(elementId);
      if (element) {
        const { scrollTop, scrollLeft } = element;
        setScrollPosition({ scrollTop, scrollLeft });
      }
    };

    const element = document.getElementById(elementId);
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [elementId]);

  return scrollPosition;
};

export default useScrollPosition;
