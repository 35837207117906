import { atom, getDefaultStore, useAtomValue } from 'jotai';

export const proxyManagerHoverStateAtom = atom<boolean>(false);

export const getProxyManagerHoverState = (): boolean => {
  return getDefaultStore().get(proxyManagerHoverStateAtom);
};

export const setProxyManagerHoverState = (newState: boolean): void => {
  getDefaultStore().set(proxyManagerHoverStateAtom, newState);
};

export const updateProxyManagerHoverState = (updater: (prevState: boolean) => boolean): void => {
  const currentState = getProxyManagerHoverState();
  const newState = updater(currentState);
  setProxyManagerHoverState(newState);
};

export const useProxyManagerHoverState = () => {
  const isProxyManagerHovered = useAtomValue(proxyManagerHoverStateAtom);

  return {
    isProxyManagerHovered,
    getProxyManagerHoverState,
    setProxyManagerHoverState,
    updateProxyManagerHoverState,
  };
};
