import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ContainerFolderList } from './styles';
import { userContext } from '../../../../../state';
import { useProfilesSettingsState } from '../../../../../state/profiles-settings-atom';
import { FOLDERS_MANAGER_MODAL_NAME, openProfilesTableModal } from '../../../../../state/profiles-table-modal.atom';
import { IconPlusFill } from '../../../../../ui/gologin-header/icons';
import { ContainerIcon, SeparatedContainer } from '../../../styles';
import { Mixed } from '../mixed/mixed';

type FolderListProps = {
  profileFolders: string[];
}

export const FolderList: FC<FolderListProps> = (props) => {
  const { profileFolders = [] } = props;

  const { profileIds } = useProfilesSettingsState();

  const { folders: foldersFromCtx } = useContext(userContext);

  const { t: translation } = useTranslation();

  const profileFolderList = profileFolders.filter(folderName => foldersFromCtx.find(folder => folder.name === folderName));

  const renderFolders = (): JSX.Element => {
    if (profileIds.length > 1) {
      return (
        <Mixed colorText='var(--2B2B31-profile-new-settings)' />
      );
    }

    if (!profileFolderList.length) {
      return (
        <ContainerIcon
          iconColor='var(--B5B5BA-profile-new-settings)'
          iconHoveredColor='var(--2B2B31-profile-new-settings)'
          textColor='var(--81818A-profile-new-settings)'
          textHoveredColor='var(--2B2B31-profile-new-settings)'
          iconType='stroke'
        >
          <IconPlusFill padding={0} margin='0 8px 0 0' />
          {translation('quickSettings.favorites.addFolder')}
        </ContainerIcon>
      );
    }

    return (
      <SeparatedContainer>
        {profileFolderList.map((folder, index) => (
          <span key={index}>
            {folder}
          </span>
        ))}
      </SeparatedContainer>
    );
  };

  return (
    <ContainerFolderList onClick={(): void => openProfilesTableModal(FOLDERS_MANAGER_MODAL_NAME, profileIds)}>
      {renderFolders()}
    </ContainerFolderList>
  );
};
