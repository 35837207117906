import { useCallback } from 'react';
import { closeProxyManager } from './proxy-manager-modal-status.atom';

interface UseHandleOutsideClickProps {
  modalView: any;
  popperRef: React.RefObject<HTMLDivElement>;
}

export const useHandleOutsideClick = ({ modalView, popperRef }: UseHandleOutsideClickProps) => {
  return useCallback(
    (event: MouseEvent) => {
      if (!modalView || !popperRef.current) {
        return;
      }

      const isNewSelectOptions = document.querySelector('[data-proxy-manager-exclude="select-options"]') !== null;
      const isPopoverPaper = document.querySelector('.MuiPopover-paper') !== null;
      const isHiddenBackdrop = document.querySelector('[role="presentation"][aria-hidden="true"]') !== null;
      const isProxyOldContextMenu = document.querySelector('[data-proxy-manager-exclude="context-menu"]') !== null;

      if (popperRef.current.contains(event.target as Node)) {
        return;
      }

      if (!(isNewSelectOptions || isPopoverPaper || isHiddenBackdrop || isProxyOldContextMenu)) {
        closeProxyManager();
      }
    },
    [modalView, popperRef],
  );
};
