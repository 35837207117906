import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

import { showCheckbox } from '../gologin-checkbox/styles';
import { IGroupHeaderRow } from './gologin-table-group-header';

interface HeaderWrapperProps {
  headerHeight: number;
  hasBorderRadius?: boolean;
}

export const RadiusHeaderWrapper = styled.div<HeaderWrapperProps>`
  background-color: var(--F9F9F9-header-container);
  position: sticky;
  top: ${({ headerHeight }: HeaderWrapperProps): string => `${headerHeight}px`};
  z-index: 152;

  ${({ hasBorderRadius }: HeaderWrapperProps): string =>
    hasBorderRadius
      ? `
    border-radius: 8px 8px 0 0;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 14px;
      height: 8px;
      background-color: var(--F9F9F9-header-container);
      z-index: -5;
    }

    &::before {
      left: -8px;
      top: -2px;
    }

    &::after {
      right: -8px;
      top: -2px;
    }
  `
      : ''}
`;

export const HeaderWrapper = styled.div<HeaderWrapperProps>`
  scrollbar-width: none;
  overflow-y: auto;
  position: sticky;
  top: ${({ headerHeight }: HeaderWrapperProps): string => `${headerHeight}px`};
  z-index: 5;
  max-width: 100%;
  box-sizing: border-box;
  ${({ hasBorderRadius }: HeaderWrapperProps): string =>
    hasBorderRadius ? 'border-radius: 8px 8px 0 0;' : ''}
  outline: 1px solid var(--EBEBEB);
`;

interface TableCustomizationWrapperProps {
  headerHeight: number;
}

export const TableCustomizationWrapper = styled.div<
  TableCustomizationWrapperProps
>`
  scrollbar-width: none;
  position: sticky;
  top: ${({ headerHeight }: TableCustomizationWrapperProps): string =>
    `${headerHeight + 56}px`};
  z-index: 152;

  max-width: 100%;
`;

export const TableCustomizationInnerWrapper = styled.div`
  position: relative;
  width: 100%;
  top: -56px;
`;

export const GologinTableContainer = styled('div')`
  scrollbar-width: none;
  overflow-y: scroll;
  font-family: Roboto;
`;

export const GologinTableItemList = styled('div')`
  padding-bottom: 65px;

  // for some reason without it, z-index of tags manager is broken :(
  .ReactVirtualized__Grid {
    will-change: initial !important;
  }

  .ReactVirtualized__Grid,
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
`;

export const GologinTableItemRow = styled('div')``;

export const GologinTableItemCell = styled('div')`
  position: relative;
  align-items: center;
  vertical-align: top;
  background-color: inherit;
  position: relative;
  height: 100%;
  padding: 16px 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--2B2B31-profile-table);

  vertical-align: top;
  transition: none;
  min-height: 52px;

  &.notes-cell {
    padding: 10px 8px;
  }
  &.new-notes-cell {
    padding: 0;
    overflow: visible;
    white-space: normal;
  }
  &.new-status-cell {
    overflow: visible;
    padding-left: 12px;

    :hover {
      z-index: 104;
    }
  }
  &.tags-cell {
    padding: 0;
    position: relative;
  }
  &.new-tags-cell {
    padding: 0;
    position: relative;
    overflow: visible;
  }
  &.new-proxy-cell {
    overflow: visible;
    padding: 0;
    position: relative;
  }
  &.proxy-cell,
  &.name-cell,
  &.new-name-cell {
    padding: 0;
    /*z-index: 20;*/
    position: relative;
  }

  &.new-selection-cell {
    padding: 0;
  }
`;

export const GologinTableSelectionCell = styled('div')<{ width?: string }>`
  width: ${(props): string => props.width || 'min-content'}px;
  padding: 0;
`;

export const GologinTableHeaderContainer = styled('div')`
  display: flex;
  align-items: center;
  min-width: fit-content;
  width: 100%;
  position: relative;
  z-index: 5;
  background-color: var(--FFFFFF-profile-table-header);
  color: var(--98989F-profile-table);

  border-radius: 0;

  & > :first-child {
    padding: 0;
  }

  & > :nth-child(2) {
    padding: 12px 8px 12px 6px;
  }

  & > * {
    padding: 12px 8px;
  }
`;

export const ResizableHandle = styled('span')<{ newStyle: boolean }>`
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;

  &:hover {
    &::after {
      background-color: var(--00A987);
      right: 4px;
      width: 3px;
    }
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 1px;
    height: 32px;
    background-color: var(--0000000F);
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: '';
    opacity: 0;
  }

  ${(props): SerializedStyles | '' =>
    props.newStyle
      ? css`
          &::after {
            background-color: var(--EAEAEB-profile-table);
          }

          &:hover::after {
            background-color: var(--767676-profile-table);
          }
        `
      : ''}
`;

export const GologinTableHeaderColumnContainer = styled('div')`
  position: relative;
  height: 100%;

  &:hover {
    color: var(--2B2B31-profile-table);
    cursor: pointer;

    ${showCheckbox()}

    ${ResizableHandle}::after {
      opacity: 1;
    }
  }
`;

export const GologinTableHeaderColumnContent = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;

  & > span {
    font-weight: 400;
    letter-spacing: 0.25px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const GroupTagItemBody = styled('div')<{
  borderColor: string;
}>`
  white-space: nowrap;
  overflow: hidden;
  max-width: 400px;
  text-overflow: ellipsis;
  vertical-align: top;
  position: relative;

  font: 500 14px / 20px Roboto;
  letter-spacing: 0.25px;

  cursor: pointer;
  user-select: none;

  display: flex;
  gap: 8px;
  align-items: center;

  padding: 2px 12px;
  border-radius: 12px;

  color: var(--2B2B31-tags-list);

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${(props): SerializedStyles => css`
    background-color: ${props.borderColor};
    border-color: ${props.borderColor};
  `}
`;

export const ArrowContainer = styled('div')<{ isOpen?: boolean }>`
  align-self: center;

  cursor: pointer;

  svg {
    ${(props): SerializedStyles => css`
      transform: ${props.isOpen ? 'rotate(90deg)' : 'rotate(0deg)'};
    `}
  }
`;

export const GroupActionContainer = styled('div')`
  align-self: center;

  stroke: none;
  fill: none;

  cursor: pointer;
`;

const groupActionsContainersVisible = css`
  ${GroupActionContainer} {
    visibility: visible;
  }
`;

export const GroupHeaderRow = styled('div')<IGroupHeaderRow>`
  position: relative;

  display: flex;
  align-items: baseline;
  gap: 8px;

  padding: ${(props): string =>
    props.addTopMargin ? '32px 16px 16px 16px' : '16px'};

  color: var(--2B2B31-profile-table-profile-name);
  stroke: ${(props): string =>
    props.isHovered ? 'var(--2B2B31)' : 'var(--98989F-profile-table)'};

  b {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  ${(props): SerializedStyles | null =>
    !props.areActionsForceVisible
      ? css`
          ${GroupActionContainer} {
            visibility: hidden;
          }
        `
      : null};

  &:hover {
    ${groupActionsContainersVisible}
  }

  // TODO: find a way to reference this inner container
  // without using this unstable class name
  ${(props): SerializedStyles | null => css`
    .ReactVirtualized__Grid__innerScrollContainer:has([data-group-key='${props.groupKey}']:hover) & {
      ${groupActionsContainersVisible}
    }`}

  &::after, &::before {
    position: absolute;
    display: block;
    left: 0px;
    right: 0px;
    height: 0px;
    width: 100%;
    transition: background-color 0.3s;
    content: '';
    z-index: 140;
  }

  &::after {
    border-bottom: 0.5px solid var(--E5E5E6-profile-table);
    bottom: 0px;
    visibility: ${(props): string =>
      props.hasElements ? 'visible' : 'hidden'};

    ${(props): SerializedStyles | '' =>
      props.dropPosition === 'bottom'
        ? css`
            border-bottom: 3px solid var(--535358);
            visibility: visible;
          `
        : ''}
  }

  &::before {
    top: 0px;
    visibility: hidden;

    ${(props): SerializedStyles | '' =>
      props.dropPosition === 'top'
        ? css`
            visibility: visible;
            border-bottom: 3px solid var(--535358);
          `
        : ''}
  }
`;

export const PlainGroupHeaderText = styled('div')`
  color: var(--535358);
  font: 400 14px / 20px Roboto;
  letter-spacing: 0.25px;

  display: flex;
  gap: 8px;
  align-items: center;

  cursor: pointer;
  user-select: none;
`;

export const GroupHeaderCounter = styled('div')`
  color: var(--767676);
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;

  cursor: pointer;
  user-select: none;

  &:hover {
    color: var(--36363D);
  }
`;

export const GroupFolderItemBody = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 400px;

  cursor: pointer;
  user-select: none;

  letter-spacing: 0.25px;

  b {
    font: 500 16px / 20px Roboto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
