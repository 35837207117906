/* eslint-disable id-length */

export const PRICING_ROUTE_NAME = 'checkout';

export const PROFILE_LIST_PAGE = '/profileList';
export const PROXY_PAGE = '/proxy';
export const PRICING_PAGE = `/${PRICING_ROUTE_NAME}`;
export const PROXY_CHECKOUT_PAGE = `/${PRICING_ROUTE_NAME}/proxy`;
export const PADDLE_CHECKOUT_PAGE = `/${PRICING_ROUTE_NAME}/paddle`;
export const PRICING_PAGE_WITH_OPENED_PAYMENT_METHODS = `${PRICING_PAGE}/payment-methods`;

export const SHARE_LINK_PAGE='/share/:profileName/:linkShortId';
