import { useAtomValue } from 'jotai';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TextCell } from '../../../../../quickProfiles/table/styles';
import { IProxyTableCellBase } from '../../interfaces';

const ConnectionType: React.FC<IProxyTableCellBase> = (props) => {
  const { proxyAtom } = props;

  const proxy = useAtomValue(proxyAtom);

  const { t: translation } = useTranslation();

  // 'gologin' & 'tor' proxies are not displayed on the proxy page
  let value = proxy.mode.toUpperCase();
  if (proxy.connectionType) {
    value = translation(`proxyTypes.${proxy.connectionType}`);
  }

  return (
    <TextCell>
      {value}
    </TextCell>
  );
};

export default ConnectionType;
