import React from 'react';
import { useLocation } from 'react-router-dom';

import { PROXY_PAGE } from '../../../../../common/constants/routes';
import { determineIsProxyCheckFailed } from '../../../../../common/proxy/utils';
import { IProxy } from '../../../../interfaces';
import { updateEditModeProfileId } from '../../../../state/edit-mode-profile-id.atom';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { getProfilesList } from '../../../../state/profiles-list.atom';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../../state/proxy/proxy-check/constants';
import { hideProxyCheckTooltip } from '../../../../state/proxy/proxy-check/proxy-check-tooltip.atom';
import { useIsProxyRestoring } from '../../../../state/proxy/proxy-check/proxy-statuses.atom';
import { ProxyCheckTooltipLocation } from '../../../../state/proxy/proxy-check/types/proxy-check-tooltip-location.type';
import { openProxyManager } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { openProxySettingsModal } from '../../../../state/proxy/proxy-settings-modal.atom';
import ProxyConnectionButton from '../../proxy-connection-button';
import ProxyUnlinkButton from '../../proxy-connection-button/proxy-unlink-button';
import ProxyEditButton from '../../proxy-edit-button';
import { getIsProxyArchived, getIsProxyEditable } from '../../proxy-helpers';
import ProxyInfoCheckDate from './proxy-info-check-date';
import ProxyInfoError from './proxy-info-error';
import ProxyInfoSuccess from './proxy-info-success';
import { ProxyInfoButtonsWrapper, ProxyInfoHead, ProxyInfoWrapper } from './styles';
import ProxyInfoArchive from './proxy-info-archive';

type ProxyInfoProps = {
  proxy: IProxy;
  profileId?: string;
  proxyTooltipView: ProxyCheckTooltipLocation;
  selectorContainerElement: Element;
};

const ProxyInfo: React.FC<ProxyInfoProps> = props => {
  const { proxy, profileId, proxyTooltipView, selectorContainerElement } = props;

  const isProxyRestoring = useIsProxyRestoring(proxy.id);

  const location = useLocation();

  const isProxyPage = location.pathname === PROXY_PAGE;
  const isProxyEditable =
    getIsProxyEditable(proxy) &&
    proxyTooltipView !== PROXY_CHECK_TOOLTIP_LOCATIONS.proxyManagerEditForm &&
    !getIsProxyArchived(proxy);

  let isProxyRemovableFromProfile = false;
  if (!isProxyPage) {
    const profiles = getProfilesList();
    const profile = profiles.find(({ id }) => id === profileId);
    if (profile && profile.proxy) {
      isProxyRemovableFromProfile = proxy.id === profile.proxy.id;
    }
  }

  const handleProxyInfoEditClick = (): void => {
    hideProxyCheckTooltip();
    if (isProxyPage) {
      return openProxySettingsModal({
        modalView: 'proxy-edit',
        currentProxy: proxy,
      });
    }

    openProxyManager({
      modalView: 'proxy-edit',
      currentProxy: proxy,
      containerElement: selectorContainerElement,
      currentProfileId: profileId,
      modalEditingProxyId: proxy.id,
      proxySelectorLocation: proxyTooltipView,
    });

    if (profileId) {
      updateEditModeProfileId(profileId);
    }
  };

  const renderConnectionButton = (): JSX.Element | null => {
    if (!isProxyRemovableFromProfile) {
      return null;
    }

    if (!NEW_FEATURES.proxyGroupsV2) {
      return (
        <>
          {isProxyEditable ? (
            <ProxyEditButton isProxyRestoring={isProxyRestoring} handleProxyInfoEditClick={handleProxyInfoEditClick} />
          ) : null}
          <ProxyConnectionButton profileId={profileId || ''} unlinkIconType='cross' placement='check-tooltip' />
        </>
      );
    }

    return <ProxyUnlinkButton profileId={profileId || ''} iconType='unlink' placement='check-tooltip' />;
  };

  const renderProxyStatusInfo = (): JSX.Element => {
    if (getIsProxyArchived(proxy)) {
      return <ProxyInfoArchive />;
    }

    if (!determineIsProxyCheckFailed(proxy)) {
      return <ProxyInfoSuccess proxy={proxy} proxyTooltipView={proxyTooltipView} profileId={profileId} /> ;
    }

    return <ProxyInfoError proxy={proxy} />;
  };

  return (
    <ProxyInfoWrapper onClick={(event): void => event.stopPropagation()}>
      <ProxyInfoHead>
        <ProxyInfoCheckDate proxy={proxy} />
        {proxy.checkDate ? (
          <ProxyInfoButtonsWrapper isSingleButtonVisible={!!(isProxyEditable && isProxyRemovableFromProfile)}>
            {renderConnectionButton()}
          </ProxyInfoButtonsWrapper>
        ) : null}
      </ProxyInfoHead>
      {renderProxyStatusInfo()}
    </ProxyInfoWrapper>
  );
};

export default ProxyInfo;
