import { INewFeatures, NEW_FEATURES } from '../state/feature-toggle/new-features';
import { DBG_FEATURE_OVERRIDES_KEY } from './constants';
import { log } from './log';

export const prepareDbgFeatureDef = () => ({
  list: (): void => {
    Object.entries(NEW_FEATURES).map(([key, val]) => {
      log(`${key}: ${!!val}`);
    });
  },
  overrides: {
    get: () => {
      return readDbgLocalFeaturesOverrides();
    },
    set: (key: string, val: boolean) => {
      const localOverrides = readDbgLocalFeaturesOverrides();
      const newOverrides = { ...localOverrides, [key]: val };

      localStorage.setItem(DBG_FEATURE_OVERRIDES_KEY, JSON.stringify(newOverrides));
      log(`${Object.keys(newOverrides).length} overrides written.`);
    },
    clear: () => {
      localStorage.removeItem(DBG_FEATURE_OVERRIDES_KEY);
      log(`overrides cleared.`);
    },
  },
});

export const readDbgLocalFeaturesOverrides = (): Partial<INewFeatures> => {
  const localOverridesStr = localStorage.getItem(DBG_FEATURE_OVERRIDES_KEY);
  let localOverrides: Partial<INewFeatures> = {};
  if (localOverridesStr) {
    try {
      localOverrides = JSON.parse(localOverridesStr);
      // eslint-disable-next-line no-empty
    } catch (err) { }
  }

  return localOverrides;
};
