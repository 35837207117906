import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProxyFormProxy, updateProxyForm, useProxyForm } from '../../../../../../state/proxy/custom-proxy-form-data.atom';
import { ProxyFormInputEl } from '../../styles';

export type CustomProxyPastableInputProps = {
  handlePaste: React.ClipboardEventHandler<HTMLInputElement>;
}

type CustomProxyStringFields = Pick<ProxyFormProxy,
  'customName' |
  'username' |
  'password' |
  'changeIpUrl'
>;

type CustomProxyStringInputProps = CustomProxyPastableInputProps & {
  fieldName: keyof CustomProxyStringFields;
}

const CustomProxyStringInput: React.FC<CustomProxyStringInputProps> = (props) => {
  const { fieldName, handlePaste } = props;

  const customProxyForm = useProxyForm();

  const { t: translation } = useTranslation();

  const placeholder = translation(`proxies.formFields.${fieldName}Placeholder`);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    updateProxyForm({ [fieldName]: event.target.value });
  };

  return (
    <ProxyFormInputEl
      value={customProxyForm[fieldName]}
      type='text'
      name={fieldName}
      onChange={handleChange}
      onPaste={handlePaste}
      placeholder={placeholder} 
    />
  );
};

export default CustomProxyStringInput;
