import { DefaultParamType, TFnType, TranslationKey } from '@tolgee/react';
import { message } from 'antd';
import moment from 'moment';
import React from 'react';

import { IPlan } from '../../../../../interfaces/plan';
import IconCreditCard from '../../../../../ui/icons/IconCreditCard';
import IconGooglePay from '../../../../../ui/icons/IconGooglePay';
import IconPayPal from '../../../../../ui/icons/IconPayPal';
import { requestUser, requestWorkspace, sendErrorPaymentAnalytics } from '../../../../common/api';
import { IPaymentIntent, PaymentType, IPricingConfigCountry, IUpdateSelectedPlanParameters, TPaymentTab, IPaymentTabConfig } from '../../../../pricing/interfaces';
import { DISABLED_PLANS, MONTH_NAMES, IPaymentType, ANNUAL_DISCOUNT } from '../constants';

export const getPlanExpireDate = async (workspaceId?: string): Promise<string> => {
  if (workspaceId) {
    const { planExpiresAt = '' } = await requestWorkspace(workspaceId);

    return planExpiresAt.toString();
  }

  const { planExpireDate = '' } = await requestUser();

  return planExpireDate;
};

export const isDiscountAnnual = (selectedDiscount: string): boolean => selectedDiscount === ANNUAL_DISCOUNT;
export const getPricingPlans = (plans: IPlan[]): IPlan[] => plans.filter(plan => !DISABLED_PLANS.includes(plan.name));
export const getSelectedPlan = (plans: IPlan[], selectedPlan: string): IPlan => {
  const planToPay = plans.find(({ id }) => id === selectedPlan);

  if (!planToPay) {
    throw new Error('Plan is not defined');
  }

  return planToPay;
};

export const updateSelectedPlan = async (updateSelectedPlanParameters: IUpdateSelectedPlanParameters): Promise<void> => {
  const { plans, selectedPlan, userContextPlan, updateWorkspaceParams } = updateSelectedPlanParameters;
  const { updateUser } = userContextPlan;

  if (!updateUser) {
    return;
  }

  const { card, planExpireDate, payment } = await requestUser();
  const { workspaceId } = updateWorkspaceParams || {};
  let expiresDate = planExpireDate;
  if (workspaceId) {
    const { planExpiresAt = '' } = await requestWorkspace(workspaceId);
    expiresDate = planExpiresAt.toString();
  }

  updateUser({
    plan: getSelectedPlan(plans, selectedPlan),
    hasTrial: false,
    trialDays: 0,
    planExpireDate: expiresDate,
    card,
    payment,
  });

  if (!updateWorkspaceParams) {
    return;
  }

  const { updateWorkspaceHooks } = updateWorkspaceParams;
  const { updateWorkspace, updateAvailableWorkspace } = updateWorkspaceHooks;
  const { id, name, maxProfiles = 0 } = getSelectedPlan(plans, selectedPlan);
  if (!(workspaceId && updateWorkspace && updateAvailableWorkspace)) {
    return;
  }

  updateWorkspace({
    isUnpaid: false,
    paymentIsTrial: false,
    planExpiresAt: moment(expiresDate).toDate(),
  });

  updateAvailableWorkspace(workspaceId, {
    planId: id,
    planName: name,
    planProfilesMax: maxProfiles,
    visibleExpiresDate: moment(expiresDate).toDate(),
  });
};

export const getMonthCount = (isAnnual: boolean): number => isAnnual ? 12 : 1;
export const closeThreeDSecureModal = async (paymentInstant: any): Promise<void> => {
  if (!paymentInstant) {
    return;
  }

  paymentInstant
    .then(paymentInstant.checkTransaction)
    .catch((error: any) => {
      if (error && error.manualError) {
        message.error(error.message || '');
      }
    });
};

export const getCardInputStyles = (): any => ({
  style: {
    base: {
      fontFamily: 'Roboto, sans-serif',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: '0.15px',
      color: 'var(--FFFFFF)',

      '::placeholder': {
        color: 'var(--98989F)',
      },
      padding: '0',
    },
    invalid: {
      color: 'var(--9E2146)',
    },
  },
});

export const getCardNumberOptions = (): any => ({
  ...getCardInputStyles(),
  placeholder: '0000 0000 0000 0000',
});

export const getCardExpireOptions = (): any => ({
  ...getCardInputStyles(),
});

export const getCardCVCOptions = (): any => ({
  ...getCardInputStyles(),
  placeholder: '***',
});

interface IPlanMaxProfilesParams {
  translation: TFnType<DefaultParamType, string, TranslationKey>;
  planToPay: IPlan;
  paymentMethod: IPaymentIntent['paymentMethod'];
  profiles?: number;
}

export const checkPlanMaxProfiles = ({
  translation,
  planToPay,
  profiles,
  paymentMethod,
}: IPlanMaxProfilesParams): boolean => {
  const { maxProfiles, name } = planToPay;
  let status = true;
  if (maxProfiles && profiles && (profiles > maxProfiles)) {
    status = false;
    const errorMessage = `
      ${translation('checkoutModal.notification.firstText', { count: maxProfiles })}
      ${translation('checkoutModal.notification.secondText', { count: profiles })}
      `;

    message.error(errorMessage);
    sendErrorPaymentAnalytics({
      messageText: errorMessage,
      extra: {
        plan: name,
        scenario: 'availableToPay',
      },
      method: paymentMethod,
    });
  }

  return status;
};

export const getUserBrowser = (): string | null => {
  const { userAgent } = navigator;

  if (userAgent.match(/edg/i)) {
    return 'edge';
  }

  if (userAgent.match(/chrome|chromium|crios/i)) {
    return 'chrome';
  }

  if (userAgent.match(/safari/i)) {
    return 'safari';
  }

  return null;
};

export const getUserDevice = (): string | undefined => {
  const { platform } = navigator;
  const USER_PLATFORMS = ['Win', 'Mac', 'Linux', 'Android', 'iPhone'];

  return USER_PLATFORMS.find(userPlatform => platform.includes(userPlatform));
};

export const getIsChromeBrowser = (): boolean => getUserBrowser() === 'chrome';
export const getIsMobilePaymentAvailable = (): boolean => getIsChromeBrowser() || getUserBrowser() === 'safari';
export const getIsGooglePay = (): boolean | null => {
  const userDevice = getUserDevice();
  if (!userDevice) {
    return null;
  }

  const IS_GOOGLE_PAY_PLATFORM: {[key: string]: boolean} = {
    'Win': true,
    'Mac': getIsChromeBrowser(),
    'Linux': true,
    'Android': true,
    'iPhone': false,
  };

  return IS_GOOGLE_PAY_PLATFORM[userDevice] ?? true;
};

export const getNextPaymentDate = (isAnnual: boolean, planExpiresAt = ''): string => {
  if (planExpiresAt) {
    return moment(planExpiresAt).format('MMM DD, YYYY');
  }

  const date = new Date();
  if (isAnnual) {
    date.setFullYear(date.getFullYear() + 1);
  } else {
    date.setMonth(date.getMonth() + 1);
  }

  return `${MONTH_NAMES[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

export const getFirstPaymentTab = (tabs: IPaymentTabConfig[]): TPaymentTab => {
  const [firstTab] = tabs;
  const { type: firstTabPaymentType = PaymentType.Card } = firstTab;

  return firstTabPaymentType;
};

export const getPaymentTypes = (config: IPricingConfigCountry, selectedDiscount?: string): IPaymentType[] => 
  config.tabs.map((tab, index) => {
  let icon = <IconCreditCard />;
  if (tab.type === PaymentType.PayPal) {
    icon = <IconPayPal />;
  }

  if (tab.type === PaymentType.Mobile) {
    icon = <IconGooglePay />;
  }

  return {
    id: String(++index),
    Icon: icon,
    type: tab.type,
  };
});
