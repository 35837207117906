import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

import { textFont } from '../../../ui/style-templates';
import { PROXY_ITEM_ELEMENT_SEMI_TRANSPARENCY } from '../constants/styles';
import { PROXY_SELECTOR_CONTENT_HEIGHT } from '../proxy-selector/styles';

export const ProxySearchInputEl = styled.input<{
  width?: number;
  truncate: boolean;
  isTransparent: boolean;
  newStyle: boolean;
  isInDrawer: boolean;
  isProxyManagerWithGroups: boolean;
}>`
  height: ${PROXY_SELECTOR_CONTENT_HEIGHT};
  max-width: 100%;
  padding: 0;
  border: none;
  outline: none;

  ${textFont()}
  line-height: ${PROXY_SELECTOR_CONTENT_HEIGHT};

  color: var(--000000-proxy-manager-search-input);
  background: none;
  opacity: ${(props): React.CSSProperties['opacity'] => props.isTransparent ? PROXY_ITEM_ELEMENT_SEMI_TRANSPARENCY : 1};

  ${(props): SerializedStyles => props.width ? css`
    width: ${props.width}px;
    min-width: ${props.width}px;
    display: ${props.newStyle ? 'flex' : 'block'};
  ` : css`
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  `}

  ${(props): SerializedStyles | null => props.truncate ? css`
    text-overflow: ellipsis;
  ` : null}

  tr:hover, .gologin-table-row:hover & {
    opacity: 1;
  }

  &:focus {
    outline: none !important;
    box-shadow: none;
  }

  &::placeholder {
    color: var(--CDCDCD-proxy-manager-search-input);
  }

  ${(props): SerializedStyles | null => props.isProxyManagerWithGroups ? css`
    cursor: pointer;
    user-select: none;
  ` : null}
`;
