import { API_BASE_URL } from '../../../common/constants/constants';
import { http } from '../../services';
import type {
  DeltaSyncApiVer,
  DeltaSyncClientVer,
  DeltaSyncEvent,
  ObjectPoolFolderProfile,
  ObjectPoolProfile,
} from '../types';

type FetchDeltaSyncDataParams = {
  workspaceId: string;
  syncSince: string;
  apiVer: DeltaSyncApiVer;
  clientVer: DeltaSyncClientVer;
}

export type FetchDeltaSyncData = {
  workspaces: Array<{
    profiles: Omit<ObjectPoolProfile, 'objectPoolEntityType'>[];
    foldersProfiles: Omit<ObjectPoolFolderProfile, 'objectPoolEntityType'>[];
  }>;
  events: Array<DeltaSyncEvent>;
  latestSyncDate: string;
  isSyncDone: boolean;
}

export const fetchDeltaSyncData = async ({
  workspaceId,
  syncSince,
  apiVer = 'v2',
  clientVer = 1,
}: FetchDeltaSyncDataParams): Promise<FetchDeltaSyncData> =>
  http(`${API_BASE_URL}/delta-sync/${apiVer}?clientVer=${clientVer}&onlyWorkspaceId=${workspaceId}&syncSince=${syncSince}`, {
    method: 'GET',
  }).then((res: any) => res.body);
