import styled from '@emotion/styled';

import IconArrowDown3 from '../../../../../ui/icons/IconArrowDown3';

export const ReadMoreButton = styled.button`
  color: var(--767676-proxy-manager-info-error);
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  letter-spacing: 0.25px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
`;

export const IconArrow = styled(IconArrowDown3)`
  margin-left: 7px;
  stroke: var(--767676-proxy-manager-info-error);
  display: flex; 
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ArhiveTitleText = styled.div`
  display: flex;
  line-height: 20px;
  letter-spacing: 0.25px;
  font-size: 14px;
`;

export const ArchiveDetails = styled.div`
  font-size: 14px;
  margin-top: 8px;
`;

export const ReadMoreBlockWrapper = styled.div`
  position: absolute;
  background-color: var(--FFFFFF-proxy-manager-info-error);
  border-radius: 0px 0px 4px 4px;
  box-shadow: var(--00000026-proxy-manager-info-error) 0px 2px 8px;
  z-index: 100;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0px 16px 12px;
  margin-top: -13px;
  line-height: 20px;
  letter-spacing: 0.25px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: -5px;
    height: 5px;
    width: 100%;
    background: var(--FFFFFF-proxy-manager-info-error);
  }
`;
