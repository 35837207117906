import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { PROXY_PAGE } from '../../../../../common/constants/routes';
import { determineIsProxyMode } from '../../../../../common/constants/types';
import { IProxy } from '../../../../interfaces';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { updateProxyForm } from '../../../../state/proxy/custom-proxy-form-data.atom';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../../state/proxy/proxy-check/constants';
import { pasteProxyToForm } from '../../../../state/proxy/proxy-operations/paste-proxies-operations';
import { IconProxyPaste } from '../../../../ui/gologin-header/icons/icon-proxy-paste';
import CheckProxyBtn from '../../check-proxy-button';
import ProxyFlag, { SMALL_FLAG_HEIGHT } from '../../proxy-flag';
import ControlIconButton, {
  CONTROL_BUTTON_TOOLTIP_KEY,
} from '../../proxy-page/proxy-settings-modal/control-icon-button';
import { ProxyFlagBlockEl } from '../proxy-list/styles';
import { ProxyModeSelector } from './proxy-mode-selector';
import { CheckProxyButtonWrapper, HostPortSplitter, ProxyFormInputEl } from './styles';

type ProxyEditFormFieldSlot = React.FC<{ style?: React.CSSProperties }>;

type ProxyEditFormFieldsProps = {
  formValues: IProxy;
  currentProfileId: string | null;
  Row: ProxyEditFormFieldSlot;
  RowKey: ProxyEditFormFieldSlot;
  RowValue: ProxyEditFormFieldSlot;
  rowStyles?: React.CSSProperties;
};

const ProxyEditFormFields: React.FC<ProxyEditFormFieldsProps> = props => {
  const { formValues, currentProfileId, Row, RowKey, RowValue, rowStyles } = props;

  const location = useLocation();
  const { t: translation } = useTranslation();

  const handleChangeMode: React.ChangeEventHandler<HTMLInputElement> = event => {
    const newMode = event.target.value;
    const isProxyMode = determineIsProxyMode(newMode);
    if (isProxyMode) {
      return updateProxyForm({ mode: newMode });
    }

    // TODO: sentry
  };

  const handleChangeStringValue: React.ChangeEventHandler<HTMLInputElement> = event => {
    updateProxyForm({ [event.target.name]: event.target.value });
  };

  const handleChangePort: React.ChangeEventHandler<HTMLInputElement> = event => {
    const newPort = event.target.value;
    updateProxyForm({ port: newPort });
  };

  const handlePaste: React.ClipboardEventHandler<HTMLInputElement> = async event => {
    const clipboardText = await navigator.clipboard.readText();
    const pasteProxyRes = await pasteProxyToForm(formValues, clipboardText);
    if (pasteProxyRes) {
      event.preventDefault();
    }
  };

  const handlePasteChangeIpUrl: React.ClipboardEventHandler<HTMLInputElement> = async event => {
    const clipboardText = event.clipboardData.getData('Text');
    const isPossibleUrl = clipboardText.startsWith('http://') || clipboardText.startsWith('https://');
    if (isPossibleUrl) {
      // without event.preventDefault - pastes into field, without parsing
      return;
    }

    const pasteProxyRes = await pasteProxyToForm(formValues, clipboardText);
    if (pasteProxyRes) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Row style={rowStyles}>
        <RowKey>
          <Trans i18nKey='proxies.formFields.type' />
        </RowKey>
        <RowValue>
          <ProxyModeSelector currentMode={formValues.mode} handleChangeMode={handleChangeMode} />
        </RowValue>
      </Row>
      <Row style={rowStyles}>
        <RowKey>
          <Trans i18nKey='proxies.formFields.customName' />
        </RowKey>
        <RowValue>
          <ProxyFormInputEl
            value={formValues.customName}
            name='customName'
            onChange={handleChangeStringValue}
            onPaste={handlePaste}
            placeholder={translation('proxies.formFields.customNamePlaceholder') || ''}
          />
        </RowValue>
      </Row>
      <Row style={rowStyles}>
        <RowKey style={{ width: 40 }}>
          <Trans i18nKey='proxies.formFields.host' />
        </RowKey>
        <RowValue
          style={
            location.pathname !== PROXY_PAGE
              ? {
                  marginLeft: NEW_FEATURES.header ? 82 : 57,
                }
              : {
                  marginLeft: NEW_FEATURES.header && formValues.country ? -24 : 0,
                  alignItems: 'center',
                }
          }
        >
          <ProxyFlagBlockEl
            newStyle={!!NEW_FEATURES.header}
            style={{
              opacity: formValues.host && formValues.port ? 1 : 0,
            }}
          >
            {formValues.id ? (
              <CheckProxyButtonWrapper>
                <CheckProxyBtn
                  key={`${formValues.id}-${currentProfileId}`}
                  proxy={formValues}
                  profileId={currentProfileId || ''}
                  proxyTooltipView={PROXY_CHECK_TOOLTIP_LOCATIONS.proxyManagerEditForm}
                />
              </CheckProxyButtonWrapper>
            ) : null}

            {formValues.country ? <ProxyFlag countryCode={formValues.country} height={SMALL_FLAG_HEIGHT} /> : null}
          </ProxyFlagBlockEl>
          <ProxyFormInputEl
            value={formValues.host}
            name='host'
            onChange={handleChangeStringValue}
            onPaste={handlePaste}
            placeholder={translation('proxies.formFields.hostPlaceholder') || ''}
            inputWidth={111}
          />
          <HostPortSplitter>:</HostPortSplitter>
          <ProxyFormInputEl
            value={formValues.port}
            name='port'
            onChange={handleChangePort}
            onPaste={handlePaste}
            placeholder={translation('proxies.formFields.portPlaceholder') || ''}
            inputWidth={58}
          />
          {location.pathname === PROXY_PAGE ? (
            <ControlIconButton
              handleClick={handlePaste}
              tooltipTranslationKey={CONTROL_BUTTON_TOOLTIP_KEY.pasteProxy}
              wrapperStyles={{ position: 'relative', right: 8, marginRight: 8 }}
              Icon={IconProxyPaste}
            />
          ) : null}
        </RowValue>
      </Row>
      <Row style={rowStyles}>
        <RowKey>
          <Trans i18nKey='proxies.formFields.username' />
        </RowKey>
        <RowValue>
          <ProxyFormInputEl
            value={formValues.username}
            autoComplete='new-password'
            name='username'
            onChange={handleChangeStringValue}
            onPaste={handlePaste}
            placeholder={translation('proxies.formFields.usernamePlaceholder') || ''}
          />
        </RowValue>
      </Row>
      <Row style={rowStyles}>
        <RowKey>
          <Trans i18nKey='proxies.formFields.password' />
        </RowKey>
        <RowValue>
          <ProxyFormInputEl
            value={formValues.password}
            autoComplete='new-password'
            name='password'
            type='text'
            onChange={handleChangeStringValue}
            onPaste={handlePaste}
            placeholder={translation('proxies.formFields.passwordPlaceholder') || ''}
            data-lpignore='true'
          />
        </RowValue>
      </Row>
      <Row style={rowStyles}>
        <RowKey>
          <Trans i18nKey='proxies.formFields.changeIpUrl' />
        </RowKey>
        <RowValue>
          <ProxyFormInputEl
            value={formValues.changeIpUrl}
            name='changeIpUrl'
            onChange={handleChangeStringValue}
            onPaste={handlePasteChangeIpUrl}
            placeholder={translation('proxies.formFields.changeIpUrlPlaceholder') || ''}
          />
        </RowValue>
      </Row>
    </>
  );
};

export default ProxyEditFormFields;
