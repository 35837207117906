import { atom, getDefaultStore } from 'jotai';

import { NEW_FEATURES } from '../state/feature-toggle/new-features';

type DbgMonitorLevel = 'none'|'trace'|'info';
type DbgMonitorUnitKey = 'profiles-group-headers' | 'profiles-table-render';
type DbgMonitorUnit = {
  key: DbgMonitorUnitKey;
  level: DbgMonitorLevel;
};

type DbgMonitorConfig = {
  units: Record<DbgMonitorUnitKey, DbgMonitorUnit>;
}

const DEFAULT_DBG_MONITOR: DbgMonitorConfig = {
  units: {
    'profiles-table-render': {
      key: 'profiles-table-render',
      level: 'trace',
    },
    'profiles-group-headers': {
      key: 'profiles-group-headers',
      level: 'info',
    },
  },
};

const dbgMonitorConfigAtom = atom(DEFAULT_DBG_MONITOR);
const setDbgMonitorConfig = (newConfig: DbgMonitorConfig) => getDefaultStore().set(dbgMonitorConfigAtom, newConfig);
const getDbgMonitorConfig = () => getDefaultStore().get(dbgMonitorConfigAtom);
const getDbgMonitorUnitConfig = (unitKey: DbgMonitorUnitKey) => getDbgMonitorConfig().units[unitKey];

export const prepareDbgMonitorDef = () => ({
  getDbgMonitorConfig,
  setDbgMonitorConfig,
});

export const logToDbgMonitor = (unitKey: DbgMonitorUnitKey, objToLog: object) => {
  if (!NEW_FEATURES.debugUtils) {
    return;
  }

  const unitConfig = getDbgMonitorUnitConfig(unitKey);
  if (unitConfig.level === 'trace') {
    console.trace();
  }

  if (unitConfig.level !== 'none') {
    console.log(JSON.stringify({ unit: unitKey, objToLog }, null, 2));
  }
};
