import { message } from 'antd';
import moment from 'moment/moment';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { getActionButtons } from './get-action-buttons';
import { IProfilesMenu, MenuItemsProps } from './interfaces';
import { MenuItemWithPopconfirm } from './menu-item-with-popconfirm';
import { MassActionsButtonLine, MassActionsMarginBlock } from './styles';
import { PROFILE_LIST_PAGE } from '../../../../common/constants/routes';
import { DEFAULT_MANY_PROFILE_NAME } from '../../../hooks';
import { IProfile } from '../../../interfaces';
import { browserUpdaterContext, importFilesContext, templatesContext, userContext, workspaceContext } from '../../../state';
import { useEditModeProfileId } from '../../../state/edit-mode-profile-id.atom';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { filterProfilesByPermission } from '../../../state/profiles-list.atom';
import {
  closeProfilesSettings,
  openProfilesSettingsOfSelectedIds,
  useIsProfilesSettingsOpen,
} from '../../../state/profiles-settings-atom';
import {
  resetProfilesTableSelectedIds,
  toggleAllProfilesSelected,
  useAllProfilesSelectionStatus, useProfilesTableSelectedIds,
} from '../../../state/profiles-table-selected-ids.atom';
import { checkCompetitorsForMigration } from '../../../ui/Footer/utils';
import { GologinNewCheckbox } from '../../../ui/gologin-checkbox/gologin-new-checkbox';
import {
  IconClose,
} from '../../../ui/gologin-header/icons';
import TooltipCustom from '../../../ui/tooltip-custom';
import { openWebsitePricing } from '../../../utils/open-site';
import {
  getActionsButtonStyles,
  MassActionsBlockLine,
  MassActionsBlockLineCorners,
  MassActionsButtonText,
  MassActionsRow,
} from '../../common/custom-table/components/mass-actions-menu/styles';
import ExtensionsModal from '../../modalsComponents/components/extensions-modal/extensions-modal';
import ImportDolphinProfilesModal from '../../modalsComponents/components/import-dolphin-profiles';
import MultipleProxyManager from '../../modalsComponents/components/multiple-proxy-manager';
import ProfileBookmarksModal from '../../modalsComponents/components/profile-bookmarks';
import { exportWorkspaceProfiles } from '../../modalsComponents/components/profiles-import-manager/api';
import { updateMultipleFingerprints } from '../../updateProfile/api';
import EditTagsMenu from '../components/edit-tags-menu';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

const MultipleOperationsMenu: FC<IProfilesMenu> = (props): JSX.Element => {
  const {
    launchProfileOrbita,
    changeProfilesData,
    updateProfileFingerprints,
    headerRef,
  } = props;

  const [isProcessing, changeProcessing] = useState<boolean>(false);
  const [isShowProxyManager, setIsShowProxyManager] = useState<boolean>(false);
  const [extensionsModalVisible, setExtensionsModalVisible] = useState<boolean>(false);
  const [isShowBookmarksManager, setIsShowBookmarksManager] = useState<boolean>(false);
  const [isShowImportDolphinModal, setIsShowImportDolphinModal] = useState<boolean>(false);
  const [anchorElTooltip, setAnchorElTooltip] = useState<HTMLElement|null>(null);
  const [anchorEditTagsEl, setAnchorEditTagsEl] = useState<null | HTMLElement>(null);

  const { setManyProfilesName, setNeedOpenSpintaxPopup } = useContext(importFilesContext);
  const { selectedTemplate } = useContext(templatesContext);
  const {
    migrationData = {},
    isQuickSettingsEnabled,
  } = useContext(userContext);

  const {
    id: workspaceId,
    isUnpaid: workspaceIsUnpaid,
    paymentIsTrial: workspaceIsTrial,
  } = useContext(workspaceContext);

  const { browserUpdating: isBrowserUpdating } = useContext(browserUpdaterContext);

  const editModeProfileId = useEditModeProfileId();
  const isProfilesSettingsOpen = useIsProfilesSettingsOpen();
  const selectedProfilesIds = useProfilesTableSelectedIds();

  const isProfileListPage = !!useRouteMatch(PROFILE_LIST_PAGE);

  const { t: translation } = useTranslation();

  const allRowsSelectionStatus = useAllProfilesSelectionStatus();
  const isSelected = allRowsSelectionStatus === 'all';
  const isIndeterminate = allRowsSelectionStatus === 'some';

  const canExportProfiles = filterProfilesByPermission(selectedProfilesIds, 'exportProfile');
  const showExportPayPopUp = !canExportProfiles && (workspaceIsUnpaid || workspaceIsTrial);

  const EscapePressListener = (event: KeyboardEvent): void => {
    if (event.key !== 'Escape') {
      return;
    }

    resetProfilesTableSelectedIds();
    closeProfilesSettings();
  };

  useEffect(() => {
    window.addEventListener('keydown', EscapePressListener);

    return () => {
      window.removeEventListener('keydown', EscapePressListener);
    };
  }, []);

  useEffect((): (() => void) | void => {
    if (!isElectron) {
      return;
    }

    checkCompetitorsForMigration(migrationData, ipcRenderer).catch(() => void 0);
    ipcRenderer.on('dolphin-anty-detected', () => {
      setIsShowImportDolphinModal(true);
    });

    return () => ipcRenderer.removeAllListeners('dolphin-anty-detected');
  }, []);

  const exportProfile = async (): Promise<void> => {
    changeProcessing(true);

    let response;
    response = await exportWorkspaceProfiles(workspaceId, selectedProfilesIds).catch(() => null);

    if (!response) {
      changeProcessing(false);

      return;
    }

    const saveFileElement = document.createElement('a');
    const outputFile = new Blob(['\ufeff', response], { type: 'text/plain' });
    const date = moment().utc().format('YYYYMMDD');

    saveFileElement.href = URL.createObjectURL(outputFile);
    saveFileElement.download = `gologin_profiles_export_${date}.csv`;

    saveFileElement.click();

    changeProcessing(false);
  };

  const redirectToSite = async (): Promise<void> => {
    openWebsitePricing({
      workspaceId,
      isQuickSettingsEnabled,
      isShowPaymentMethods: true,
    });
  };

  const updateFingerprints = async () => {
    changeProcessing(true);
    const resolution = `${screen.width}x${screen.height}`;

    const newProfilesData = await updateMultipleFingerprints({
      resolution,
      instanceIds: selectedProfilesIds,
      template: selectedTemplate.id,
    }).finally(() => changeProcessing(false));

    updateProfileFingerprints(newProfilesData);
    message.success(translation('notifications.success.fingerprintsUpdated'));
  };

  const onRenameProfiles = (): void => {
    openProfilesSettingsOfSelectedIds();
    setManyProfilesName(selectedTemplate.profileName.rename || DEFAULT_MANY_PROFILE_NAME);
    if (isProfilesSettingsOpen) {
      setNeedOpenSpintaxPopup(true);

      return;
    }

    document.getElementById('pinned-name-param-profile-name')?.click();
  };

  const openExtensionsModal = (): void => {
    setExtensionsModalVisible(true);
  };

  const openBookmarksManager = (): void => {
    setIsShowBookmarksManager(true);
  };

  const handleEditTagsClick = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setAnchorEditTagsEl(anchorEditTagsEl ? null : event.currentTarget);
  };

  const actionButtons: MenuItemsProps[] = getActionButtons({
    translation,
    isBrowserUpdating,
    setAnchorElTooltip,
    setIsShowProxyManager,
    handleEditTagsClick,
    openExtensionsModal,
    openBookmarksManager,
    onRenameProfiles,
    exportProfile,
    showExportPayPopUp,
    redirectToSite,
    updateFingerprints,
    isProcessing,
    launchProfileOrbita,
  });

  const rootElem = document.getElementById('root');
  const [hasBorderRadius, setHasBorderRadius] = useState(true);
  useEffect(() => {
    const subHeader = document.getElementById('promobar');
    const mainHeader = document.getElementById('main-header');

    if (!subHeader || !mainHeader) {
      return;
    }

    const handleScroll = (): void => {
      const subHeaderRect = subHeader.getBoundingClientRect();
      const headerRect = mainHeader.getBoundingClientRect();

      setHasBorderRadius(
        subHeader.hasChildNodes()
          ? subHeaderRect.bottom < headerRect.bottom
          : true,
      );
    };

    rootElem?.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      rootElem?.removeEventListener('scroll', handleScroll);
    };
  }, [selectedProfilesIds?.length, isProfileListPage]);

  return (
    <>
      <MassActionsBlockLine
        oldStyle={!NEW_FEATURES.header}
        style={{
          backgroundColor: NEW_FEATURES.header
            ? 'var(--FFFFFF)'
            : 'var(--F6F7F9-profile-table-profiles-menu)',
        }}
        isVisible={!!selectedProfilesIds?.length}
        isProxyManagerVisible={!!editModeProfileId}
        isProfilePage={isProfileListPage}
        hasBorderRadius={hasBorderRadius && NEW_FEATURES.header}
        ref={headerRef}
      >
        <MassActionsBlockLineCorners hasBorderRadius={!!selectedProfilesIds?.length} oldStyle={!NEW_FEATURES.header}>
          <MassActionsMarginBlock isProfilesSettingsOpen={isProfilesSettingsOpen}>
            <MassActionsButtonLine
              {...getActionsButtonStyles(isProcessing)}
              iconHoveredColor={NEW_FEATURES.header ? 'var(--36363D)' : 'var(--00B86D)'}
              oldStyle={!NEW_FEATURES.header}
              style={{ cursor: 'default' }}
            >
              <MassActionsRow>
                <GologinNewCheckbox
                  onChange={toggleAllProfilesSelected}
                  checked={isSelected}
                  isIndeterminate={isIndeterminate}
                  isSmall={true}
                />
                <MassActionsButtonText>
                  {translation('tableProfiles.multipleOperations.selected', { count: selectedProfilesIds.length })}
                </MassActionsButtonText>
                <IconClose
                  onClick={resetProfilesTableSelectedIds}
                  padding={0}
                  iconColor='var(--B5B5BA-header-account-menu)'
                  iconHoveredColor={NEW_FEATURES.header ? 'var(--36363D)' : 'var(--00B86D)'}
                  size={12}
                />
              </MassActionsRow>
            </MassActionsButtonLine>
            {actionButtons.map((actionButton, index) => (
              <MenuItemWithPopconfirm key={index} {...actionButton} />
            ))}
          </MassActionsMarginBlock>
        </MassActionsBlockLineCorners>
      </MassActionsBlockLine>
      <MultipleProxyManager
        onClose={(): void => setIsShowProxyManager(false)}
        visible={isShowProxyManager}
      />
      <ProfileBookmarksModal
        visible={isShowBookmarksManager}
        onClose={() => setIsShowBookmarksManager(false)}
        profilesIds={selectedProfilesIds}
      />
      <ImportDolphinProfilesModal
        visible={isShowImportDolphinModal}
        onClose={() => setIsShowImportDolphinModal(false)}
      />
      <EditTagsMenu
        selectedRows={selectedProfilesIds}
        anchorEditTagsEl={anchorEditTagsEl}
        setAnchorEditTagsEl={setAnchorEditTagsEl}
      />
      <ExtensionsModal
        extensionsModalVisible={extensionsModalVisible}
        setExtensionsModalVisible={setExtensionsModalVisible}
        profileIds={selectedProfilesIds}
        updateProfileInfo={(newProfilesData: Partial<IProfile>): void => changeProfilesData(selectedProfilesIds, newProfilesData)}
      />
      <TooltipCustom
        anchorEl={anchorElTooltip}
        value={translation('notifications.error.orbitaIsDownloading')}
      />
    </>
  );
};

export default MultipleOperationsMenu;
