import styled from '@emotion/styled';

import { textFont } from '../../../../../ui/style-templates';

export const ProxySectionItemWrapper = styled.div`
  width: 100%;
  padding: 12px 12px 2px 10px;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  cursor: default;
  user-select: none;

  position: relative;
`;

export const ProxySectionItemTitle = styled.span`
  ${textFont()}

  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;

  color: var(--767676-proxy-manager-list-item);
`;

export const HorizontalLine = styled.div`
  width: 98%;
  border: none;
  height: 0.5px;
  background-color: var(--D2D2D5);
  margin: 0 auto;
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
`;
