import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { ProxyInfoRowWrapper } from '../styles';
import { ArchiveDetails, ArhiveTitleText, IconArrow, ReadMoreButton } from './styles';

const ProxyInfoArchive: React.FC = () => {
  const [isReadMoreVisible, setIsReadMoreVisible] = useState<boolean>(false);

  const handleReadMoreClick = (): void => setIsReadMoreVisible(!isReadMoreVisible);

  return (
    <>
      <div onClick={e => e.stopPropagation()}>
        <ArhiveTitleText>
          <Trans i18nKey={'proxyPage.archivedProxyStateTooltip'} />
        </ArhiveTitleText>
        {isReadMoreVisible && (
          <ArchiveDetails>
            <Trans i18nKey={'proxyPage.archivedProxyStateTooltipDescription'} />
          </ArchiveDetails>
        )}
      </div>
      {isReadMoreVisible ? (
        <ProxyInfoRowWrapper>
          <ReadMoreButton onClick={handleReadMoreClick}>
            <Trans i18nKey='proxies.readLess' />
            <IconArrow color='var(--767676-proxy-manager-info-error)' style={{ transform: 'scaleY(-1)' }} />
          </ReadMoreButton>
        </ProxyInfoRowWrapper>
      ) : (
        <ProxyInfoRowWrapper>
          <ReadMoreButton onClick={handleReadMoreClick}>
            <Trans i18nKey='proxies.readMore' />
            <IconArrow color='var(--767676-proxy-manager-info-error)' />
          </ReadMoreButton>
        </ProxyInfoRowWrapper>
      )}
    </>
  );
};

export default ProxyInfoArchive;
