/* eslint-disable max-lines */
import styled from '@emotion/styled';
import { Button, Icon, Input, InputNumber, message, Radio, Select, Switch, Table, Tooltip } from 'antd';
import React from 'react';
import CopyToClipBoard from 'react-copy-to-clipboard';
import { Trans, TransProps, withTranslation } from 'react-i18next';

import { TooltipText } from './styles';
import { DEVICE_MEMORY, HARDWARE_CONCURRENCY } from '../../../interfaces';
import { closeProfilesTableModal, LOCALES_MODAL_NAME, openProfilesTableModal } from '../../../state/profiles-table-modal.atom';
import Modal from '../../../ui/Modal/index';
import { getFontsList } from '../../../utils/fonts-list';
import { validateLaunchArguments } from '../../../utils/validate-launch-arguments';
import ProfileLanguagesInputBlock from '../../common/components/profile-langs-input-block';
import { TUpdateWebglMetadataFields } from '../../common/interfaces/update-webgl-metadata.interface';
import ResolutionManager from '../../common/resolution-manager';
import { BookmarksTextArea } from '../../common/tabs/bookmarks/styles';
import FontsManager from '../../modalsComponents/components/fonts-manager';
import LocalesManager from '../../modalsComponents/components/locales-manager';

const { Option } = Select;

const FontEditBtn = styled(Button)`
  font-size: 12px;
  max-height: 30px;
`;

const Container = styled('div')`
  color: var(--000000-update-profile-advanced);
`;

const Cent = styled('div')`
  align-atems: center;
`;

const Component = styled('div')`
  margin-top: 23px;
  display: flex;
  flex-direction: column;
  line-height: 20px;
`;

const Row = styled('div')`
  display: flex;
  flex-direction: row;
`;

const Title = styled('div')`
  font-size: 16px;
  line-height: 19px;
  padding: 10px 0;
  border-bottom: 0.6px solid var(--E7E7E7-update-profile-advanced);
`;

const SubTitle = styled('span')`
  display: inline-block;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 10px;
  width: auto;
`;

const EditLanguagesSpan = styled(SubTitle)`
  cursor: pointer;
  margin-left: 8px;
  color: var(--006CBA-update-profile-advanced);
`;

const SwitchTitle = styled(SubTitle)`
  margin-left: 10px;
  margin-top: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`;

const Link = styled(SwitchTitle)`
  color: var(--006CBA-update-profile-advanced);
  cursor: pointer;
  margin-left: 0;
`;

const InputText = styled(Input)`
  width: 350px;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

const SelectAdvanced = styled(Select)`
width: 350px;
@media screen and (max-width: 450px) {
  width: 100%;
}
`;

const ShortInput = styled(InputNumber)`
  width: 57px;
`;

const FontParagr = styled('p')`
  margin: 0;
`;

const RadioButton = styled(Radio.Button)`
  height: 30px;
  text-align: center;
  font-size: 12px;
  line-height: 29px;
  align-items: center;
  justify-content: 'center;
`;

const CustomDNSInput = styled(InputText)`
  margin-bottom: 80px;
`;

declare interface IAdvancedTab extends TransProps {
  updateProfileInfo: (value: any) => any;
  profileInfo: any;
  setUpdatedField: (field: string) => any;
  updateVendorsList: () => void;
  updateRenderersList: (vendor: string, updateInFp?: boolean) => void;
  vendors: string[];
  renderers: string[];
  t: (key: string, args?: Record<string, string>) => string;
}

class AdvancedTab extends React.Component<IAdvancedTab> {
  state = {
    webGlModalVisible: false,
    fontsModalVisible: false,
    showAllFonts: false,
    fonts: [],
  };

  componentDidMount(): void {
    const { fonts = {}, os = 'win' } = this.props.profileInfo;
    const families = fonts.families || [];
    getFontsList(os, families).then(resultFonts => {
      this.setState({ fonts: resultFonts });
    });

    this.props.updateVendorsList();
    this.props.updateRenderersList(this.props.profileInfo.webGLMetadata.vendor);
  }

  handleChangeLaunchArguments: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const launchArgumentsText = event.target.value;

    this.props.updateProfileInfo({ launchArguments: launchArgumentsText });
    this.props.setUpdatedField('launchArguments');
  }

  checkLaunchArgs: React.FocusEventHandler<HTMLTextAreaElement> = (event) => {
    const launchArgumentsText = event.target.value;
    const checkArguments = validateLaunchArguments(launchArgumentsText);

    if (checkArguments.invalid.length) {
      message.error(
        <Trans
          i18nKey={'profileSettings.launchArguments.skippedForbiddenArguments'}
          values={{ list: checkArguments.invalid.join(', ') }}
        />,
      );
    }

    this.props.updateProfileInfo({ launchArguments: checkArguments.valid.join('\n') });
    this.props.setUpdatedField('launchArguments');
  }

  updateNavigator = (field: string, value: any) => {
    this.props.updateProfileInfo({
      navigator: {
        ...this.props.profileInfo.navigator,
        [field]: value,
      },
    });

    this.props.setUpdatedField(`navigator.${field}`);
  };

  private updateFonts(field: string, value: any) {
    this.props.updateProfileInfo({
      fonts: {
        ...this.props.profileInfo.fonts,
        [field]: value,
      },
    });

    this.props.setUpdatedField(`fonts.${field}`);
  }

  private updateMedia(field: string, value: any) {
    this.props.updateProfileInfo({
      mediaDevices: {
        ...this.props.profileInfo.mediaDevices,
        [field]: value,
      },
    });

    this.props.setUpdatedField(`mediaDevices.${field}`);
  }

  private updateCanvas(field: string, value: any) {
    this.props.updateProfileInfo({
      canvas: {
        ...this.props.profileInfo.canvas,
        [field]: value,
      },
    });

    this.props.setUpdatedField(`canvas.${field}`);
  }

  private updateAudioCtx(field: string, value: any) {
    this.props.updateProfileInfo({
      audioContext: {
        ...this.props.profileInfo.audioContext,
        [field]: value,
      },
    });

    this.props.setUpdatedField(`audioContext.${field}`);
  }

  private updateWebGl(field: string, value: any) {
    this.props.updateProfileInfo({
      webGL: {
        ...this.props.profileInfo.webGL,
        [field]: value,
      },
    });

    this.props.setUpdatedField(`webgl.${field}`);
  }

  private updateClientRects(field: string, value: any) {
    this.props.updateProfileInfo({
      clientRects: {
        ...this.props.profileInfo.clientRects,
        [field]: value,
      },
    });

    this.props.setUpdatedField(`clientRects.${field}`);
  }

  private updateWebGlMetadata(field: TUpdateWebglMetadataFields, value: string) {
    if (field === 'vendor') {
      this.props.updateRenderersList(value, true);
    }

    this.props.updateProfileInfo({
      webGLMetadata: {
        ...this.props.profileInfo.webGLMetadata,
        [field]: value,
      },
    });

    this.props.setUpdatedField(`webglMetadata.${field}`);
  }

  private updateStorage(field: string, value: any) {
    this.props.updateProfileInfo({
      storage: {
        ...this.props.profileInfo.storage,
        [field]: value,
      },
    });

    this.props.setUpdatedField(`storage.${field}`);
  }

  private updatePlugins(field: string, value: any) {
    this.props.updateProfileInfo({
      plugins: {
        ...this.props.profileInfo.plugins,
        [field]: value,
      },
    });

    this.props.setUpdatedField(`plugins.${field}`);
  }

  private updateResolution = (value: string): void => {
    this.props.updateProfileInfo({
      navigator: {
        ...this.props.profileInfo.navigator,
        resolution: value,
      },
    });

    this.props.setUpdatedField('navigator.resolution');
  };

  threadList = HARDWARE_CONCURRENCY.map(hardware => ({
    title: hardware.toString(),
    value: hardware.toString(),
  }));

  deviceMemoryList = DEVICE_MEMORY.map(memory => ({
    title: memory.toString(),
    value: memory.toString(),
  }));

  public getCanvasContent = () => {
    const { canvas = {} } = this.props.profileInfo;
    const { mode } = canvas;
    if (mode === 'off') {
      return (
        <>
          <Tooltip
            title={(
              <TooltipText>
                {this.props.t('canvas.off.help')}
              </TooltipText>
            )}
            overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
            placement='rightBottom'
            arrowPointAtCenter={true}
          >
            <Icon
              style={{
                marginTop: 7,
                marginLeft: 5,
                marginBottom: 7,
                opacity: 0.4,
              }}
              type='info-circle'
            />
          </Tooltip>
        </>
      );
    }

    if (mode === 'block') {
      return (
        <>
          <Tooltip
            title={(
              <TooltipText>
                {this.props.t('canvas.block.help')}
              </TooltipText>
            )}
            overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
            placement='rightBottom'
            arrowPointAtCenter={true}
          >
            <Icon
              style={{
                marginTop: 7,
                marginLeft: 5,
                marginBottom: 7,
                opacity: 0.4,
              }}
              type='info-circle'
            />
          </Tooltip>
        </>
      );
    }

    return (
      <>
        <Tooltip
          title={(
            <TooltipText>
              {this.props.t('canvas.noise.help')}
            </TooltipText>
          )}
          overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
          placement='rightBottom'
          arrowPointAtCenter={true}
        >
          <Icon
            style={{
              marginTop: 7,
              marginLeft: 5,
              marginBottom: 7,
              opacity: 0.4,
            }}
            type='info-circle'
          />
        </Tooltip>
      </>
    );
  };

  public getAudionContextContent = () => {
    const { audioContext = {} } = this.props.profileInfo;
    const { mode } = audioContext;
    if (mode === 'off') {
      return (
        <>
          <Tooltip
            title={(
              <TooltipText>
                {this.props.t('audioContext.off.help')}
              </TooltipText>
            )}
            overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
            placement='rightBottom'
            arrowPointAtCenter={true}
          >
            <Icon
              style={{
                marginTop: 7,
                marginLeft: 5,
                marginBottom: 7,
                opacity: 0.4,
              }}
              type='info-circle'
            />
          </Tooltip>
        </>
      );
    }

    return (
      <>
        <Tooltip
          title={(
            <TooltipText>
              {this.props.t('audioContext.noise.help')}
            </TooltipText>
          )}
          overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
          placement='rightBottom'
          arrowPointAtCenter={true}
        >
          <Icon
            style={{
              marginTop: 7,
              marginLeft: 5,
              marginBottom: 7,
              opacity: 0.4,
            }}
            type='info-circle'
          />
        </Tooltip>
      </>
    );
  };

  public getWebGlImageContent = () => {
    const { webGL = {} } = this.props.profileInfo;
    const { mode } = webGL;
    if (mode === 'off') {
      return (
        <>
          <Tooltip
            title={(
              <TooltipText>
                {this.props.t('webgl.image.off.help')}
              </TooltipText>
            )}
            overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
            placement='rightBottom'
            arrowPointAtCenter={true}
          >
            <Icon
              style={{
                marginTop: 7,
                marginLeft: 5,
                marginBottom: 7,
                opacity: 0.4,
              }}
              type='info-circle'
            />
          </Tooltip>
        </>
      );
    }

    return (
      <>
        <Tooltip
          title={(
            <TooltipText>
              {this.props.t('webgl.image.noise.help')}
            </TooltipText>
          )}
          overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
          placement='rightBottom'
          arrowPointAtCenter={true}
        >
          <Icon
            style={{
              marginTop: 7,
              marginLeft: 5,
              marginBottom: 7,
              opacity: 0.4,
            }}
            type='info-circle'
          />
        </Tooltip>
      </>
    );
  };

  public getWebGlMetadata = () => {
    const { webGLMetadata = {} } = this.props.profileInfo;
    const { mode } = webGLMetadata;
    if (mode === 'off') {
      return (
        <Row>
          <Tooltip
            title={(
              <TooltipText>
                {this.props.t('webgl.metadata.off.help')}
              </TooltipText>
            )}
            overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
            placement='rightBottom'
            arrowPointAtCenter={true}
          >
            <Icon
              style={{
                marginTop: 7,
                marginLeft: 5,
                marginBottom: 7,
                opacity: 0.4,
              }}
              type='info-circle'
            />
          </Tooltip>
        </Row>
      );
    }

    return (
      <>
        <Tooltip
          title={(
            <TooltipText>
              {this.props.t('webgl.metadata.mask.help')}
            </TooltipText>
          )}
          overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
          placement='rightBottom'
          arrowPointAtCenter={true}
        >
          <Icon
            style={{
              marginTop: 7,
              marginLeft: 5,
              marginBottom: 7,
              opacity: 0.4,
            }}
            type='info-circle'
          />
        </Tooltip>
      </>
    );
  };

  public toggleWebGlModal = (boolean: boolean) => {
    this.setState({
      webGlModalVisible: boolean,
    });
  };

  public toggleFontsModal = (boolean: boolean) => {
    this.setState({
      fontsModalVisible: boolean,
    });
  };

  public toggleLocalesListModal = (save: boolean, autoLang = true, newLanguage?: string): void => {
    if (save) {
      this.updateNavigator('language', newLanguage);
      this.props.setUpdatedField('language');
      this.props.updateProfileInfo({ autoLang });
      this.props.setUpdatedField('autoLang');
    }

    closeProfilesTableModal();
  };

  public setFontsState = (fonts: any[]) => this.setState({ fonts });

  public webGParamsTable = () => {
    const webGLParams = this.props.profileInfo.webglParams || {};
    const glParamValues = webGLParams.glParamValues || [];
    const filteredParams = glParamValues.filter((obj:any) => obj.value !== 'n/a');
    const dataSource = filteredParams.map((obj:any) => {
      const { name } = obj;
      const { value } = obj;
      let resultValue = [value];
      if (value === Object(value)) {
        resultValue = [];
        for (const index in value) {
          resultValue.push(value[index]);
        }
      }

      return {
        key: name,
        extension: 'webgl',
        name,
        value: resultValue,
      };
    });

    return (
      <Table
        dataSource={dataSource}
        pagination={false}
        size='small'
      >
        <Table.Column title={this.props.t('base.extension')} key='extension' dataIndex='extension' />
        <Table.Column title={this.props.t('base.name')} key='name' dataIndex='name' ellipsis={true} render={(name: string) => (<Tooltip title={name}>
          {name}
        </Tooltip>)} />
        <Table.Column title={this.props.t('base.code')} key='code' dataIndex='code'  />
        <Table.Column
          title={this.props.t('base.value')}
          key='value'
          dataIndex='value'
          render={(arr: any[]) => (
            <>
              <p>
                {arr.join(', ')}
              </p>
            </>
          )}
        />
      </Table>
    );
  };

  public cancelFontsModal() {
    this.toggleFontsModal(false);
  }

  public saveFontsModal() {
    const checkedFontsObjs = this.state.fonts.filter(
      (fontElem: any) => fontElem.checked,
    );

    const checkedFonts = checkedFontsObjs.map(
      (fontElem: any) => fontElem.value,
    );

    this.updateFonts('families', checkedFonts);
    this.toggleFontsModal(false);
  }

  private getFontsSetting = () => {
    const os = this.props.profileInfo.os || 'win';

    let androidNotificationComponent = null;
    if (os === 'android') {
      androidNotificationComponent = (
        <SubTitle
          style={{ marginTop: '8px' }}
        >
          {this.props.t('fonts.androidNotifyText')}
        </SubTitle>
      );
    }

    return (
      <>
        <Component>
          <Row>
            <Switch
              disabled={os === 'android'}
              checked={this.props.profileInfo.fonts.enableMasking}
              onChange={e => this.updateFonts('enableMasking', e)}
            />
            <SwitchTitle>
              {this.props.t('fonts.switchLabel')}
            </SwitchTitle>
          </Row>
        </Component>
        {androidNotificationComponent}
      </>
    );
  };

  private getFontsContent = () => {
    const { fonts = {}, os = 'win' } = this.props.profileInfo;
    const { enableMasking } = fonts;
    if (os === 'android' || !enableMasking) {
      return '';
    }

    return (
      <>
        <Component>
          <Row>
            <SubTitle style={{ marginTop: '5px' }}>
              {this.props.t('fonts.subtitle')}
              :
            </SubTitle>
          </Row>
        </Component>
        {this.renderUserFonts()}
        <Component>
          <Row>
            <FontEditBtn
              type='primary'
              onClick={() => this.toggleFontsModal(true)}
            >
              {this.props.t('base.edit')}
            </FontEditBtn>
          </Row>
        </Component>
      </>
    );
  };

  public renderUserFonts = () => {
    const { showAllFonts } = this.state;
    const usersFontsCountToRender = 8;
    const families = (this.state.fonts || []).filter((fontElem: any) => fontElem.checked);
    const checkedFontsNames = families.map((elem: any, index: number) => (
      <FontParagr key={index}>
        {elem.name}
      </FontParagr>
    ));

    const arrayToRender = checkedFontsNames.slice(0, usersFontsCountToRender);

    const fontLength = checkedFontsNames.length;
    if (showAllFonts) {
      checkedFontsNames.push(
        <Link onClick={() => this.setState({ showAllFonts: false })}>
          {this.props.t('base.edit')}
        </Link>,
      );
    } else if (fontLength > usersFontsCountToRender) {
      const diff = fontLength - usersFontsCountToRender;

      // TODO: Connect localization
      arrayToRender.push(
        <Link onClick={() => this.setState({ showAllFonts: true })}>
          ...and
          {' '}
          {diff}
          {' '}
          more
        </Link>,
      );
    }

    if (!arrayToRender.length) {
      return '';
    } else if (showAllFonts) {
      return (<Component>
        {checkedFontsNames}
      </Component>);
    }

    return (<Component>
      {arrayToRender}
    </Component>);
  };

  public getMaskMediaDevices = () => {
    const { profileInfo } = this.props;
    const { mediaDevices = {} } = profileInfo;
    const { enableMasking } = mediaDevices;
    if (!enableMasking) {
      return '';
    }

    return (
      <>
        <Component>
          <Row>
            <Cent>
              <SubTitle style={{ width: 100 }}>
                {this.props.t('mediaDevices.videoInTitle')}
              </SubTitle>
              <ShortInput
                style={{ marginLeft: 25 }}
                maxLength={1}
                max={9}
                min={0}
                value={profileInfo.mediaDevices.videoInputs}
                onChange={e => this.updateMedia('videoInputs', e || 0)}
              />
            </Cent>
          </Row>
        </Component>
        <Component>
          <Row>
            <Cent>
              <SubTitle style={{ width: 100 }}>
                {this.props.t('mediaDevices.audioInTitle')}
              </SubTitle>
              <ShortInput
                style={{ marginLeft: 25 }}
                maxLength={1}
                max={9}
                min={0}
                value={profileInfo.mediaDevices.audioInputs}
                onChange={e => this.updateMedia('audioInputs', e || 0)}
              />
            </Cent>
          </Row>
        </Component>
        <Component>
          <Row>
            <Cent>
              <SubTitle style={{ width: 100 }}>
                {this.props.t('mediaDevices.audioOutTitle')}
              </SubTitle>
              <ShortInput
                style={{ marginLeft: 25 }}
                maxLength={1}
                max={9}
                min={0}
                value={profileInfo.mediaDevices.audioOutputs}
                onChange={e => this.updateMedia('audioOutputs', e || 0)}
              />
            </Cent>
          </Row>
        </Component>
      </>
    );
  };

  private getAndroidTouchPoints = () => {
    if (this.props.profileInfo.os !== 'android') {
      return null;
    }

    const { profileInfo } = this.props;

    return (
      <Component>
        <SubTitle>
          {this.props.t('base.maxTouchPoints')}
        </SubTitle>
        <InputText
          maxLength={1}
          max={9}
          min={1}
          type='number'
          value={profileInfo.navigator.maxTouchPoints}
          onChange={e => this.updateNavigator('maxTouchPoints', e.target.value)}
        />
      </Component>
    );
  };

  public render() {
    const { profileInfo } = this.props;

    return (
      <Container>
        {/* *** Navigator *** */}
        <Component>
          <Title>
            {this.props.t('base.navigator')}
          </Title>
        </Component>
        <Component>
          <SubTitle>
            {this.props.t('base.useragent')}
          </SubTitle>
          <Row>
            <InputText
              value={profileInfo.navigator.userAgent}
              placeholder={this.props.t('base.useragent')}
              onChange={e => this.updateNavigator('userAgent', e.target.value)}
            />
            <CopyToClipBoard text={profileInfo.navigator.userAgent} onCopy={() => message.success(this.props.t('base.copiedText'))}>
              <Icon type='copy' style={{ fontSize: 17, opacity: 0.4, marginTop: 6, marginLeft: 5 }} />
            </CopyToClipBoard>
          </Row>
        </Component>
        <Component>
          <SubTitle>
            {this.props.t('base.screenResolution')}
          </SubTitle>
          <ResolutionManager
            updateResolution={this.updateResolution}
            isDisabled={this.props.profileInfo.os === 'android'}
            oldStyle={true}
            resolution={this.props.profileInfo.navigator.resolution}
          />
        </Component>
        <Component>
          <div>
            <SubTitle>
              {this.props.t('base.languages')}
            </SubTitle>
            {!this.props.profileInfo.autoLang ? (
              <EditLanguagesSpan
                onClick={(): void => openProfilesTableModal(LOCALES_MODAL_NAME, [])}
              >
                {this.props.t('base.edit')}
              </EditLanguagesSpan>
            ) : null}
          </div>
          <ProfileLanguagesInputBlock
            mode='editProfile'
            autoLang={this.props.profileInfo.autoLang}
            language={this.props.profileInfo.navigator.language}
            updateProfileInfo={this.props.updateProfileInfo}
            updateNavigator={this.updateNavigator}
            setUpdatedField={this.props.setUpdatedField}
          />
        </Component>
        <Component>
          <SubTitle>
            {this.props.t('base.platform')}
          </SubTitle>
          <InputText
            disabled={true}
            value={profileInfo.navigator.platform}
            placeholder={this.props.t('base.platform')}
            onChange={e => this.updateNavigator('platform', e.target.value)}
          />
        </Component>
        <Component>
          <SubTitle>
            {this.props.t('editProfile.advanced.cpu.title')}
          </SubTitle>
          <SelectAdvanced
            value={profileInfo.navigator.hardwareConcurrency}
            onChange={(e: any) =>
              this.updateNavigator('hardwareConcurrency', e)}
          >
            {this.threadList.map(
              elem => (
                <Option key={elem.value} value={elem.value}>
                  {elem.title}
                </Option>
              ),
            )}
          </SelectAdvanced>
        </Component>
        <Component>
          <SubTitle>
            {this.props.t('editProfile.advanced.ram.title')}
          </SubTitle>
          <SelectAdvanced
            value={profileInfo.navigator.deviceMemory}
            onChange={(e: any) =>
              this.updateNavigator('deviceMemory', e)}
          >
            {this.deviceMemoryList.map(
              elem => (
                <Option key={elem.value} value={elem.value}>
                  {elem.title}
                </Option>
              ),
            )}
          </SelectAdvanced>
        </Component>
        {this.getAndroidTouchPoints()}
        <Component>
          <Row>
            <Switch
              checked={profileInfo.navigator.doNotTrack}
              onChange={e => this.updateNavigator('doNotTrack', e)}
            />
            <SwitchTitle>
              {this.props.t('editProfile.advanced.doNotTrack.switchLabel')}
            </SwitchTitle>
          </Row>
        </Component>

        {/* *** Fonts *** */}
        <Component>
          <Title>
            {this.props.t('fonts.title')}
          </Title>
        </Component>
        {this.getFontsSetting()}
        {this.getFontsContent()}

        {/* *** Media Devices *** */}
        <Component>
          <Title>
            {this.props.t('mediaDevices.switchLabel')}
          </Title>
        </Component>
        <Component>
          <Row>
            <Switch
              checked={profileInfo.mediaDevices.enableMasking}
              onChange={e => this.updateMedia('enableMasking', e)}
            />
            <SwitchTitle>
              {this.props.t('mediaDevices.switchLabel')}
            </SwitchTitle>
          </Row>
        </Component>
        {this.getMaskMediaDevices()}

        {/* *** Hardware *** */}
        <Component>
          <Title>
            {this.props.t('base.hardware')}
          </Title>
        </Component>
        <Component>
          <SubTitle>
            {this.props.t('canvas.title')}
          </SubTitle>
          <Row>
            <Radio.Group
              value={profileInfo.canvas.mode}
              onChange={e => this.updateCanvas('mode', e.target.value)}
            >
              <Tooltip
                title={(
                  <TooltipText>
                    {this.props.t('canvas.noise.help')}
                  </TooltipText>
                )}
                overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
                placement='topRight'
                arrowPointAtCenter={true}
              >
                <RadioButton value='noise'>
                  {this.props.t('base.noise')}
                </RadioButton>
              </Tooltip>
              <Tooltip
                title={(
                  <TooltipText>
                    {this.props.t('canvas.off.help')}
                  </TooltipText>
                )}
                overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
                placement='top'
                arrowPointAtCenter={true}
              >
                <RadioButton value='off'>
                  {this.props.t('base.off')}
                </RadioButton>
              </Tooltip>
              <Tooltip
                title={(
                  <TooltipText>
                    {this.props.t('canvas.block.help')}
                  </TooltipText>
                )}
                overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
                placement='top'
                arrowPointAtCenter={true}
              >
                <RadioButton
                  style={{
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                  }}
                  value='block'
                >
                  {this.props.t('base.block')}
                </RadioButton>
              </Tooltip>
            </Radio.Group>
            {this.getCanvasContent()}
          </Row>
        </Component>
        <Component>
          <SubTitle>
            {this.props.t('clientRects.title')}
          </SubTitle>
          <Row>
            <Radio.Group
              value={profileInfo.clientRects.mode}
              onChange={e => this.updateClientRects('mode', e.target.value)}
            >
              <RadioButton value='noise'>
                {this.props.t('base.noise')}
              </RadioButton>
              <RadioButton value='off'>
                {this.props.t('base.off')}
              </RadioButton>
            </Radio.Group>
            <Tooltip
              title={(
                <TooltipText>
                  {this.props.t('clientRects.help')}
                </TooltipText>
              )}
              overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
              placement='rightBottom'
              arrowPointAtCenter={true}
            >
              <Icon
                style={{
                  marginTop: 6,
                  marginLeft: 5,
                  marginBottom: 6,
                  opacity: 0.4,
                }}
                type='info-circle'
              />
            </Tooltip>
          </Row>
        </Component>
        <Component>
          <SubTitle>
            {this.props.t('audioContext.title')}
          </SubTitle>
          <Row>
            <Radio.Group
              value={profileInfo.audioContext.mode}
              onChange={e => this.updateAudioCtx('mode', e.target.value)}
            >
              <Tooltip
                title={(
                  <TooltipText>
                    {this.props.t('audioContext.noise.help')}
                  </TooltipText>
                )}
                overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
                placement='topRight'
                arrowPointAtCenter={true}
              >
                <RadioButton value='noise'>
                  {this.props.t('base.noise')}
                </RadioButton>
              </Tooltip>
              <Tooltip
                title={(
                  <TooltipText>
                    {this.props.t('audioContext.off.help')}
                  </TooltipText>
                )}
                overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
                placement='top'
                arrowPointAtCenter={true}
              >
                <RadioButton value='off'>
                  {this.props.t('base.off')}
                </RadioButton>
              </Tooltip>
            </Radio.Group>
            {this.getAudionContextContent()}
          </Row>
        </Component>
        <Component>
          <SubTitle>
            {this.props.t('webgl.image.title')}
          </SubTitle>
          <Row>
            <Radio.Group
              value={profileInfo.webGL.mode}
              onChange={e => this.updateWebGl('mode', e.target.value)}
            >
              <Tooltip
                title={(
                  <TooltipText>
                    {this.props.t('webgl.image.noise.help')}
                  </TooltipText>
                )}
                overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
                placement='topRight'
                arrowPointAtCenter={true}
              >
                <RadioButton value='noise'>
                  {this.props.t('base.noise')}
                </RadioButton>
              </Tooltip>
              <Tooltip
                title={(
                  <TooltipText>
                    {this.props.t('webgl.image.off.help')}
                  </TooltipText>
                )}
                overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
                placement='top'
                arrowPointAtCenter={true}
              >
                <RadioButton value='off'>
                  {this.props.t('base.off')}
                </RadioButton>
              </Tooltip>
            </Radio.Group>
            {this.getWebGlImageContent()}
          </Row>
        </Component>
        <Component>
          <SubTitle>
            {this.props.t('webgl.metadata.title')}
          </SubTitle>
          <Row>
            <Radio.Group
              value={profileInfo.webGLMetadata.mode}
              onChange={e => this.updateWebGlMetadata('mode', e.target.value)}
            >
              <Tooltip
                title={(
                  <TooltipText>
                    {this.props.t('webgl.metadata.mask.help')}
                  </TooltipText>
                )}
                overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
                placement='topRight'
                arrowPointAtCenter={true}
              >
                <RadioButton value='mask'>
                  {this.props.t('base.mask')}
                </RadioButton>
              </Tooltip>
              <Tooltip
                title={(
                  <TooltipText>
                    {this.props.t('webgl.metadata.off.help')}
                  </TooltipText>
                )}
                overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
                placement='top'
                arrowPointAtCenter={true}
              >
                <RadioButton value='off'>
                  {this.props.t('base.real')}
                </RadioButton>
              </Tooltip>
            </Radio.Group>
            {this.getWebGlMetadata()}
          </Row>
          {this.props.profileInfo.webGLMetadata.mode !== 'off' && (
            <>
              <Component>
                <SubTitle>
                  {this.props.t('webgl.vendor.title')}
                </SubTitle>
                <Select
                  placeholder={this.props.t('webgl.vendor.manualInputPlaceholder')}
                  value={this.props.profileInfo.webGLMetadata.vendor}
                  onSelect={(value: string): void => this.updateWebGlMetadata('vendor', value)}
                  style={{ width: 350 }}
                >
                  {this.props.vendors.map((vendor: string) => (
                    <Select.Option value={vendor} key={vendor}>
                      {vendor}
                    </Select.Option>
                  ),
                  )}
                </Select>
              </Component>
              <Component>
                <SubTitle>
                  {this.props.t('webgl.renderer.title')}
                </SubTitle>
                <Select
                  value={this.props.profileInfo.webGLMetadata.renderer}
                  onSelect={(value: string) => this.updateWebGlMetadata('renderer', value)}
                  style={{ width: 350 }}
                >
                  {this.props.renderers.map((renderer: string) => (
                    <Select.Option value={renderer} key={renderer}>
                      {renderer}
                    </Select.Option>
                  ),
                  )}
                </Select>
              </Component>
            </>
          )}
        </Component>

        {/* *** Storage *** */}
        <Component>
          <Title>
            {this.props.t('storage.title')}
          </Title>
        </Component>
        <Component>
          <Row>
            <Switch
              checked={profileInfo.storage.local}
              onChange={e => this.updateStorage('local', e)}
            />
            <SwitchTitle>
              {this.props.t('storage.localStorage.switchLabel')}
            </SwitchTitle>
            <Tooltip
              title={(
                <TooltipText>
                  {this.props.t('storage.localStorage.help')}
                </TooltipText>
              )}
              overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
              placement='rightBottom'
              arrowPointAtCenter={true}
            >
              <Icon
                style={{
                  marginTop: 6,
                  marginLeft: 5,
                  marginBottom: 6,
                  opacity: 0.4,
                }}
                type='info-circle'
              />
            </Tooltip>
          </Row>
        </Component>
        <Component>
          <Row>
            <Switch
              checked={profileInfo.storage.indexedDb}
              onChange={e => this.updateStorage('indexedDb', e)}
            />
            <SwitchTitle>
              {this.props.t('storage.indexedDb.switchLabel')}
            </SwitchTitle>

            <Tooltip
              title={(
                <TooltipText>
                  {this.props.t('storage.indexedDb.help')}
                </TooltipText>
              )}
              overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
              placement='rightBottom'
              arrowPointAtCenter={true}
            >
              <Icon
                style={{
                  marginTop: 6,
                  marginLeft: 5,
                  marginBottom: 6,
                  opacity: 0.4,
                }}
                type='info-circle'
              />
            </Tooltip>
          </Row>
        </Component>
        <Component>
          <Row>
            <Switch
              checked={profileInfo.storage.extensions}
              onChange={e => this.updateStorage('extensions', e)}
            />
            <SwitchTitle>
              {this.props.t('storage.extension.switchLabel')}
            </SwitchTitle>
            <Tooltip
              title={(
                <TooltipText>
                  {this.props.t('storage.extension.help')}
                </TooltipText>
              )}
              overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
              placement='rightBottom'
              arrowPointAtCenter={true}
            >
              <Icon
                style={{
                  marginTop: 6,
                  marginLeft: 5,
                  marginBottom: 6,
                  opacity: 0.4,
                }}
                type='info-circle'
              />
            </Tooltip>
          </Row>
        </Component>

        {/* *** Browser Plugins *** */}
        <Component>
          <Title>
            {this.props.t('plugins.title')}
          </Title>
        </Component>
        <Component>
          <Row>
            <Switch
              checked={profileInfo.plugins.enableVulnerable}
              onChange={e => this.updatePlugins('enableVulnerable', e)}
            />
            <SwitchTitle>
              {this.props.t('plugins.vulnerablePlugins.switchLabel')}
            </SwitchTitle>
            <Tooltip
              title={(
                <TooltipText>
                  {this.props.t('plugins.vulnerablePlugins.help')}
                </TooltipText>
              )}
              overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
              placement='rightBottom'
              arrowPointAtCenter={true}
            >
              <Icon
                style={{
                  marginTop: 6,
                  marginLeft: 5,
                  marginBottom: 6,
                  opacity: 0.4,
                }}
                type='info-circle'
              />
            </Tooltip>
          </Row>
        </Component>
        <Component>
          <Title>
            {this.props.t('base.other')}
          </Title>
        </Component>
        <Component>
          <Row>
            <Switch
              checked={profileInfo.lockEnabled}
              onChange={e => {
                this.props.updateProfileInfo({ lockEnabled: e });
                this.props.setUpdatedField('lockEnabled');
              }}
            />
            <SwitchTitle>
              {this.props.t('editProfile.advanced.activeSession.switchLabel')}
            </SwitchTitle>
            <Tooltip
              title={(
                <TooltipText>
                  {this.props.t('editProfile.advanced.activeSession.help')}
                </TooltipText>
              )}
              overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
              placement='rightBottom'
              arrowPointAtCenter={true}
            >
              <Icon
                style={{
                  marginTop: 6,
                  marginLeft: 5,
                  marginBottom: 6,
                  opacity: 0.4,
                }}
                type='info-circle'
              />
            </Tooltip>
          </Row>
        </Component>
        <Component>
          <Row>
            <Switch
              checked={profileInfo.googleServicesEnabled}
              onChange={e => {
                this.props.updateProfileInfo({ googleServicesEnabled: e });
                this.props.setUpdatedField('googleServicesEnabled');
              }}
            />
            <SwitchTitle>
              {this.props.t('editProfile.advanced.googleServices.switchLabel')}
            </SwitchTitle>
            <Tooltip
              title={(
                <TooltipText>
                  {this.props.t('newProfile.advanced.googleServices.help')}
                </TooltipText>
              )}
              overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
              placement='rightBottom'
              arrowPointAtCenter={true}
            >
              <Icon
                style={{
                  marginTop: 6,
                  marginLeft: 5,
                  marginBottom: 6,
                  opacity: 0.4,
                }}
                type='info-circle'
              />
            </Tooltip>
          </Row>
        </Component>
        <Component>
          <Row>
            <Switch
              checked={profileInfo.storage.bookmarks}
              onChange={e => this.updateStorage('bookmarks', e)}
            />
            <SwitchTitle>
              {this.props.t('editProfile.advanced.bookmarks.switchLabel')}
            </SwitchTitle>
            <Tooltip
              title={(
                <TooltipText>
                  {this.props.t('editProfile.advanced.bookmarks.help')}
                </TooltipText>
              )}
              overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
              placement='rightBottom'
              arrowPointAtCenter={true}
            >
              <Icon
                style={{
                  marginTop: 7,
                  marginLeft: 5,
                  marginBottom: 11,
                  opacity: 0.4,
                }}
                type='info-circle'
              />
            </Tooltip>
          </Row>
        </Component>
        <Component>
          <Row>
            <Switch
              checked={profileInfo.storage.history}
              onChange={e => this.updateStorage('history', e)}
            />
            <SwitchTitle>
              {this.props.t('editProfile.advanced.history.switchLabel')}
            </SwitchTitle>
            <Tooltip
              title={(
                <TooltipText>
                  {this.props.t('editProfile.advanced.history.help')}
                </TooltipText>
              )}
              overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
              placement='rightBottom'
              arrowPointAtCenter={true}
            >
              <Icon
                style={{
                  marginTop: 6,
                  marginLeft: 5,
                  marginBottom: 6,
                  opacity: 0.4,
                }}
                type='info-circle'
              />
            </Tooltip>
          </Row>
        </Component>
        <Component>
          <Row>
            <Switch
              checked={profileInfo.storage.passwords}
              onChange={e => this.updateStorage('passwords', e)}
            />
            <SwitchTitle>
              {this.props.t('editProfile.advanced.password.switchLabel')}
            </SwitchTitle>
            <Tooltip
              title={(
                <TooltipText>
                  {this.props.t('editProfile.advanced.password.help')}
                </TooltipText>
              )}
              overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
              placement='rightBottom'
              arrowPointAtCenter={true}
            >
              <Icon
                style={{
                  marginTop: 6,
                  marginLeft: 5,
                  marginBottom: 6,
                  opacity: 0.4,
                }}
                type='info-circle'
              />
            </Tooltip>
          </Row>
        </Component>
        <Component>
          <Row>
            <Switch
              checked={profileInfo.storage.session}
              onChange={e => this.updateStorage('session', e)}
            />
            <SwitchTitle>
              {this.props.t('editProfile.advanced.session.switchLabel')}
            </SwitchTitle>
            <Tooltip
              title={(
                <TooltipText>
                  {this.props.t('editProfile.advanced.session.help')}
                </TooltipText>
              )}
              overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
              placement='rightBottom'
              arrowPointAtCenter={true}
            >
              <Icon
                style={{
                  marginTop: 6,
                  marginLeft: 5,
                  marginBottom: 6,
                  opacity: 0.4,
                }}
                type='info-circle'
              />
            </Tooltip>
          </Row>
        </Component>
        <Component>
          <Row>
            <Switch
              checked={profileInfo.storage.enableExternalExtensions}
              onChange={(value): void => this.updateStorage('enableExternalExtensions', value)}
            />
            <SwitchTitle>
              {this.props.t('extensions.external.switchLabel')}
            </SwitchTitle>
            <Tooltip
              title={(
                <TooltipText>
                  {this.props.t('extensions.external.help')}
                </TooltipText>
              )}
              overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
              placement='rightBottom'
              arrowPointAtCenter={true}
            >
              <Icon
                style={{
                  marginTop: 6,
                  marginLeft: 5,
                  marginBottom: 6,
                  opacity: 0.4,
                }}
                type='info-circle'
              />
            </Tooltip>
          </Row>
        </Component>
        <Component>
          <InputText
            placeholder={this.props.t('editProfile.advanced.startUrl.inputPlaceholder')}
            value={profileInfo.startUrl}
            onChange={e => {
              this.props.updateProfileInfo({ startUrl: e.target.value });
              this.props.setUpdatedField('startUrl');
            }}
          />
        </Component>
        <Component>
          <BookmarksTextArea
            placeholder={this.props.t('advanced.launchArguments.inputPlaceholder')}
            value={profileInfo.launchArguments}
            onChange={this.handleChangeLaunchArguments}
            onBlur={this.checkLaunchArgs}
          />
        </Component>
        <Component>
          <Row>
            <CustomDNSInput
              placeholder={this.props.t('editProfile.advanced.dns.inputPlaceholder')}
              value={profileInfo.dns}
              onChange={e => {
                this.props.updateProfileInfo({ dns: e.target.value });
                this.props.setUpdatedField('dns');
              }}
            />
            <Tooltip
              title={(
                <TooltipText>
                  {this.props.t('editProfile.advanced.dns.help')}
                </TooltipText>
              )}
              overlayStyle={{ backgroundColor: 'var(--FFFFFF-update-profile-advanced)', color: 'var(--000000-update-profile-advanced)' }}
              placement='rightBottom'
              arrowPointAtCenter={true}
            >
              <Icon
                style={{
                  marginTop: 10,
                  marginLeft: 5,
                  marginBottom: 10,
                  opacity: 0.4,
                }}
                type='info-circle'
              />
            </Tooltip>
          </Row>
        </Component>

        <Modal
          visible={this.state.webGlModalVisible}
          onClose={() => this.toggleWebGlModal(false)}
          title='WebGL parameters'
          footer={null}
        >
          {this.webGParamsTable()}
        </Modal>
        <FontsManager
          fonts={this.state.fonts}
          fontsModalVisible={this.state.fontsModalVisible}
          updateFontsList={(fonts: any[]) => this.setFontsState(fonts)}
          onCancel={() => this.cancelFontsModal()}
          onOk={() => this.saveFontsModal()}
        />
        <LocalesManager
          languages={profileInfo.navigator.language}
          toggleLocalesListModal={this.toggleLocalesListModal}
          autoLang={this.props.profileInfo.autoLang}
        />
      </Container>
    );
  }
}

export default withTranslation()(AdvancedTab);
