import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DescriptionPlan,
  MenuRowContainer,
  MenuRowText,
  MenuHeaderItem,
  WorkspaceContainerDescription,
} from './styles';
import { WorkspaceUpgrade } from './workspace-upgrade';
import { FREE_PLAN_ID } from '../../../../common/constants/constants';
import { greenMain, greyInformers } from '../../style-templates/colors';
import { IconCheck } from '../icons';
import { IconBriefcase } from '../icons/icon-briefcase';

type WorkspaceInfoProps = {
  name: string;
  profiles: number;
  planMaxProfiles: number;
  planName: string;
  onClick: () => void;
  isSelected: boolean;
  isFromOldHeader?: boolean;
  expiresAt?: Date;
  planId?: string;
}

const WorkspaceInfo: React.FC<WorkspaceInfoProps> = (props): JSX.Element => {
  const { name, profiles, planMaxProfiles, planName, onClick, isSelected, isFromOldHeader, expiresAt, planId } = props;

  const { t: translation } = useTranslation();

  let profilesText = `${profiles} ${translation('base.of')} ${planMaxProfiles}, ${planName}`;
  if (!planMaxProfiles) {
    profilesText = `${profiles}, ${planName}`;
  }

  const renderExpiresAt = (): JSX.Element|null => {
    if (!expiresAt || FREE_PLAN_ID === planId) {
      return null;
    }

    return (
      <span>
        {translation('base.expires')}
        {' '}
        {format(new Date(expiresAt), 'd MMM yyyy')}
      </span>
    );
  };

  return (
    <MenuHeaderItem onClick={onClick} isActive={true} isFromOldHeader={isFromOldHeader}>
      <MenuRowContainer>
        <IconBriefcase padding={0} styleType='lightGray' iconColor={greyInformers} />
        <MenuRowText style={{ margin: '0 8px', cursor: 'pointer' }}>
          {name}
        </MenuRowText>
        {isSelected ? (
          <IconCheck
            padding={0}
            styleType='green'
            iconColor={greenMain}
            size={12}
          />
        ) : null}
      </MenuRowContainer>
      <WorkspaceContainerDescription>
        <DescriptionPlan>
          <span>
            {profilesText}
          </span>
          {renderExpiresAt()}
        </DescriptionPlan>
      </WorkspaceContainerDescription>
      <WorkspaceUpgrade isSelected={isSelected} />
    </MenuHeaderItem>
  );
};

export default WorkspaceInfo;
