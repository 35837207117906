import React from 'react';

import { GeoProxyType } from '../../../../../../common/constants/types';
import { stringifyRemainingTrafficAmount } from '../../../../../../common/proxy/traffic/utils';
import { determineIsProxyCheckFailed } from '../../../../../../common/proxy/utils';
import { NEW_FEATURES } from '../../../../../state/feature-toggle/new-features';
import { ProxyContextMenuProxyLocation } from '../../../../../state/proxy-select-menu.atom';
import { useIsProxyAvailable } from '../../../../../state/proxy/geoproxy-countries.atom';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../../../state/proxy/proxy-check/constants';
import { useIsProxyChecking, useIsProxyRestoring } from '../../../../../state/proxy/proxy-check/proxy-statuses.atom';
import { IUngroupedProxy } from '../../../../../state/proxy/proxy-groups/types';
import { openProxyManager } from '../../../../../state/proxy/proxy-manager-modal-status.atom';
import { useTrafficData } from '../../../../../state/proxy/traffic-data.atom';
import { openQuickPricing } from '../../../../../state/quick-pricing.atom';
import CheckProxyBtn from '../../../check-proxy-button';
import ProxyConnectionButton from '../../../proxy-connection-button';
import ProxyConnectedStatus from '../../../proxy-connection-button/proxy-connected-status';
import ProxyUnlinkButton from '../../../proxy-connection-button/proxy-unlink-button';
import ProxyEditButton from '../../../proxy-edit-button';
import ProxyFlag, { BIG_FLAG_HEIGHT, SMALL_FLAG_HEIGHT } from '../../../proxy-flag';
import { getIsProxyArchived, getIsProxyEditable, makeProxyTitle, restoreProxy } from '../../../proxy-helpers';
import { ProxyStatusDot } from '../../../styles';
import ProxyItemCheckbox from '../proxy-item-checkbox';
import ProxyProfilesCounter from '../proxy-profiles-counter';
import PurchaseTrafficButton from '../purchase-traffic-button';
import {
  NoGroupsProxyRightControlsWrapper,
  ProxyInteractiveProfilesCounterWrapper,
  UngroupedProxyStatusWrapper,
} from '../styles';
import { ProxyConnectedStatusWrapper, ProxyTitleWrapper, UnroupedProxyItemWrapper } from './styles';

const preventDefaultAndPropagation: React.MouseEventHandler<Element> = event => {
  event.preventDefault();
  event.stopPropagation();
};

type UngroupedProxyItemProps = {
  ungroupedProxy: IUngroupedProxy;
  currentProfileId: string | null;
  isCurrentProxy: boolean;
  isCheckboxVisible: boolean;
  isOfferedProxy?: boolean;
  handleRowClick: React.MouseEventHandler<HTMLDivElement>;
  handleContextMenu: (
    event: React.MouseEvent<Element>,
    proxyContextMenuProxyLocation: ProxyContextMenuProxyLocation,
  ) => void;
  localProxyElementLocation: string | null;
  selectorContainerElement?: React.RefObject<HTMLDivElement>;
  style: React.CSSProperties;
};

const UngroupedProxyItem: React.FC<UngroupedProxyItemProps> = props => {
  const {
    ungroupedProxy,
    currentProfileId,
    isCurrentProxy,
    isCheckboxVisible,
    isOfferedProxy,
    handleRowClick,
    handleContextMenu,
    localProxyElementLocation,
    selectorContainerElement,
    style,
  } = props;

  const isProxyRestoring = useIsProxyRestoring(ungroupedProxy.id);
  const isProxyAvailable = useIsProxyAvailable(ungroupedProxy.country);

  const isProxyChecking = useIsProxyChecking(
    ungroupedProxy,
    currentProfileId || '',
    PROXY_CHECK_TOOLTIP_LOCATIONS.proxyUngroupedListItem,
  );
  const trafficData = useTrafficData();

  const isGeoProxyAvailable = isProxyAvailable && ungroupedProxy.mode === 'geolocation';
  const type = ungroupedProxy?.connectionType as GeoProxyType;
  const isRemainingTrafficZero = type && stringifyRemainingTrafficAmount(trafficData, type) === '0.0';

  const title = makeProxyTitle(ungroupedProxy);
  const isProxyEditable = getIsProxyEditable(ungroupedProxy);
  const isCheckFailed = determineIsProxyCheckFailed(ungroupedProxy);
  const isProxyConnected = ungroupedProxy?.status === true && !isProxyChecking;
  const profilesCounter = ungroupedProxy.profilesCount || 0;

  const handleProxyInfoEditClick: React.MouseEventHandler<Element> = async event => {
    preventDefaultAndPropagation(event);
    let proxyToEdit = ungroupedProxy;

    if (getIsProxyArchived(ungroupedProxy)) {
      proxyToEdit = await restoreProxy(ungroupedProxy);
    }

    openProxyManager({
      modalView: 'proxy-edit',
      currentProxy: proxyToEdit,
      currentProfileId,
      modalEditingProxyId: proxyToEdit.id,
      proxySelectorLocation: localProxyElementLocation,
    });
  };

  const proxyInteractiveProfilesCounterStyle: React.CSSProperties = {
    width: '35px',
  };

  const proxyTitleWrapperStyle: React.CSSProperties = {
    maxWidth: 'calc(100% - 96px)',
  };

  if (!isGeoProxyAvailable && isOfferedProxy) {
    proxyInteractiveProfilesCounterStyle.width = 'auto';
    proxyInteractiveProfilesCounterStyle.marginRight = '12px';
    proxyInteractiveProfilesCounterStyle.marginLeft = 'auto';

    proxyTitleWrapperStyle.maxWidth = 'calc(100% - 142px)';
  }

  const handleRowClickWrapper: React.MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation();

    if (!isGeoProxyAvailable && isOfferedProxy) {
      return openQuickPricing('popup-buy-locked-proxy');
    }

    handleRowClick(event);
  };

  const renderLeftControls = (): JSX.Element => {
    if (isOfferedProxy) {
      return (
        <UngroupedProxyStatusWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}>
          <ProxyStatusDot
            status={'success'}
            isRemainingTrafficZero={isRemainingTrafficZero}
            isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}
          />
        </UngroupedProxyStatusWrapper>
      );
    }

    if (isCheckboxVisible) {
      return (
        <UngroupedProxyStatusWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}>
          <ProxyItemCheckbox proxy={ungroupedProxy} isCheckboxVisible={isProxyEditable} />
        </UngroupedProxyStatusWrapper>
      );
    }

    return (
      <UngroupedProxyStatusWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}>
        <CheckProxyBtn
          proxy={ungroupedProxy}
          profileId={currentProfileId || ''}
          proxyTooltipView={PROXY_CHECK_TOOLTIP_LOCATIONS.proxyUngroupedListItem}
          selectorContainerElement={selectorContainerElement}
        />
      </UngroupedProxyStatusWrapper>
    );
  };

  const renderRightControls = (): JSX.Element => {
    if (!NEW_FEATURES.proxyGroupsV2) {
      return (
        <NoGroupsProxyRightControlsWrapper>
          <ProxyProfilesCounter profilesCounter={profilesCounter} isProxyEditable={isProxyEditable} />
          {isProxyEditable ? (
            <ProxyEditButton handleProxyInfoEditClick={handleProxyInfoEditClick} isProxyRestoring={isProxyRestoring} />
          ) : null}
          {isCurrentProxy ? (
            <ProxyConnectionButton
              profileId={currentProfileId || ''}
              unlinkIconType='cross'
              placement='proxy-manager-row'
            />
          ) : null}
        </NoGroupsProxyRightControlsWrapper>
      );
    }

    if (isCurrentProxy) {
      return (
        <ProxyConnectedStatusWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}>
          {isProxyEditable ? (
            <ProxyEditButton handleProxyInfoEditClick={handleProxyInfoEditClick} isProxyRestoring={isProxyRestoring} />
          ) : null}
          <ProxyUnlinkButton profileId={currentProfileId || ''} iconType='cross' placement='proxy-manager-row' />
          {isProxyConnected ? <ProxyConnectedStatus /> : null}
        </ProxyConnectedStatusWrapper>
      );
    }

    return (
      <ProxyInteractiveProfilesCounterWrapper
        isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}

        style={proxyInteractiveProfilesCounterStyle}
      >
        {!isGeoProxyAvailable && isOfferedProxy ? <PurchaseTrafficButton /> : null}
        {isProxyEditable && !isOfferedProxy ? (
          <ProxyEditButton handleProxyInfoEditClick={handleProxyInfoEditClick} isProxyRestoring={isProxyRestoring} />
        ) : null}
        {!isOfferedProxy ? (
          <ProxyProfilesCounter profilesCounter={profilesCounter} isProxyEditable={isProxyEditable} />
        ) : null}
      </ProxyInteractiveProfilesCounterWrapper>
    );
  };

  return (
    <UnroupedProxyItemWrapper
      style={style}
      isAlwaysOpaque={false}
      onClick={handleRowClickWrapper}
      onContextMenu={(event): void => {
        !isOfferedProxy && handleContextMenu(event, PROXY_CHECK_TOOLTIP_LOCATIONS.proxyUngroupedListItem);
      }}
      isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}
      isFailed={isCheckFailed}
      isHighlighted={NEW_FEATURES.proxyGroupsV2 ? isCurrentProxy : false}
    >
      {renderLeftControls()}
      <ProxyFlag
        countryCode={ungroupedProxy.country}
        height={NEW_FEATURES.proxyGroupsV2 ? BIG_FLAG_HEIGHT : SMALL_FLAG_HEIGHT}
        isCheckFailed={isCheckFailed}
      />
      <ProxyTitleWrapper
        isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}
        isSemiTransparent={isCheckFailed}
        style={proxyTitleWrapperStyle}
      >
        {title}
      </ProxyTitleWrapper>
      {renderRightControls()}
    </UnroupedProxyItemWrapper>
  );
};

export default UngroupedProxyItem;
