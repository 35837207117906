import styled from '@emotion/styled';

export const GeoProxyPopoverTitle = styled.div`
  padding: 12px 10px 2px 10px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  color: var(--767676);
  letter-spacing: 0.2px;
`;
