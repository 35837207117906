import { submitFoldersProfilesUpdate } from '../../features/folders/api';
import { IProfile } from '../../interfaces';
import { acceptManageFoldersProfiles } from '../../object-pool/transactions/accepters/accept-manage-folders-profiles';
import { getFolderSelectedOrAll } from '../../utils/get-folder-selected-or-all';
import { insertProfileToListByIndex } from '../../utils/insert-profile-to-list-by-index';
import { getCurrentWorkspaceId } from '../current-workspace-id.atom';
import { getProfilesList, mapAndSetProfilesList } from '../profiles-list.atom';
import { refreshFullProfile, updateFullProfilesMap } from '../profiles-map.atom';

export type ManageFoldersAction = 'add' | 'remove';

const manageProfileObjFolders = (profile: IProfile, folderName: string, action: ManageFoldersAction): IProfile => {
  const profileObj = { ...profile };
  if (action === 'add') {
    if (profileObj.folders && !profileObj.folders.includes(folderName)) {
      profileObj.folders.push(folderName);
    } else if (!profileObj.folders) {
      profileObj.folders = [folderName];
    }
  }

  if (action === 'remove' && profileObj.folders && profileObj.folders.includes(folderName)) {
    profileObj.folders = profileObj.folders.map((el: any) => el !== folderName ? el : null).filter(Boolean);
  }

  if (profileObj.folders) {
    profileObj.folders = profileObj.folders.sort();
  }

  return profileObj;
};

export const manageProfileFoldersList = (profilesIds: string[], folderName: string, action: ManageFoldersAction): void => {
  mapAndSetProfilesList((profilesPrev) => profilesPrev.map((profileObj) => {
    if (!profilesIds.includes(profileObj.id)) {
      return profileObj;
    }

    const newProfileObj = manageProfileObjFolders(profileObj, folderName, action);
    refreshFullProfile(newProfileObj);

    return newProfileObj;
  }));
};

const saveProfilesDeletedToSessionStorage = (profileDeletedIds: string[]): void => {
  const profilesDeleted = getProfilesList()
    .map((profileObject, profileIndex) => ({ profileObject, profileIndex }))
    .filter(({ profileObject }) => profileDeletedIds.includes(profileObject.id));

  try {
    const profileJson = JSON.stringify(profilesDeleted);
    sessionStorage.setItem('profilesDeleted', profileJson);
  } catch (error) {
    console.error(error);
  }
};

const getProfileDeletedFromSessionStorage = (): { profileObject: IProfile; profileIndex: number }[] => {
  const profilesDeletedJson = sessionStorage.getItem('profilesDeleted');
  if (!profilesDeletedJson) {
    return [];
  }

  try {
    return JSON.parse(profilesDeletedJson);
  } catch (error) {
    return [];
  }
};

const addProfileToList = (): void => {
  const profilesDeleted = getProfileDeletedFromSessionStorage();
  mapAndSetProfilesList((prevProfiles) => {
    let profilesUpdated = prevProfiles;
    profilesDeleted.forEach(({ profileObject, profileIndex }) => {
      profilesUpdated = insertProfileToListByIndex(profilesUpdated, profileObject, profileIndex);
    });

    updateFullProfilesMap(profilesUpdated);

    return profilesUpdated;
  });

  sessionStorage.removeItem('profilesDeleted');
};

const removeProfilesFromList = (profilesIds: string[]): void => {
  saveProfilesDeletedToSessionStorage(profilesIds);
  if (!profilesIds.length) {
    return;
  }

  mapAndSetProfilesList((profilesPrev) => {
    const newProfilesList = profilesPrev.filter(({ id }) => !profilesIds.includes(id));
    updateFullProfilesMap(newProfilesList);

    return newProfilesList;
  });
};

export const handleFolderProfiles = async (
  folderName: string,
  profilesIds: string[],
  action: ManageFoldersAction,
): Promise<void> => {
  const workspaceId = getCurrentWorkspaceId();

  manageProfileFoldersList(profilesIds, folderName, action);

  const resp = await submitFoldersProfilesUpdate({
    workspaceId,
    name: folderName,
    profiles: profilesIds,
    action,
  });

  await acceptManageFoldersProfiles(resp);

  const folderSelected = getFolderSelectedOrAll();
  if (folderSelected !== folderName) {
    return;
  }

  if (action === 'add') {
    addProfileToList();

    return;
  }

  removeProfilesFromList(profilesIds);
};
