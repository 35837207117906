import { getDefaultStore, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils'; 
import { TO_SHOW_CUSTOM_TOOLTIP_KEY_NAME } from '../../../common/constants/constants';

const toShowCustomTooltipAtom = atomWithStorage(TO_SHOW_CUSTOM_TOOLTIP_KEY_NAME, true);

export const useToShowCustomTooltip = (): boolean => {
  return useAtomValue(toShowCustomTooltipAtom);
};

export const setToShowCustomTooltip = (value: boolean): void => {
  localStorage.setItem(TO_SHOW_CUSTOM_TOOLTIP_KEY_NAME, value.toString());
  getDefaultStore().set(toShowCustomTooltipAtom, value);
};
