import { Quill } from 'quill';
import React, { FC, Ref } from 'react';
import ReactQuill from 'react-quill';

const formats = [
  'bold',
  'italic',
  'strike',
  'underline',
  'color',
  'background',
];

const toolbarOptions = [
  'bold',
  'italic',
  'strike',
  {
    background: [
      'var(--FFD5E1B3-notes)',
      'var(--FFF0AFB3-notes)',
      'var(--BFEBDEB3-notes)',
      'var(--C9E1FFB3-notes)',
      'var(--E4D8F2B3-notes)',
    ],
  },
  'underline',
  'clean',
];

function handleLinebreak(this: { quill: Quill }): void {
  const selection = this.quill.getSelection();
  if (!selection) {
    return;
  }

  const { index } = selection;
  this.quill.insertText(index, '\n');
  this.quill.setSelection({ index: index + 1, length: 0 });
}

const modules = {
  toolbar: {
    container: toolbarOptions,
    handlers: {},
  },
  keyboard: {
    bindings: {
      linebreak1: {
        key: 'enter',
        shortKey: true,
        handler: handleLinebreak,
      },
      linebreak2: {
        key: 'enter',
        shiftKey: true,
        handler: handleLinebreak,
      },
      enter: {
        key: 'enter',
        handler(this: { quill: Quill }): void {
          this.quill.blur();
        },
      },
    },
  },
};

interface IQuillNotes {
  notes: string;
  isEditing: boolean;
  onChange: (text: string) => void;
  onFocus: () => void;
  isInDrawer?: boolean;
  quillRef: Ref<ReactQuill>;
}

const QuillNotes: FC<IQuillNotes> = (props) => {
  const { notes, isEditing, onChange, onFocus, isInDrawer, quillRef } = props;

  return (
    <ReactQuill
      theme="bubble"
      className={isEditing ? 'ql-focus' : ''}
      value={notes}
      onChange={onChange}
      onFocus={onFocus}
      modules={modules}
      formats={formats}
      style={{
        padding: 0,
        width: '100%',
        color: isInDrawer ? 'var(--222222-notes-cell)' : '',
      }}
      placeholder=""
      ref={quillRef}
    />
  );
};

export default QuillNotes;
