import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { sendActionAnalytics } from '../../features/common/api';
import { ProxyManagerModalView } from '../../features/proxy/components/interfaces/proxy-manager-modal-view.type';
import { IProxy } from '../../interfaces';
import PerformanceObserverService from '../../services/performance-observer/performance-observer.service';
import { getProfilesList } from '../profiles-list.atom';
import { resetProxyForm, updateProxyForm } from './custom-proxy-form-data.atom';
import { resetProxyFormTab } from './proxy-form-tabs.atom';
import { hideProxyGroups } from './proxy-groups/proxy-visible-groups.atom';
import { resetCustomlySortedProxies } from './proxy-list.atom';
import { resetSelectedProxies } from './selected-proxies.atom';

// eslint-disable-next-line no-shadow
export enum ProxySelectorLocation {
  profileSettingsPage = 'profile-settings-page',
}

export interface IProxyManagerState {
  containerElement: Element | null;
  currentProfileId: string | null;
  modalView: ProxyManagerModalView | null;
  modalEditingProxyId: string | null;
  proxySelectorLocation: string | null;
  currentProxy: IProxy | null;
  handleProxySelect: ((newProxyId: string | null) => void) | null;
}

const PROXY_MANAGER_DEFAULT_STATE: IProxyManagerState = {
  containerElement: null,
  currentProfileId: null,
  modalView: null,
  modalEditingProxyId: null,
  proxySelectorLocation: null,
  currentProxy: null,
  handleProxySelect: null,
};

export const proxyManagerStateAtom = atom(PROXY_MANAGER_DEFAULT_STATE);
export const lastModalViewAtom = atom<ProxyManagerModalView | null>(null);

export const getProxyManagerState = (): IProxyManagerState => getDefaultStore().get(proxyManagerStateAtom);
export const getIsProxyManagerVisible = (): boolean => !!getProxyManagerState().modalView;

const setProxyManagerState = (data: IProxyManagerState): void => {
  const currentState = getDefaultStore().get(proxyManagerStateAtom);

  if (currentState.modalView !== null && currentState.modalView !== data.modalView) {
    getDefaultStore().set(lastModalViewAtom, currentState.modalView);
  }

  getDefaultStore().set(proxyManagerStateAtom, data);
};

export const useProxyManagerState = (): IProxyManagerState => useAtomValue(proxyManagerStateAtom);
export const useIsProxyManagerVisible = (): boolean => !!useAtomValue(proxyManagerStateAtom).modalView;
export const useLastModalView = (): ProxyManagerModalView | null => useAtomValue(lastModalViewAtom);

export const setProxyManagerCurrentProxy = (currentProxy: IProxy | null): void => {
  const proxyManagerState = getProxyManagerState();
  setProxyManagerState({ ...proxyManagerState, currentProxy });
};

export const switchProxyManagerView = (newModalView: ProxyManagerModalView, newModalEditingProxyId?: string): void => {
  const proxyManagerState = getProxyManagerState();
  setProxyManagerState({
    ...proxyManagerState,
    modalView: newModalView,
    modalEditingProxyId: newModalEditingProxyId || null,
  });
};

export const openProxyManagerListView = (): void => switchProxyManagerView('proxy-list');

export const resetLastModalView = (): void => {
  getDefaultStore().set(lastModalViewAtom, null);
};

const recordOpenProxyManagerPerformance = (): void => {
  const proxiesCount = getProfilesList().length;
  const performanceObserverService = PerformanceObserverService.getInstance();
  performanceObserverService.handleUserAction({ userAction: 'open-proxy-manager', proxiesCount });
};

export interface IProxyManagerOpenParams {
  proxySelectorLocation: IProxyManagerState['proxySelectorLocation'];
  currentProxy: IProxyManagerState['currentProxy'];
  modalView: ProxyManagerModalView;
  containerElement?: IProxyManagerState['containerElement'];
  currentProfileId?: IProxyManagerState['currentProfileId'];
  modalEditingProxyId?: IProxyManagerState['modalEditingProxyId'];
  handleProxySelect?: IProxyManagerState['handleProxySelect'];
}

export const openProxyManager = ({
  containerElement,
  proxySelectorLocation,
  currentProxy,
  currentProfileId,
  modalView,
  modalEditingProxyId,
  handleProxySelect,
}: IProxyManagerOpenParams): void => {
  recordOpenProxyManagerPerformance();
  const proxyManagerState = getProxyManagerState();
  const selectorLocation =
    proxyManagerState.proxySelectorLocation === ProxySelectorLocation.profileSettingsPage
      ? proxyManagerState.proxySelectorLocation
      : proxySelectorLocation;

  const stateUpdated: IProxyManagerState = {
    ...proxyManagerState,
    modalView,
    proxySelectorLocation: selectorLocation,
    currentProxy,
  };

  if (containerElement) {
    stateUpdated.containerElement = containerElement;
  }

  if (currentProfileId) {
    stateUpdated.currentProfileId = currentProfileId;
  }

  if (modalEditingProxyId) {
    stateUpdated.modalEditingProxyId = modalEditingProxyId;
  }

  if (handleProxySelect) {
    stateUpdated.handleProxySelect = handleProxySelect;
  }

  if (currentProxy && modalView !== 'proxy-add') {
    updateProxyForm(currentProxy);
  } else {
    resetProxyForm();
  }

  setProxyManagerState(stateUpdated);
  sendActionAnalytics('opened proxy manager');
};

export const closeProxyManager = (): void => {
  resetProxyForm();
  setProxyManagerState(PROXY_MANAGER_DEFAULT_STATE);
  resetSelectedProxies();
  hideProxyGroups();
  resetCustomlySortedProxies();
  resetProxyFormTab();
  resetLastModalView();
};
