import React from 'react';

import { ProxyModeSelectorWrapper, ProxyModeLabel, ProxyModeRadioInput, ProxyModeRadioInputContent } from './styles';
import { ProxyMode, UserProxyFormMode } from '../../../../../interfaces';
import { USER_PROXY_FORM_MODES } from '../constants';

type ProxyModeSelectorProps = {
  currentMode: ProxyMode;
  handleChangeMode: React.ChangeEventHandler<HTMLInputElement>;
}

export const ProxyModeSelector: React.FC<ProxyModeSelectorProps> = (props) => {
  const { currentMode, handleChangeMode } = props;

  const proxyProtocol = currentMode === 'geolocation' ? 'http' : currentMode;

  const renderModeOption = (modeOption: UserProxyFormMode): JSX.Element => {
    const isModeOptionSelected = modeOption.value === proxyProtocol;

    return (
      <ProxyModeLabel key={modeOption.value}>
        <ProxyModeRadioInput
          checked={isModeOptionSelected}
          onChange={handleChangeMode}
          type='radio'
          value={modeOption.value}
        />
        <ProxyModeRadioInputContent>
          {modeOption.label}
        </ProxyModeRadioInputContent>
      </ProxyModeLabel>
    );
  };

  return (
    <ProxyModeSelectorWrapper>
      {USER_PROXY_FORM_MODES.map(renderModeOption)}
    </ProxyModeSelectorWrapper>
  );
};
