import React from 'react';
import { Trans } from 'react-i18next';

import { E_ANALYTICS_ACTIONS } from '../../../../../common/constants/analytics';
import { NEW_FEATURES } from '../../../../state/feature-toggle/new-features';
import { getProxyManagerState } from '../../../../state/proxy/proxy-manager-modal-status.atom';
import { IconClose } from '../../../../ui/gologin-header/icons';
import { IconUnlink } from '../../../../ui/gologin-header/icons/icon-unlink';
import { IIconWrapper } from '../../../../ui/gologin-header/icons/wrapper';
import IconCloseCurrentColor from '../../../../ui/icons/IconCloseCurrentColor';
import TooltipWrapper from '../../../../ui/tooltip-custom/tooltip-wrapper';
import { sendActionAnalytics } from '../../../common/api';
import { handleNoProxyClick } from '../../proxy-helpers';
import { UnlinkButtonWrapper, UnlinkIconWrapper } from './styles';

const iconProps: IIconWrapper = {
  padding: 0,
  iconColor: 'var(--98989F-proxy-manager-icon)',
  iconHoveredColor: 'var(--2B2B31-proxy-manager-active-icon)',
};

export type ProxyUnlinkButtonProps = {
  profileId: string;
  iconType: 'unlink' | 'cross';
  placement: 'check-tooltip' | 'proxy-manager-row';
};

const ProxyUnlinkButton: React.FC<ProxyUnlinkButtonProps> = props => {
  const { profileId, iconType, placement } = props;

  const handleClickUnlink: React.MouseEventHandler<HTMLDivElement> = (event): void => {
    let actionName = E_ANALYTICS_ACTIONS.clickedUnlinkProxyViaProxyManager;
    if (placement === 'check-tooltip') {
      actionName = E_ANALYTICS_ACTIONS.clickedUnlinkProxyViaCheckProxyTooltip;
    }

    sendActionAnalytics(actionName, { profile: profileId });

    const { handleProxySelect } = getProxyManagerState();
    if (!handleProxySelect) {
      handleNoProxyClick(event, profileId);

      return;
    }

    event.preventDefault();
    event.stopPropagation();

    handleProxySelect(null);
  };

  const renderUnlinkIcon = (): JSX.Element => {
    if (!NEW_FEATURES.proxyGroupsV2) {
      return <IconCloseCurrentColor />;
    }

    if (iconType === 'cross') {
      return <IconClose {...iconProps} />;
    }

    return <IconUnlink {...iconProps} />;
  };

  return (
    <UnlinkButtonWrapper isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}>
      <TooltipWrapper isTextEllipsis={false} hasTooltipAnimation={true} value={<Trans i18nKey='proxies.unlinkProxy' />}>
        <UnlinkIconWrapper onClick={handleClickUnlink} isProxyManagerWithGroups={!!NEW_FEATURES.proxyGroupsV2}>
          {renderUnlinkIcon()}
        </UnlinkIconWrapper>
      </TooltipWrapper>
    </UnlinkButtonWrapper>
  );
};

export default ProxyUnlinkButton;
