import styled from '@emotion/styled';
import React from 'react';

import { closeOverlayMask, useOverlayMask } from '../../../state/overlay-mask.atom';
import { hideProxyContextMenu, useProxyContextMenuProxy } from '../../../state/proxy/proxy-context-menu.atom';
import { closeProxyManager, useIsProxyManagerVisible } from '../../../state/proxy/proxy-manager-modal-status.atom';
import {
  closeProxySettingsModal,
  useIsProxySettingsModalVisible,
} from '../../../state/proxy/proxy-settings-modal.atom';
import { closeTagManager, useIsTagManagerOpen } from '../../../state/tags/tag-manager-state.atom';

type BackdropPosition = 'fixed' | 'absolute';

export const PAGE_BACKDROP_Z_INDEX = 210;

const PageBackdrop = styled('div')<{ position: BackdropPosition }>`
  position: ${(props): BackdropPosition => props.position};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: ${PAGE_BACKDROP_Z_INDEX};
`;

interface IPageOverlay {
  style?: React.CSSProperties;
  position?: BackdropPosition;
}

const PageOverlay: React.FC<IPageOverlay> = props => {
  const { style: styleProp, position = 'fixed' } = props;

  const { isVisible: isOverlayMaskVisible } = useOverlayMask();
  const isProxyManagerVisible = useIsProxyManagerVisible();
  const proxyContextMenuProxy = useProxyContextMenuProxy();
  const isProxySettingsModalVisible = useIsProxySettingsModalVisible();
  const isTagManagerOpen = useIsTagManagerOpen();

  const isBackdropVisible = [
    isTagManagerOpen,
    isProxyManagerVisible,
    isOverlayMaskVisible,
    isProxySettingsModalVisible,
  ].some(Boolean);

  const onBackdropClick = (): void => {
    switch (true) {
      case isTagManagerOpen:
        closeTagManager();
        break;
      case isProxyManagerVisible:
        if (proxyContextMenuProxy) {
          hideProxyContextMenu();
        } else {
          closeProxyManager();
        }

        break;
      case isProxySettingsModalVisible:
        closeProxySettingsModal();
        break;
      case isOverlayMaskVisible:
        closeOverlayMask();
        break;
      default:
    }
  };

  if (!isBackdropVisible) {
    return null;
  }

  const style = {
    ...styleProp,
    zIndex: isProxyManagerVisible ? 121 : 210,
  };

  return <PageBackdrop onClick={onBackdropClick} position={position} style={style} />;
};

export default PageOverlay;
