import styled from '@emotion/styled';
import { IIconWrapper } from '../../../../../ui/gologin-header/icons/wrapper';
import { ISelectCustom } from '../../../../../ui/modern-select/interfaces';
import {
  ADD_BUTTON_ARROW_ACTIVE_COLOR,
  ADD_BUTTON_ARROW_COLOR,
} from '../../proxy-list/proxy-group-add-button/proxy-group-add-button-menu/styles';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export const GEOPROXY_MODERN_SELECT_STYLE: React.CSSProperties = {
  position: 'relative',
  top: 0,
  width: 'fit-content',
  height: '100%',
  maxWidth: '100%',
};

export const GEOPROXY_MODERN_SELECT_POPOVER_STYLE: React.CSSProperties = {
  marginLeft: '-16px',
};

export const GEOPROXY_MODERN_SELECT_POPOVER_WIDTH: ISelectCustom['popoverWidth'] = '220px';
export const GEOPROXY_MODERN_SELECT_Z_INDEX: ISelectCustom['zIndex'] = 1060;

export const evaluateGeoProxyModernSelectArrowColor = (isHighlighted: boolean): IIconWrapper['iconColor'] =>
  isHighlighted ? ADD_BUTTON_ARROW_ACTIVE_COLOR : ADD_BUTTON_ARROW_COLOR;


export const getGeoProxyModernSelectTitles = (translate: TFunction) => {
  const allCountriesTitle = translate(`proxy.allCountries.title`);
  const recentCountriesTitle = translate(`proxy.recentCountries.title`);

  return {
    GEOPROXY_MODERN_SELECT_RECENT_COUNTRIES_TITLE: allCountriesTitle,
    GEOPROXY_MODERN_SELECT_ALL_COUNTRIES_TITLE: recentCountriesTitle,
  };
};

export const RemainingTrafficLimit = styled.span`
  color: var(--B5B5BA);
  margin-left: auto;
`;
