/* eslint-disable max-lines */
// hide these warnings for now - there are ~300 this.state and this.props
/* eslint-disable react/destructuring-assignment */
import {
  SimpleSortableContext,
} from '@dnd-kit-contextless/sortable';
import * as Sentry from '@sentry/react';
import { ConfigProvider, Dropdown, Menu, message } from 'antd';
import { TFunction } from 'i18next';
import React, { Component, SyntheticEvent, useEffect } from 'react';
import ReactCopyToClipboard from 'react-copy-to-clipboard';
import ReactDragListView from 'react-drag-listview';
import { Trans, TransProps, withTranslation } from 'react-i18next';
import type { ResizeCallbackData } from 'react-resizable';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getDefaultColumnsSettings } from './default-columns-settings';
import { InfiniteScrollProfilesTable } from './infinite-scroll-profiles-table';
import { IProfilesTableColumn, IProfilesTableSettings, ProfilesTableColumn } from './interfaces/app-settings.interface';
import { BrowserStartData, ILaunchProfileOrbita } from './interfaces/launch-profile-orbita.interface';
import { ITransferParams } from './interfaces/transfer-params.interface';
import ProfilesMenu from './profiles-menu';
import ResizableTitle from './resizable-title';
import { DrawerDotsButton } from './styles';
import TabProfiles from './tab-profile';
import { TableProfile } from './table-custom';
import { ProxyTypeColumn } from './TableColumns';
import ProxyParamCell from './TableColumns/ProxyParamColumn';
import { E_ANALYTICS_ACTIONS } from '../../../../common/constants/analytics';
import { PAGE_SIZE } from '../../../../common/constants/constants';
import { PROFILE_RUN_DEEP_LINK_EVENT_NAME } from '../../../../common/constants/events';
import { DeepLinkProtocol } from '../../../../common/types/deep-link-protocol';
import { normalizeProfilesData } from '../../../../common/utils';
import { IUpdateProfileStatusEvent } from '../../../../electron/interfaces/profile.status.manager.interfaces';
import { IProfile, IProxy } from '../../../interfaces';
import { history } from '../../../services';
import PerformanceObserverService from '../../../services/performance-observer/performance-observer.service';
import { ITemplatesCtx } from '../../../state';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { getFolderById } from '../../../state/folders/folders-list.atom';
import {
  getFlatProfileRunStatuses,
  getProfileRunStatus,
  updateProfileRunStatusesForWeb,
} from '../../../state/profile-run-statuses.atom';
import {
  filterProfilesByPermission,
  getProfileNamesForNotifications,
  getProfilesTableGroupHeaders,
  IProfileRunStatus,
  mapAndSetProfilesList,
  resetProfilesList,
} from '../../../state/profiles-list.atom';
import { IBasicTableEntity, IBasicTableProfile } from '../../../state/profiles-table/basic-table-entities-subtypes';
import { getBasicTableEntities, getBasicTableEntityById, useBasicTableEntities } from '../../../state/profiles-table/basic-table-entities.atom';
import { ProfilesQueryLoadingStatus, doesProfilesQueryHaveMoreProfiles, paginateProfilesQuery } from '../../../state/profiles-table/profiles-query';
import {
  getProfilesSortField,
  resetProfilesTableSorting,
  sortProfilesTable,
  useProfilesTableSorting,
} from '../../../state/profiles-table/profiles-sort.atom';
import { getIsProfilesTableGrouped, getProfilesTableGroupField } from '../../../state/profiles-table/profiles-table-group-field.atom';
import { SHARE_MODAL_NAME, closeProfileTableModal, openProfileTableModal } from '../../../state/profiles-table-modal.atom';
import {
  getProfilesTableSelectedIds,
  updateProfilesTableSelectedIds,
  useOldProfilesTableSelectedRowIndexes,
} from '../../../state/profiles-table-selected-ids.atom';
import {
  onProfilesTableRowClick,
  onUserChangeSelectedProfiles,
  updateProfileStateAtFinish,
  updateProfileStateAtLaunch,
  updateProfileStatus,
} from '../../../state/profiles-table.commands';
import { getProxyListProxyById } from '../../../state/proxy/proxy-list.atom';
import { GologinCheckbox } from '../../../ui/gologin-checkbox';
import { IconSpinner } from '../../../ui/gologin-header/icons';
import GologinModal from '../../../ui/gologin-modal';
import GologinTable from '../../../ui/gologin-table';
import { IGologinTableHeaderColumn, IResizeProps } from '../../../ui/gologin-table/gologin-table-header-column';
import IconDotsInButton from '../../../ui/icons/IconDotsInButton';
import IconSetting from '../../../ui/icons/IconSetting';
import { INewNameProfile } from '../../../utils/parse-name-formatting/interfaces';
import { ReactError } from '../../../utils/sentry-parameters/custom-errors';
import { ITransactionObject } from '../../../utils/sentry-parameters/helper.functions.interfaces';
import { sendReactErrorToSentry } from '../../../utils/sentry.helper';
import {
  generateBrowserIconsPromise,
  getIconsFromSessionStorage,
  getParamsToGenerateIcons,
  removeIconsFromSessionStorage,
  saveIconsToSessionStorage,
} from '../../browser-system-icons';
import { requestShortCookies, sendActionAnalytics } from '../../common/api';
import ColumnHeader from '../../common/components/column-header';
import ColumnFilterResetButton from '../../common/components/column-header/column-filter-reset-button';
import CustomTableLoader from '../../common/custom-table/components/custom-table-loader';
import { IFilter, Sorter } from '../../common/custom-table/interfaces/column.interfaces';
import { DEEP_LINKS_REDIRECT_ORIGIN, getProfileForLink } from '../../common/deep-links';
import { ICookiesListModal } from '../../common/interfaces/cookies-list-modal.interface';
import { MajorVersionOption } from '../../common/orbita-browser';
import { SortOrder } from '../../common/sorter-order';
import { updateProfileNotes } from '../../drawer/api';
import ProfileDrawer from '../../drawer/components/index';
import CookiesList from '../../modalsComponents/components/cookies-manager/cookies-list';
import { IFolderChange } from '../../modalsComponents/components/folders-manager/interfaces/folder-change.interface';
import { IFoldersSelected } from '../../modalsComponents/components/folders-manager/interfaces/folders-selected.interface';
import OrbitaVersionModals from '../../modalsComponents/components/orbita-version-modals';
import HistoryModal from '../../modalsComponents/components/profile-upload-history';
import { calculateProfileDeleteOrLeave } from '../../modalsComponents/components/remove-profile-modal';
import ShareModal from '../../modalsComponents/components/share-modal';
import TransferProfileModal from '../../modalsComponents/components/transfer-profile';
import UpdateProfilesModal from '../../modalsComponents/components/update-profiles-ua/update-profiles';
import { requestFullProfileInfo } from '../../profile-settings/api';
import { getIsProxyArchived, restoreProxy, syncProxyTooltipWithProfileStatus } from '../../proxy/proxy-helpers';
import TagsCell from '../../tags/components/tags';
import { IAddTag, ITagBase } from '../../tags/interfaces/tag.interface';
import { launchProfileWeb } from '../actions';
import {
  cloneProfile,
  cloneWorkspaceProfiles,
  IPinParams,
  pin,
  PinAction,
  requestProfiles,
  saveProfileTableColumns,
  saveProfileTableColumnsOrder,
  saveWorkspaceProfileTableColumns,
  saveWorkspaceProfileTableColumnsOrder,
  transferProfile,
  transferToMyWorkspace,
  unpin,
  updateRunStatus,
} from '../api';
import { evaluateProfilesTableColumnsOrder } from '../columns-order';
import CustomStatusCell from '../custom-status-cell';
import DateCell, { DateCellField } from '../date-cell';
import { RenderTableCellFn } from '../interfaces';
import NameCell from '../name-cell';
import NoProfilesPlaceholder from '../no-profiles-placeholder';
import NotesCell from '../notes-cell';
import OrbitaVersionCell from '../orbita-version-cell';
import OsCell from '../os-cell';
import ProfileDropdownMenu from '../profile-dropdown-menu';
import ProxyCell from '../proxy-cell';
import ButtonQuickCreateProfile from '../quick-create-profile';
import { ICreateQuickProfileOpts, createQuickProfile } from '../quick-create-profile/create-quick-profile.action';
import SharingCell from '../sharing-cell';
import StatusCell from '../status-cell';
import {
  ContainerNameCol,
  MarginIcon,
  ProfilesTabsContainer,
  RowNameFolder,
  TextNameFolder,
} from '../table/styles';
import TableCustomizationMenu from '../table-customization-menu';
import TableCustomizationSettingsButton from '../table-customization-menu/settings-button';

const PROFILES_STATUS_UPDATE_TIMING = 10 * 1000;
let profileStatusUpdateIntervalId: NodeJS.Timeout;

const ROW_SELECTION = {
  columnWidth: 34,
};

const isElectron = !!window.require;
let ipcRenderer: Electron.IpcRenderer;
if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

interface IProfileTable extends TransProps, IProfilesTableQuick {
  t: TFunction;
}

interface IProfilesTableQuick extends IFoldersSelected, IFolderChange {
  basicTableEntities: IBasicTableEntity[];
  selectedProfileRowKeys: string[]; // used only by !header table
  updateSelectedProfileIds: (newSelectedProfileIds: string[]) => void;
  profilesTableSettings: IProfilesTableSettings|null;
  setProfilesTableSettings: (profilesTableSettings: IProfilesTableSettings|null) => void;
  browserUpdating: boolean | undefined;
  versionLoadingNow: string | undefined;
  foldersList: any[];
  allProfilesFolderId?: string;
  manageProfileFoldersList: (profilesIds: string[], folderName: string, action: string) => void;
  openFoldersModal: (profileIds: string[]) => void;
  setProfiles: (set: (prevProfiles: IProfile[]) => IProfile[]) => void;
  updateTag: (tag: ITagBase) => Promise<void>;
  removeTag: (tagId: string, isInSuggest: boolean) => Promise<void>;
  addTag: (params: IAddTag) => Promise<void>;
  removeProfileTag: (profileIds: string[], tagId: string) => Promise<void>;
  downloadSpecificOrbitaVersion: (version: string) => Promise<void>;
  selectedFolderFromCtx: string;
  isOrbitaVersionCompatible: (versionValue: MajorVersionOption) => Promise<boolean>;
  lastWorkspaceId: string;
  sortField: SortField;
  sortOrder: SortOrder;
  onFolderChange: (newFolderName: string) => void;
  profilesQueryLoadingStatus: ProfilesQueryLoadingStatus;
}

class PTableClass extends Component<IProfileTable> {
  constructor(props: IProfileTable) {
    super(props);
    this.profileNameRef = React.createRef();
    this.isElectron = !!window.require;

    const { t: translation, profilesTableSettings } = props;

    const previousSelectedFolder = localStorage.getItem('SelectedFolder');
    let selectedFolder: string;
    if (NEW_FEATURES.header) {
      selectedFolder = this.props.selectedFolderFromCtx;
    } else {
      selectedFolder = previousSelectedFolder ? previousSelectedFolder : 'all';
    }

    let columnsSettings: any = getDefaultColumnsSettings();
    const proxyColumnTitle = NEW_FEATURES.renameProxyToLocation ? 'profiles.tableColumns.location' : 'profiles.tableColumns.proxy';
    let columnsOrder = evaluateProfilesTableColumnsOrder({
      isProxyColumnShownAmongFirstColumns: NEW_FEATURES.renameProxyToLocation,
    });

    if (profilesTableSettings) {
      if (profilesTableSettings.columnsOrder) {
        const newColumns = ['customStatus', 'proxyPort', 'proxyUsername', 'proxyPassword', 'orbitaVersion'];
        columnsOrder = profilesTableSettings.columnsOrder.concat(newColumns.filter((item) => !profilesTableSettings.columnsOrder.includes(item)));
      }

      if (profilesTableSettings.columnsSettings) {
        const settingsRes: any = columnsSettings;
        for (const el of profilesTableSettings.columnsSettings) {
          if (el.title) {
            settingsRes[el.title] = {
              visible: el.visible,
              width: el.width,
            };
          }
        }
        columnsSettings = settingsRes;
      }
    }

    const columnsList: IProfilesTableColumn[] = [{
      colName: 'name',
      title: <ColumnHeader
        column='name'
        title={translation('base.name')}
        enableDrag={false}
        hasSort={true}
        sorterField='name'
        quickCreateProfile={this.quickCreateProfile}
      />,
      label: translation('base.name'),
      dataIndex: 'name',
      render: (_, record) => (
        <NameCell
          profileAtom={record.atom}
          rowIdx={record.idx}
          setProfiles={this.props.setProfiles}
          browserUpdating={!!this.props.browserUpdating}
          versionLoadingNow={this.props.versionLoadingNow}
          renderDropDownMenu={this.renderDropDownMenu}
          launchProfileOrbita={this.launchProfileOrbita}
        />
      ),
      minWidth: 240,
      width: columnsSettings.name.width < 240 ? 240 : columnsSettings.name.width,
      visible: columnsSettings.name.visible,
      sortField: 'name',
      className: NEW_FEATURES.header ? 'new-name-cell no-drag' : 'no-drag name-cell',
      actionsSlot: <ButtonQuickCreateProfile
        createProfile={(templateCtx): Promise<void> => this.quickCreateProfile(templateCtx)}
        tooltipContent={<Trans i18nKey='tooltip.profilesTable.addProfile' />}
      />,
    }, {
      // TODO: rename to 'state' everywhere (after final decision is made)
      colName: 'status',
      title: <ColumnHeader
        column='status'
        title={translation('profiles.tableColumns.state')}
      />,
      label: translation('profiles.tableColumns.state'),
      dataIndex: 'status',
      render: this.renderState,
      minWidth: 110,
      width: columnsSettings.status.width,
      visible: columnsSettings.status.visible,
      className: NEW_FEATURES.header ? 'new-status-cell draggable' : 'draggable',
    }, {
      colName: 'notes',
      title: <ColumnHeader
        column='notes'
        title={translation('quickSettings.notes.title')}
      />,
      label: translation('quickSettings.notes.title'),
      dataIndex: 'notes',
      render: this.renderNotes,
      minWidth: 120,
      width: columnsSettings.notes.width,
      visible: columnsSettings.notes.visible,
      className: NEW_FEATURES.header ? 'new-notes-cell draggable' : 'notes-cell draggable',
      onCellClick: (_, event) => event.stopPropagation(),
    }, {
      colName: 'tags',
      title: <ColumnHeader
        column='tags'
        title={translation('quickSettings.tags.title')}
      />,
      label: translation('quickSettings.tags.title'),
      dataIndex: 'tags',
      render: this.renderTags,
      minWidth: 120,
      width: columnsSettings.tags.width,
      visible: columnsSettings.tags.visible,
      className: NEW_FEATURES.header ? 'new-tags-cell draggable' : 'tags-cell draggable',
      onCellClick: (_, event) => event.stopPropagation(),
      actionsSlot: <ColumnFilterResetButton field='tags' />,
    }, {
      colName: 'customStatus',
      title: <ColumnHeader
        column='customStatus'
        title={translation('profiles.tableColumns.customStatus')}
      />,
      label: translation('profiles.tableColumns.customStatus'),
      dataIndex: 'customStatus',
      render: this.renderCustomStatus,
      minWidth: 120,
      width: columnsSettings.customStatus.width,
      visible: columnsSettings.customStatus.visible,
      className: NEW_FEATURES.header ? 'new-tags-cell draggable' : 'tags-cell draggable',
      onCellClick: (_, event) => event.stopPropagation(),
      actionsSlot: <ColumnFilterResetButton field='custom-status' />,
    }, {
      colName: 'lastUpdate',
      title: <ColumnHeader
        column='lastUpdate'
        title={translation('profiles.tableColumns.lastUpdate')}
        hasSort={true}
        sorterField='updatedAt'
      />,
      label: translation('profiles.tableColumns.lastUpdate'),
      dataIndex: 'updatedAt',
      render: (_, profile) => this.renderDate(profile, 'updatedAt'),
      minWidth: 120,
      width: columnsSettings.lastUpdate.width,
      visible: columnsSettings.lastUpdate.visible,
      sortField: 'updatedAt',
      className: 'draggable',
    }, {
      colName: 'lastLaunch',
      title: <ColumnHeader
        column='lastLaunch'
        title={translation('profiles.tableColumns.lastLaunch')}
        hasSort={true}
        sorterField='lastActivity'
      />,
      label: translation('profiles.tableColumns.lastLaunch'),
      dataIndex: 'lastActivity',
      render: (_, profile) => this.renderDate(profile, 'lastActivity'),
      minWidth: 120,
      width: columnsSettings.lastLaunch.width,
      visible: columnsSettings.lastLaunch.visible,
      sortField: 'lastActivity',
      className: 'draggable',
    }, {
      colName: 'createdAt',
      title: <ColumnHeader
        column='createdAt'
        title={translation('profiles.tableColumns.createdAt')}
        hasSort={true}
        sorterField='createdAt'
      />,
      label: translation('profiles.tableColumns.createdAt'),
      dataIndex: 'createdAt',
      render: (_, profile) => this.renderDate(profile, 'createdAt'),
      minWidth: 120,
      width: columnsSettings.createdAt.width,
      visible: columnsSettings.createdAt.visible,
      sortField: 'createdAt',
      className: 'draggable',
    }, {
      colName: 'sharing',
      title: <ColumnHeader
        column='sharing'
        title={translation('profiles.tableColumns.sharing')}
        hasSort={true}
        sorterField='sharedEmails'
      />,
      label: translation('profiles.tableColumns.sharing'),
      dataIndex: 'sharedEmails',
      render: this.renderSharing,
      minWidth: 120,
      width: columnsSettings.sharing.width,
      visible: columnsSettings.sharing.visible,
      sortField: 'sharedEmails',
      className: 'draggable',
    }, {
      colName: 'proxyType',
      title: <ColumnHeader
        column='proxyType'
        title={translation('profiles.tableColumns.proxyType')}
        hasSort={true}
        sorterField='proxyType'
      />,
      label: translation('profiles.tableColumns.proxyType'),
      dataIndex: 'proxyType',
      render: this.renderProxyType,
      minWidth: 120,
      width: columnsSettings.proxyType.width,
      visible: columnsSettings.proxyType.visible,
      sortField: 'proxyType',
      className: 'draggable',
    }, {
      colName: 'proxy',
      title: <ColumnHeader column='proxy' title={translation(proxyColumnTitle)} />,
      label: translation(proxyColumnTitle),
      labelTooltip: 'profiles.tableColumnsTooltips.location',
      dataIndex: 'proxy',
      render: this.renderProxy,
      minWidth: 120,
      width: columnsSettings.proxy.width,
      visible: columnsSettings.proxy.visible,
      className: NEW_FEATURES.header ? 'new-proxy-cell draggable' : 'proxy-cell draggable',
      onCellClick: (_, event) => event.stopPropagation(),
      leftPadding: 24,
      actionsSlot: <ColumnFilterResetButton field='proxy-id' />,
    }, {
      colName: 'proxyPort',
      title: <ColumnHeader
        column='proxyPort'
        title={translation('profiles.tableColumns.proxyPort')}
      />,
      label: translation('profiles.tableColumns.proxyPort'),
      dataIndex: 'proxyPort',
      render: (_, profile) => this.renderProxyParam(profile, 'port'),
      minWidth: 120,
      width: columnsSettings.proxyPort.width,
      visible: columnsSettings.proxyPort.visible,
      className: 'draggable',
      onCellClick: (_, event) => event.stopPropagation(),
    }, {
      colName: 'proxyUsername',
      title: <ColumnHeader
        column='proxyUsername'
        title={translation('profiles.tableColumns.proxyUsername')}
      />,
      label: translation('profiles.tableColumns.proxyUsername'),
      dataIndex: 'proxyUsername',
      render: (_, profile) => this.renderProxyParam(profile, 'username'),
      minWidth: 120,
      width: columnsSettings.proxyUsername.width,
      visible: columnsSettings.proxyUsername.visible,
      className: 'draggable',
      onCellClick: (_, event) => event.stopPropagation(),
    }, {
      colName: 'proxyPassword',
      title: <ColumnHeader
        column='proxyPassword'
        title={translation('profiles.tableColumns.proxyPassword')}
      />,
      label: translation('profiles.tableColumns.proxyPassword'),
      dataIndex: 'proxyPassword',
      render: (_, profile) => this.renderProxyParam(profile, 'password'),
      minWidth: 120,
      width: columnsSettings.proxyPassword.width,
      visible: columnsSettings.proxyPassword.visible,
      className: 'draggable',
      onCellClick: (_, event) => event.stopPropagation(),
    }, {
      colName: 'os',
      title: <ColumnHeader
        column='os'
        title={translation('base.os')}
        hasSort={true}
        sorterField='os'
      />,
      label: translation('base.os'),
      dataIndex: 'os',
      render: this.renderOs,
      minWidth: 120,
      width: columnsSettings.os.width,
      visible: columnsSettings.os.visible,
      sortField: 'os',
      className: 'draggable',
    }, {
      colName: 'orbitaVersion',
      title: <ColumnHeader
        column='orbitaVersion'
        title={translation('profiles.tableColumns.orbitaVersion')}
      />,
      label: translation('profiles.tableColumns.orbitaVersion'),
      dataIndex: 'navigator',
      render: this.renderOrbitaVersion,
      minWidth: 120,
      width: columnsSettings.orbitaVersion.width,
      visible: columnsSettings.orbitaVersion.visible,
      className: 'draggable',
    }];

    const columns = columnsOrder.map((colName: string) => columnsList.find((col: any) => col.colName === colName)).filter(Boolean);

    this.state = {
      cookiesListModalVisible: false,
      modalVisiblee: false,
      modalVisibleSharing: false,
      sharingProfile: null,
      // eslint-disable-next-line react/no-unused-state
      windowWidth: 0,
      selectedFolder,
      idCookiesList: '',
      nameCookiesList: '',
      isProfileHistoryModalOpened: false,
      profileHistoryModalProfileId: '',
      updateProfilesModal: false,
      isBrowserVersionCompatible: true,
      isOrbitaUpdateModalVisible: false,
      isOrbitaIncompatibleCannotRunModalVisible: false,
      areOrbitaModalButtonsDisabled: false,
      updateProfileModalProfileId: '',
      isResizeMode: false,
      columnsSettings,
      columns,
    };
  }

  // eslint-disable-next-line react/sort-comp
  protected isElectron: boolean;
  protected profileNameRef: any;
  state: any;

  handleResize = (): void => this.setState({
    // eslint-disable-next-line react/no-unused-state
    windowWidth: window.innerWidth,
  });

  changeSelectedFolder = async (selectedFolder: string): Promise<void> => {
    localStorage.setItem('SelectedFolder', selectedFolder);
    this.setState({ selectedFolder });
    this.props.onFolderChange(selectedFolder);
  };

  updateProfilesRunningInWeb = async (): Promise<void> => {
    const flatProfileRunStatuses = getFlatProfileRunStatuses();
    const profilesRunningInWeb = await updateRunStatus({
      profiles: flatProfileRunStatuses,
      isNewRoute: true,
      isPinging: true,
      shouldSendAnalytics: false,
    });

    updateProfileRunStatusesForWeb(profilesRunningInWeb);
  };

  onUpdateProfileStatus = (_: unknown, args: IUpdateProfileStatusEvent): void => {
    const { profileId, status, message: statusMessage, proxyId } = args;

    if (proxyId) {
      syncProxyTooltipWithProfileStatus({
        profileId, statusMessage, proxyId,
      });
    }

    const performanceObserverService = PerformanceObserverService.getInstance();
    const profileStatusSpan = performanceObserverService.createProfileRunSpan(profileId, status);

    const isProfileLoadingFinished = ['profileStatuses.ready', 'profileStatuses.error', 'profileStatuses.running'].includes(status);
    if (!isProfileLoadingFinished) {
      updateProfileStatus(args);
      profileStatusSpan.finish();

      return;
    }

    if (status !== 'profileStatuses.running') {
      updateProfileStatus(args);
      profileStatusSpan.finish();

      return;
    }

    updateProfileStateAtFinish(profileId, status);

    profileStatusSpan.finish();
    performanceObserverService.finishProfileRunTransaction(profileId);
  };

  componentDidMount = async (): Promise<void> => {
    if (this.isElectron && ipcRenderer) {
      ipcRenderer.on('update-profile-status', this.onUpdateProfileStatus);

      const urlCurrent = new URL(location.href.replace('#', ''));
      const profileIdOrName = urlCurrent.searchParams.get('profile-id-or-name');
      const deepLinkProtocol = urlCurrent.searchParams.get('protocol') === 'http' ? 'http' : 'custom';
      if (profileIdOrName) {
        this.handleDeepLinkRun(null, { profileIdOrName, deepLinkProtocol });
      }

      ipcRenderer.on(PROFILE_RUN_DEEP_LINK_EVENT_NAME, async (event, args) => {
        await this.handleDeepLinkRun(event, args);
      });
    } else {
      this.updateProfilesRunningInWeb();
      profileStatusUpdateIntervalId = setInterval(this.updateProfilesRunningInWeb, PROFILES_STATUS_UPDATE_TIMING);
    }

    this.normalizeData();
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  };

  componentDidUpdate(prevProps: any): void {
    if (this.profileNameRef.current !== null) {
      const noteElement = document.getElementById('profileNameId');
      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart((noteElement as any).childNodes[0], 0);
      range.setEnd((noteElement as any).childNodes[0], this.profileNameRef.current.innerText.length);
      (sel as any).removeAllRanges();
      (sel as any).addRange(range);
    }
  }

  componentWillUnmount = (): void => {
    if (!isElectron) {
      clearInterval(profileStatusUpdateIntervalId);
    }

    window.removeEventListener('resize', this.handleResize);
    if (!ipcRenderer) {
      return;
    }

    ipcRenderer.removeAllListeners('update-profile-status');
    ipcRenderer.removeAllListeners(PROFILE_RUN_DEEP_LINK_EVENT_NAME);
  };

  private updateProfilesTableSettingsState = (columns: IProfilesTableColumn[]): IProfilesTableSettings => {
    const columnsOrder: string[] = [];
    const columnsSettings: IProfilesTableColumn[] = columns.map((column: IProfilesTableColumn) => {
      if (column.colName) {
        columnsOrder.push(column.colName);
      }

      return {
        title: column.colName,
        width: column.width,
        minWidth: column.minWidth,
        visible: column.visible,
      };
    });

    const res: IProfilesTableSettings = {
      columnsSettings,
      columnsOrder,
    };

    this.props.setProfilesTableSettings(res);

    return res;
  };

  private handleResizeCol = (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData): void => {
      const newColumns = [...this.state.columns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
      };

      this.setState({
        columns: newColumns,
      });
    };

  private handleResizeColumn: IResizeProps['onResize'] = (columnIndex, _, data) => {
    const newColumns = [...this.state.columns];
    newColumns[columnIndex] = {
      ...newColumns[columnIndex],
      width: data.size.width,
    };

    this.setState({
      columns: newColumns,
    });
  };

  private changeProfileNames = (newProfileData: INewNameProfile[]): void => {
    mapAndSetProfilesList(profiles => profiles.map(profile => {
      const currentProfile = newProfileData.find(({ profileId }) => profileId === profile.id);
      if (!currentProfile) {
        return profile;
      }

      return {
        ...profile,
        name: currentProfile.name || '',
      };
    }));
  };

  private changeProfilesData = (profileIds: string[], newData: Partial<IProfile>): void => {
    mapAndSetProfilesList(profiles => profiles.map(profile => {
      if (!profileIds.includes(profile.id)) {
        return profile;
      }

      return {
        ...profile,
        ...newData,
      };
    }));
  };

  private normalizeData = (): void => {
    mapAndSetProfilesList(profiles => normalizeProfilesData(profiles));
    this.checkProfilesStatus();
  };

  checkProfilesStatus = (): Promise<void> => isElectron && ipcRenderer.invoke('check-profiles');

  navigateToUpdatePage = (profileId: string): void => {
    const tableProfileIdx = getBasicTableEntityById(profileId)?.idx;
    const el = document.querySelector(`[data-row-key='${tableProfileIdx}']`) as HTMLElement | null;
    if (!el) {
      return;
    }

    const tableY = 224;
    const rect = el.getBoundingClientRect();
    const profileToScrollToY = el.offsetTop - (rect.top + window.scrollY) + tableY;

    sessionStorage.setItem('profileToScrollY', `${profileToScrollToY}`);

    history.push(`/update/${profileId}`);
  };

  private checkboxColumnStatus = (checkbox: ProfilesTableColumn, _columnName: string): JSX.Element | null => {
    const column = this.state.columns.find((col: IProfilesTableColumn) => col.colName === checkbox);

    if (!column) {
      return null;
    }

    return (
      <RowNameFolder
        style={{ cursor: 'pointer' }}
        onClick={(): Promise<void> => this.checkState(column)}
      >
        <TextNameFolder>
          {column.label}
        </TextNameFolder>
        <MarginIcon >
          <GologinCheckbox
            onChange={() => null}
            checked={column.visible}
          />
        </MarginIcon>
      </RowNameFolder>
    );
  };

  renderSharing: RenderTableCellFn = (_, profile) => (
    <SharingCell profileAtom={profile.atom} openShareModal={this.openShareModal} />
  );

  // LOGIC //

  updateProfilesList = (): void => {
    this.changeSelectedFolder(this.state.selectedFolder);
  };

  private getCurrentStatus = (profileId: string): boolean => {
    const profileRunStatus = getProfileRunStatus(profileId);

    return ['profileStatuses.ready', 'profileStatuses.error'].includes(profileRunStatus.status);
  };

  updateProfileFingerprints = (updatedProfiles: IProfile[]): void => {
    mapAndSetProfilesList(profiles => profiles.map((profile) => {
      const updatedProfile = updatedProfiles.find(pp => profile.id === pp.id);

      if (!updatedProfile) {
        return profile;
      }

      return {
        ...profile,
        ...updatedProfile,
        navigator: {
          ...profile.navigator,
          ...updatedProfile.navigator,
        },
      };
    }));
  };

  private shouldLaunchAfterVersionCheck = async (options: ILaunchProfileOrbita, transaction: ITransactionObject): Promise<boolean> => {
    const span = transaction.startChild({ op: 'check-browser-version-before-launch' });
    const { profile } = options;
    const { versionToDownload, pickedVersionIfSkipDownload } =
      await ipcRenderer.invoke('check-supported-orbita', { key: 'userAgent', value: profile.navigator.userAgent })
        .catch(() => ({ versionToDownload: null, pickedVersionIfSkipDownload: null }));

    const isBrowserVersionCompatible = await this.props.isOrbitaVersionCompatible(versionToDownload).catch(() => true);
    if (!versionToDownload) {
      span.finish();

      return true;
    }

    const stateIfCompatible = {
      versionToUpdate: versionToDownload,
      launchProfileOpts: { ...options, skipOrbitaVersionCheck: true, pickedVersionIfSkipDownload },
      isOrbitaUpdateModalVisible: true,
    };

    const stateIfIncompatible = { isOrbitaIncompatibleCannotRunModalVisible: true, launchProfileOpts: { pickedVersionIfSkipDownload } };
    const stateToUpdate = this.state.isBrowserVersionCompatible === isBrowserVersionCompatible ? stateIfCompatible : stateIfIncompatible;
    this.setState(stateToUpdate);
    span.finish();

    return false;
  };

  private launchProfileOrbita = async (options: ILaunchProfileOrbita): Promise<void | null> => {
    const {
      profile,
      multipleLaunch = false,
      updateStatusToSync = true,
      skipOrbitaVersionCheck = false,
      automation,
      iconsStartData,
      deepLinkProtocol,
    } = options;

    const { id: profileId, proxy } = profile;
    const { id: proxyId = '' } = proxy || {};

    const { status = false, checkDate } = getProxyListProxyById(proxyId) || {};

    const performanceObserverService = PerformanceObserverService.getInstance();
    const transaction = performanceObserverService.createProfileRunTransaction(profileId);

    const listedProxy = getProxyListProxyById(proxyId);
    let proxyToRunInProfile: BrowserStartData['proxyInfo'] = { ...proxy, port: +proxy.port };
    if (listedProxy) {
      proxyToRunInProfile = {
        ...proxyToRunInProfile,
        ...listedProxy,
        port: +listedProxy.port,
      };
    }

    const isReady = this.getCurrentStatus(profileId);

    // need to check `isLinkRun`, since `isReady` does not show the relevant status for profiles, that are not loaded in the table
    if (!(deepLinkProtocol || isReady)) {
      return;
    }

    if (!skipOrbitaVersionCheck) {
      const browserIconsData = getParamsToGenerateIcons(profile);
      const [shouldLaunch, iconsPngs] = await Promise.all([
        this.shouldLaunchAfterVersionCheck(options, transaction),
        generateBrowserIconsPromise(browserIconsData, transaction),
      ]);

      saveIconsToSessionStorage(profileId, iconsPngs, browserIconsData.iconDesignVersion);
      if (!shouldLaunch) {
        transaction.finish();

        return null;
      }
    }

    const updateRunStatusSpan = transaction.startChild({ op: 'ui', description: 'update-run-status' });
    const finalUpdateStatusToSync = !deepLinkProtocol && updateStatusToSync;
    updateProfileStateAtLaunch(profile, finalUpdateStatusToSync);
    updateRunStatusSpan?.finish();

    const { archivedProxy } = profile;
    if (getIsProxyArchived(archivedProxy)) {
      await restoreProxy(archivedProxy);
    }

    const iconsData = iconsStartData || getIconsFromSessionStorage(profileId);
    const startData: BrowserStartData = {
      profileId,
      proxyInfo: proxyToRunInProfile,
      proxyEnabled: profile.proxyEnabled,
      automation,
      icons: iconsData,
      traceId: transaction.traceId || '',
      deepLinkProtocol,
      lastProxyCheckStatus: {
        id: proxyId,
        status,
        checkDate,
      },
    };

    removeIconsFromSessionStorage(profileId, iconsData.pngs.length >= 1);

    const loadingResult = ipcRenderer && await ipcRenderer.invoke('start-profile', startData);
    if (loadingResult === 'cantBeRunning' && !multipleLaunch) {
      message.error('Profile cannot be running. Other users are using this profile now.');
      this.checkProfilesStatus();
      performanceObserverService.finishProfileRunTransaction(profileId);
    }
  };

  handleDeepLinkRun = async (
    _event: Electron.IpcRendererEvent|null,
    args: { profileIdOrName: string; deepLinkProtocol: DeepLinkProtocol },
  ): Promise<void> => {
    const { profileIdOrName = '', deepLinkProtocol = 'custom' } = args;
    let workspaceId = '';
    try {
      const workspaceInfoJson = localStorage.getItem('workspace') || '';
      const workspaceInfo = JSON.parse(workspaceInfoJson);
      workspaceId = workspaceInfo.id;
    } catch {
      return;
    }

    if (!(profileIdOrName && workspaceId)) {
      const transactionName = 'profile-or-wid-in-open-link-is-falsy';
      Sentry.captureException(new ReactError(transactionName.replaceAll('-', ' ')), (scope) => {
        scope.setLevel('error');
        scope.setTransactionName(transactionName);
        scope.setFingerprint([transactionName]);

        return scope;
      });

      return;
    }

    const profile = await getProfileForLink({
      workspaceId,
      profileIdOrName,
      requestProfileFingerprint: requestFullProfileInfo,
      requestProfiles,
    });

    if (!profile) {
      const transactionName = 'profile-in-open-link-not-found';
      Sentry.captureException(new ReactError(transactionName.replaceAll('-', ' ')), (scope) => {
        scope.setLevel('error');
        scope.setTransactionName(transactionName);
        scope.setFingerprint([transactionName]);

        return scope;
      });

      return message.error(this.props.t('tableProfiles.notification.profileInUrlNotFound'));
    }

    await this.launchProfileOrbita({ profile, deepLinkProtocol }).catch(() => null);
  };

  closeModall = (): void => {
    this.setState({ modalVisiblee: false });
  };

  cloneProfile = async (profileId: string): Promise<void> => {
    sendActionAnalytics('clicked clone profile');

    const { selectedFolder } = this.state;
    const { lastWorkspaceId } = this.props;

    const folder = (!NEW_FEATURES.header && selectedFolder === 'all') ? '' : selectedFolder;
    if (NEW_FEATURES.workspaces) {
      await cloneWorkspaceProfiles(lastWorkspaceId, [profileId]);
    } else {
      await cloneProfile(profileId, folder);
    }

    resetProfilesList();
    this.updateProfilesList();
  };

  modifyProfilePin = async (profileId: string, setTo: PinAction): Promise<void> => {
    const pinOptions: IPinParams = { profileId };
    if (NEW_FEATURES.workspaces) {
      pinOptions.workspaceId = this.props.lastWorkspaceId;
    }

    const selectedFolderName = NEW_FEATURES.header ?
      this.props.selectedFolderFromCtx :
      this.state.selectedFolder;

    if (selectedFolderName && selectedFolderName !== 'all') {
      const selectedFolder = this.props.foldersList
        .find(folder => folder?.name === selectedFolderName);

      if (!selectedFolder) {
        localStorage.removeItem('SelectedFolder');
      }

      pinOptions.folderId = selectedFolder?.id;
    }

    if (!pinOptions.folderId && NEW_FEATURES.dragAndDrop) {
      pinOptions.folderId = this.props.allProfilesFolderId;
    }

    if (setTo === 'pinned') {
      await pin(pinOptions);
    } else {
      await unpin(pinOptions);
    }

    resetProfilesList();
    this.updateProfilesList();
  };

  pinProfile = (profileId: string): Promise<void> =>
    this.modifyProfilePin(profileId, 'pinned');

  unpinProfile = (profileId: string): Promise<void> =>
    this.modifyProfilePin(profileId, 'unpinned');

  toggleModalSharingOld = (state = false): void => this.setState({ modalVisibleSharing: state });

  toggleModalSharing = (state = false, rowInfo: IProfile): void => {
    if (state) {
      openProfileTableModal(SHARE_MODAL_NAME, [rowInfo.id]);
    } else {
      closeProfileTableModal();
    }
  };

  // COMPONENTS

  private syncProfile = (profileId: string, onlyUpload?: boolean): void => {
    ipcRenderer && ipcRenderer.invoke('retry-sync-profile', { profileId, onlyUpload });
  };

  private renderState: RenderTableCellFn = (_, profile) => {
    const statusColData = (this.state.columns || []).find((col: IProfilesTableColumn) => col.colName === 'status');
    const { width = 0 } = statusColData;
    const statusTextWidth = width ? width : this.state.columnsSettings.status.width;

    return (
      <StatusCell
        profileAtom={profile.atom}
        syncProfile={this.syncProfile}
        statusTextWidth={statusTextWidth}
      />
    );
  };

  private renderDate = (profile: IBasicTableProfile, field: DateCellField): JSX.Element => (
    <DateCell
      profileAtom={profile.atom}
      field={field}
      hasTooltip={field === 'lastActivity'}
    />
  );

  private renderProxy: RenderTableCellFn = (_, profile) => <ProxyCell profileAtom={profile.atom} rowIdx={profile.idx} />;

  private renderProxyParam = (profile: IBasicTableProfile, paramName: keyof IProxy): JSX.Element => (
    <ProxyParamCell
      profileAtom={profile.atom}
      paramName={paramName}
    />
  );

  modalSharingVisibleOn = (rowInfo: IProfile): void => {
    if (!this.isNewShareProfileModal) {
      return this.setState({ modalVisibleSharing: true });
    }

    openProfileTableModal(SHARE_MODAL_NAME, [rowInfo.id]);
  };

  private renderProxyType: RenderTableCellFn = (_, profile) => <ProxyTypeColumn profileAtom={profile.atom} />;
  private renderOs: RenderTableCellFn = (_, profile) => <OsCell profileAtom={profile.atom} />;
  private renderOrbitaVersion: RenderTableCellFn = (_, profile) => <OrbitaVersionCell profileAtom={profile.atom} />;

  // TODO: move to tags file
  private onTagsPopupOpen = (profileTagsCount: number, totalTagsCount: number): void => {
    const performanceObserverService = PerformanceObserverService.getInstance();
    performanceObserverService.handleUserAction({
      userAction: 'open-tags-popup',
      profileTagsCount,
      totalTagsCount,
    });
  };

  private renderCustomStatus: RenderTableCellFn = (_, profile) => (
    <CustomStatusCell
      profileAtom={profile.atom}
      rowIdx={profile.idx}
      addTag={this.props.addTag}
      updateTag={this.props.updateTag}
      removeProfileTag={this.props.removeProfileTag}
      removeTag={this.props.removeTag}
      onTagsPopupOpen={this.onTagsPopupOpen}
    />
  );

  private renderTags: RenderTableCellFn = (_, profile) => (
    <TagsCell
      profileAtom={profile.atom}
      rowIdx={profile.idx}
      addTag={this.props.addTag}
      updateTag={this.props.updateTag}
      removeProfileTag={this.props.removeProfileTag}
      removeTag={this.props.removeTag}
      onTagsPopupOpen={this.onTagsPopupOpen}
      field='tags'
    />
  );

  private renderNotes: RenderTableCellFn = (_, profile) => (
    <NotesCell
      profileAtom={profile.atom}
      saveProfileNotes={this.saveProfileNotes}
    />
  );

  private toggleCookiesListModal = (options: ICookiesListModal): void => {
    const { visible, id, name } = options;
    this.setState({
      cookiesListModalVisible: visible,
      idCookiesList: id,
      nameCookiesList: name,
    });
  };

  // TODO: move to separate file
  private copyProxy = async (profileId: string): Promise<void> => {
    const profileFingerprint = await requestFullProfileInfo(profileId);
    const { proxy } = profileFingerprint;
    const { host, port, username, password } = proxy;

    let textClipboard = '';

    if (host !== '') {
      textClipboard = host + ':' + port;
    }

    if (username !== '') {
      textClipboard = username + '@' + host + ':' + port;
    }

    if (password !== '') {
      textClipboard = username + ':' + password + '@' + host + ':' + port;
    }

    await navigator.clipboard.writeText(textClipboard)
      .then(() => {
        message.success(this.props.t('base.copiedText'));
      })
      .catch((error) => {
        message.error(this.props.t('notifications.error.somethingWentWrong'));

        const errorMessage = error instanceof Error ? error.message : 'unknown';
        sendReactErrorToSentry({ message: errorMessage, transactionName: 'copy-proxy-as-text-to-buffer' });
      });
  };

  private showCopyProxy = (rowInfo: IProfile): JSX.Element | null => {
    if (!(rowInfo?.proxy?.mode && rowInfo.proxy.mode !== 'none')) {
      return null;
    }

    return (
      <Menu.Item
        onClick={(): Promise<void> => this.copyProxy(rowInfo.id)}
      >
        {this.props.t('profiles.menu.copyProxy')}
      </Menu.Item>
    );
  };

  private updateProfileCookies = async (profileId: string): Promise<void> => {
    const newCookiesList = await requestShortCookies(profileId);
    mapAndSetProfilesList(profiles => profiles.map((profile) => {
      if (profile.id !== profileId) {
        return profile;
      }

      return {
        ...profile,
        cookies: newCookiesList,
      };
    }));
  };

  private getCookiesList = (): JSX.Element => {
    const { idCookiesList: id, nameCookiesList: name, cookiesListModalVisible } = this.state;

    return (
      <CookiesList
        id={id}
        name={name}
        cookiesListModalVisible={cookiesListModalVisible}
        toggleCookiesListModal={this.toggleCookiesListModal}
        onCookiesRequest={(): Promise<void> => this.updateProfileCookies(id)}
      />
    );
  };

  private showManageFoldersMenu = (profileId: string): JSX.Element | null => {
    const loadedProfilesCount = this.props.basicTableEntities.length;
    if (!loadedProfilesCount) {
      return null;
    }

    const openFoldersModal = (): void => {
      const performanceObserverService = PerformanceObserverService.getInstance();
      performanceObserverService.handleUserAction({ userAction: 'open-folders-modal' });
      this.props.openFoldersModal([profileId]);
    };

    return (
      <Menu.Item onClick={openFoldersModal}>
        {this.props.t('folders.title')}
      </Menu.Item>
    );
  };

  checkState = async (column: IProfilesTableColumn): Promise<void> => {
    const newColumns = this.state.columns.map((col: IProfilesTableColumn) => {
      if (col.colName !== column.colName) {
        return col;
      }

      const { sortField, visible } = col;
      const currSortField = getProfilesSortField();
      if (currSortField === sortField && visible) {
        resetProfilesTableSorting();
      }

      return {
        ...col,
        visible: !visible,
      };
    });

    this.setState({
      columns: newColumns,
    });

    const { columnsSettings } = this.updateProfilesTableSettingsState(newColumns);
    if (NEW_FEATURES.workspaces) {
      const {
        lastWorkspaceId,
      } = this.props;

      await saveWorkspaceProfileTableColumns(lastWorkspaceId, columnsSettings);
    } else {
      await saveProfileTableColumns(columnsSettings);
    }
  };

  saveLocalStorageValue = (nameTable: any, value: any, nameState: string): void => {
    Boolean(value);
    this.setState({
      [nameState]: value,
    });

    localStorage.setItem(`${nameTable}`, `${value}`);
    localStorage.setItem('profilesTableColumnsHasBeenEditing', 'true');
  };

  openHistoryModal = (id: string): void => {
    this.setState({
      isProfileHistoryModalOpened: true,
      profileHistoryModalProfileId: id,
    });
  };

  renderPinButtons = (profile: IProfile): JSX.Element => {
    if (profile.isPinned) {
      return (
        <Menu.Item onClick={(): Promise<void> => this.unpinProfile(profile?.id)}>
          {this.props.t('profiles.menu.unpin')}
        </Menu.Item>
      );
    }

    return (
      <Menu.Item onClick={(): Promise<void> => this.pinProfile(profile?.id)}>
        {this.props.t('profiles.menu.pin')}
      </Menu.Item>
    );
  };

  renderAppMenu = (rowInfo: any): JSX.Element => (
    <Menu.Item onClick={(): Promise<void> => launchProfileWeb(rowInfo)}>
      {this.props.t('profiles.menu.cloudRun')}
    </Menu.Item>
  );

  private renderUpdateProfileButton = (rowInfo: any): JSX.Element | null => {
    if (!rowInfo.suggestUpdateUA) {
      return null;
    }

    return (
      <Menu.Item
        onClick={(): void => this.openUpdateProfileModal(rowInfo.id)}
      >
        {this.props.t('base.update')}
      </Menu.Item>
    );
  };

  openShareModal = (profile: IProfile): void => {
    if (!this.isNewShareProfileModal) {
      this.toggleModalSharingOld(true);

      return this.setState({ sharingProfile: profile });
    }

    this.toggleModalSharing(true, profile);
  };

  renderMenu = (rowInfo: IProfile & IProfileRunStatus): JSX.Element => (
    <Menu mode='vertical'>
      {this.renderPinButtons(rowInfo)}
      <Menu.Item onClick={(): void => this.navigateToUpdatePage(rowInfo?.id)}>
        {this.props.t('profiles.menu.editProfile')}
      </Menu.Item>
      <Menu.Item onClick={(): Promise<void> => this.cloneProfile(rowInfo.id)}>
        {this.props.t('profiles.menu.clone')}
      </Menu.Item>
      <Menu.Item onClick={(): void => this.openShareModal(rowInfo)}>
        {this.props.t('profiles.menu.sharing')}
      </Menu.Item>
      {NEW_FEATURES.workspaces ? (
        <Menu.Item
          onClick={(): void => {
            this.openTransferProfileModal(rowInfo.id);
          }}
          disabled={!rowInfo.permissions?.transferProfile}
        >
          {this.props.t('tableProfiles.menu.transfer')}
        </Menu.Item>
      ) : null}
      {this.showManageFoldersMenu(rowInfo.id)}
      {/* {this.isElectron ? (
        <Menu.Item>
          <AutomationButtonText profileSelected={rowInfo} />
        </Menu.Item>
      ) : null} */}
      <Menu.Divider />
      {this.isElectron ? this.renderAppMenu(rowInfo) : null}
      <Menu.Item
        onClick={(): void => this.toggleCookiesListModal({ visible: true, id: rowInfo.id, name: rowInfo.name })}
      >
        {this.props.t('base.cookies')}
      </Menu.Item>
      <Menu.Item
        onClick={(): void => this.openHistoryModal(rowInfo.id)}
      >
        {this.props.t('profiles.menu.history')}
      </Menu.Item>
      {this.showCopyProxy(rowInfo)}
      {this.renderUpdateProfileButton(rowInfo)}
      <Menu.Divider />
      <ReactCopyToClipboard text={rowInfo?.id} onCopy={(): any => message.success(this.props.t('base.copiedText'))}>
        <Menu.Item>
          {this.props.t('profiles.menu.copyProfileId')}
        </Menu.Item>
      </ReactCopyToClipboard>
      <ReactCopyToClipboard
        text={`${DEEP_LINKS_REDIRECT_ORIGIN}/${encodeURIComponent(rowInfo?.name)}`}
        onCopy={(): any => message.success(this.props.t('base.copiedText'))}
      >
        <Menu.Item>
          {this.props.t('tableProfiles.contextMenu.openLinkCopy')}
        </Menu.Item>
      </ReactCopyToClipboard>
      <Menu.Divider />
      {this.showButtonDelete(rowInfo)}
    </Menu>
  );

  showButtonDelete = (rowInfo: IProfile & IProfileRunStatus): JSX.Element => {
    const { status = '' } = rowInfo;
    const isDelete = calculateProfileDeleteOrLeave(rowInfo) === 'delete';
    if (isDelete) {
      // Если профиль может быть запущен, и его статус 'error' или 'ready'
      if (rowInfo.canBeRunning && ['profileStatuses.error', 'profileStatuses.ready'].includes(status)) {
        // Даем возможность удалить профиль
        return (
          <Menu.Item onClick={(): void => openProfileTableModal('delete-or-leave-profile', [rowInfo.id])}>
            {this.props.t('base.delete')}
          </Menu.Item>
        );
      }

      return (
        <Menu.Item disabled={true}>
          {this.props.t('base.delete')}
        </Menu.Item>
      );
    }

    // Если профиль принадлежит другому пользователю
    if (['profileStatuses.error', 'profileStatuses.ready'].includes(status)) {
      // Его статус не 'запущен' и не 'синхронизация'
      return (
        <Menu.Item onClick={(): void => openProfileTableModal('delete-or-leave-profile', [rowInfo.id])}>
          {this.props.t('base.leave')}
        </Menu.Item>
      );
    }

    return (
      <Menu.Item disabled={true}>
        {this.props.t('base.leave')}
      </Menu.Item>
    );
  };

  renderDropDownMenu = (rowInfo: IProfile & IProfileRunStatus, isInDrawer?: boolean): JSX.Element => {
    const openDeleteOrLeaveProfileTableModal = (rowInfoId: string): void => {
      openProfileTableModal('delete-or-leave-profile', [rowInfoId]);
      sendActionAnalytics(E_ANALYTICS_ACTIONS.clickedDeleteProfileProfileMenu, { profile: rowInfoId });
    };

    if (NEW_FEATURES.header) {
      return (
        <ProfileDropdownMenu
          profile={rowInfo}
          selectedFolderName={NEW_FEATURES.header ? this.props.selectedFolderFromCtx : this.state.selectedFolder}
          foldersList={this.props.foldersList}
          openShareModal={(): void => this.openShareModal(rowInfo)}
          openTransferModal={(): void => this.openTransferProfileModal(rowInfo.id)}
          openFoldersModal={(): void => this.props.openFoldersModal([rowInfo.id])}
          openCookiesModal={(): void => this.toggleCookiesListModal({ visible: true, id: rowInfo.id, name: rowInfo.name })}
          openHistoryModal={(): void => this.openHistoryModal(rowInfo.id)}
          openUpdateProfileModal={(): void => this.openUpdateProfileModal(rowInfo.id)}
          openDeleteActionModal={(): void => openDeleteOrLeaveProfileTableModal(rowInfo.id)}
          navigateToUpdatePage={(): void => this.navigateToUpdatePage(rowInfo.id)}
          isTriggerAlwaysShown={isInDrawer}
          modifyProfilePin={(setTo: PinAction): Promise<void> => this.modifyProfilePin(rowInfo.id, setTo)}
        />
      );
    }

    return (
      <Dropdown
        overlay={(): JSX.Element => this.renderMenu(rowInfo)}
        placement='bottomCenter'
      >
        <DrawerDotsButton style={{ marginLeft: 10, marginBottom: 0, cursor: 'pointer' }}>
          <IconDotsInButton />
        </DrawerDotsButton>
      </Dropdown>
    );
  };

  refreshPageByTimeout = (time: number, cb: () => void): void => {
    setTimeout(() => {
      this.updateProfilesList();
      cb();
    }, time);
  };

  fetchMoreProfiles = async (): Promise<void> => {
    if (!this.state.isResizeMode) {
      paginateProfilesQuery();
    }
  };

  resizeStart = (): void => {
    this.setState({
      isResizeMode: true,
    });
  };

  resizeEnd = (): void => {
    setTimeout(async () => {
      this.setState({
        isResizeMode: false,
      });

      const { columnsSettings } = this.updateProfilesTableSettingsState(this.state.columns);

      if (NEW_FEATURES.workspaces) {
        const {
          lastWorkspaceId,
        } = this.props;

        await saveWorkspaceProfileTableColumns(lastWorkspaceId, columnsSettings);
      } else {
        await saveProfileTableColumns(columnsSettings);
      }
    });
  };

  closeUpdateProfileModal = (): void => {
    this.setState({
      updateProfilesModal: false,
      updateProfileModalProfileId: '',
    });
  };

  private handleOrbitaUpdateModalClose = (): void => this.setState({
    isOrbitaUpdateModalVisible: false,
    versionToUpdate: '',
    launchProfileOpts: null,
    areOrbitaModalButtonsDisabled: false,
  });

  private handleOrbitaUpdateModalClick = (): void =>  this.setState({
    isOrbitaUpdateModalVisible: false,
    areOrbitaModalButtonsDisabled: true,
  });

  private handleOrbitaUpdateModalCancel = async (): Promise<void> => {
    this.handleOrbitaUpdateModalClick();
    this.setState({ areOrbitaModalButtonsDisabled: false });
    await this.launchProfileOrbita(this.state.launchProfileOpts).catch(() => null);
  };

  private handleOrbitaUpdateModalSubmit = async (): Promise<void> => {
    this.handleOrbitaUpdateModalClick();
    await this.props.downloadSpecificOrbitaVersion(this.state.versionToUpdate).catch(() => null);
  };

  private handleOrbitaUpdateModalAfterClose = (): void => this.setState({ areOrbitaModalButtonsDisabled: false });

  openUpdateProfileModal = (profileId: string): void => {
    this.setState({
      updateProfileModalProfileId: profileId,
      updateProfilesModal: true,
    });
  };

  openTransferProfileModal = (profileId: string): void => {
    this.setState({
      transferModalProfileId: profileId,
      transferProfileModal: true,
    });
  };

  closeTransferProfileModal = (): void => {
    this.setState({
      transferProfileModal: false,
      transferModalProfileId: '',
    });
  };

  doTransferProfile = async ({
    targetWorkspaceId,
    selectedFolder,
    email,
    transferType,
    workspace,
    profilesIds,
  }: ITransferParams): Promise<void> => {
    const transferIds: string[] = this.state.transferModalProfileId ? [this.state.transferModalProfileId] : profilesIds || [];
    const profilesCanTransfer = filterProfilesByPermission(transferIds, 'transferProfile');
    const profileIds = profilesCanTransfer.map(profile => profile.id);
    let transferredTo;

    const { t: translation } = this.props;

    const MAX_ENTRY_LENGTH = 12;

    if (transferType === 'transfer') {
      await transferProfile({ workspaceId: workspace.id, profileIds: transferIds, email });
      transferredTo = email.length > MAX_ENTRY_LENGTH ? email.slice(0, MAX_ENTRY_LENGTH) + '...' : email;
    }

    if (transferType === 'transferToMyWorkspace') {
      await transferToMyWorkspace({
        fromWorkspaceId: workspace.id,
        folderName: selectedFolder,
        targetWorkspaceId,
        instanceIds: transferIds,
      });

      const workspaceData = workspace.availableWorkspaces.find(availableWorkspace => availableWorkspace.id === targetWorkspaceId);
      if (workspaceData) {
        const { name } = workspaceData;
        transferredTo = name.length > MAX_ENTRY_LENGTH ? name.slice(0, MAX_ENTRY_LENGTH) + '...' : name;
      }
    }

    const youTransferred = translation('transferModal.successMessage.youTransferred');
    const profilesText = getProfileNamesForNotifications(profileIds, translation);
    const toText = translation('transferModal.successMessage.to');
    message.success(
      `${youTransferred} ${profilesText} ${toText} ${transferredTo}`,
    );

    const isTransferToSameWorkspace = transferType === 'transferToMyWorkspace' && workspace.id === targetWorkspaceId;
    const shouldProfilesRemain = isTransferToSameWorkspace && ['', selectedFolder].includes(this.state.selectedFolder);
    if (!shouldProfilesRemain) {
      this.removeProfilesFromList(profileIds);
      const selectedProfileIds = getProfilesTableSelectedIds();
      const newSelectedProfiles = selectedProfileIds.filter((id: string) => !profileIds.includes(id));
      updateProfilesTableSelectedIds(newSelectedProfiles);
    } else {
      this.replaceProfileFolders(profileIds, [selectedFolder]);
    }

    this.closeTransferProfileModal();
  };

  // TODO: move
  removeProfilesFromList = (profileIds: string[]): void => {
    mapAndSetProfilesList(profiles => profiles.filter(profile => !profileIds.includes(profile.id)));
  };

  // TODO: move
  replaceProfileFolders = (profileIds: string[], newFolders: string[]): void => {
    mapAndSetProfilesList(profiles => profiles.map(profile => profileIds.includes(profile.id) ?
      { ...profile, folders: newFolders } :
      profile,
    ));
  };

  afterModalClose = (): void => {
    if (document.getElementsByTagName('body')[0].hasAttribute('style')) {
      document.getElementsByTagName('body')[0].removeAttribute('style');
    }
  };

  setTableDataChanges = (pagination: any, filters: IFilter, sorter: Sorter<string>): void => {
    if (!sorter.order) {
      resetProfilesTableSorting();

      return;
    }

    sortProfilesTable(sorter.field, sorter.order);
  };

  onSortChange: IGologinTableHeaderColumn['onSortChange'] = (newSortField, newSortOrder) => {
    this.setTableDataChanges(0, {}, { field: newSortField || '', order: newSortOrder });
  };

  // TODO: move
  saveProfileNotes = async (profileId: string, notes: string): Promise<void> => {
    await updateProfileNotes({
      id: profileId,
      notes,
    });

    mapAndSetProfilesList(profiles => profiles.map((profile) => {
      if (profile?.id === profileId) {
        return {
          ...profile,
          notes,
          defaultProps: {
            ...profile?.defaultProps,
            profileNotesIsDefault: false,
          },
        };
      }

      return profile;
    }));
  };

  getSelectedFolderName = (): string => {
    let selectedFolder = NEW_FEATURES.header ? this.props.selectedFolderFromCtx : localStorage.getItem('SelectedFolder');
    if ((!NEW_FEATURES.header || NEW_FEATURES.workspaces) && selectedFolder === 'all') {
      selectedFolder = '';
    }

    return selectedFolder || '';
  };

  quickCreateProfile = async (templateCtx: ITemplatesCtx): Promise<void> => {
    const selectedFolderName = this.getSelectedFolderName();
    const opts: ICreateQuickProfileOpts = {
      workspaceId: this.props.lastWorkspaceId,
      templateCtx,
      folderName: selectedFolderName,
    };

    const groupField = getProfilesTableGroupField();
    const [groupHeader] = getProfilesTableGroupHeaders();
    if (groupField && groupHeader) {
      const { filter: groupFilter } = groupHeader;
      switch (groupFilter.type) {
        case 'custom-status':
          opts.customStatusId = groupFilter.customStatusId;
          break;
        case 'folder': {
          const { folderId } = groupFilter;
          const { name: folderName } = getFolderById(folderId) || {};
          if (folderName) {
            opts.folderName = folderName;
          }

          break;
        }
        default:
          break;
      }
    }

    await createQuickProfile(opts);

    this.checkProfilesStatus();
  };

  dragProps = {
    onDragEnd: async (fromIndex: number, toIndex: number): Promise<void> => {
      if (toIndex < 0) {
        return;
      }

      const visibleColumns = this.state.columns.filter((col: IProfilesTableColumn) => col.visible);
      const fixedColumnsCount = NEW_FEATURES.header ? 0 : 1;
      const colToMove = visibleColumns[fromIndex + fixedColumnsCount];
      const nextCol = visibleColumns[toIndex + fixedColumnsCount];
      if (!nextCol || !colToMove) {
        return;
      }

      let newFromIndex = 0;
      let newToIndex = 0;
      for (let idx = 0; idx < this.state.columns.length; idx += 1) {
        const col = this.state.columns[idx];
        if (col.colName === colToMove.colName) {
          newFromIndex = idx;
        } else if (col.colName === nextCol.colName) {
          newToIndex = idx;
        }
      }

      const newColumns = [...this.state.columns];
      if ((!newFromIndex && newFromIndex !== 0) || (!newToIndex && newToIndex !== 0)) {
        return;
      }

      const [item] = newColumns.splice(newFromIndex, 1);
      newColumns.splice(newToIndex, 0, item);

      this.setState({
        columns: newColumns,
      });

      const { columnsOrder } = this.updateProfilesTableSettingsState(newColumns);
      if (NEW_FEATURES.workspaces) {
        const {
          lastWorkspaceId,
        } = this.props;

        await saveWorkspaceProfileTableColumnsOrder(lastWorkspaceId, columnsOrder);
      } else {
        await saveProfileTableColumnsOrder(columnsOrder);
      }
    },
    nodeSelector: 'th.draggable, .gologin-table-header-column.draggable',
    handleSelector: '.dragHandler',
    ignoreSelector: '.react-resizable-handle, .ant-table-selection-column, .no-drag',
    lineClassName: NEW_FEATURES.header ? 'new-global-drag-line' : 'global-drag-line',
  };

  getInitialScrollY = (): number | undefined => {
    const profileToScrollY = sessionStorage.getItem('profileToScrollY');
    if (!profileToScrollY) {
      return;
    }

    sessionStorage.removeItem('profileToScrollY');

    return +profileToScrollY;
  };

  isNewShareProfileModal = !!(NEW_FEATURES.workspaces && NEW_FEATURES.header);

  render(): JSX.Element {
    // reassemble columns list, because otherwise it is memoized with
    // incorrect local values, like props.browserUpdating
    // TODO: remove local props from render functions
    let columns = [...this.state.columns];
    if (!NEW_FEATURES.header) {
      columns = this.state.columns.map((col: IProfilesTableColumn, index: number) => ({
        ...col,
        originalColumnIndex: index,
        sorter: (col.sortField && !this.state.isResizeMode) ? (): 0 => 0 : false,
        sortOrder: col.sortField && this.props.sortField === col.sortField && this.props.sortOrder,
        onHeaderCell: (column: IProfilesTableColumn) => ({
          width: column.width,
          minWidth: column.minWidth,
          onResize: this.handleResizeCol(index),
          resizeStart: this.resizeStart,
          resizeEnd: this.resizeEnd,
        }),
      })).filter((col: IProfilesTableColumn) => col.visible);

      columns.push({
        render: () => null,
      });
    }

    let tableData = this.props.basicTableEntities;
    if (this.state.isResizeMode && this.props.basicTableEntities.length) {
      tableData = [this.props.basicTableEntities[0]];
    }

    const tableProfileIndexes = getBasicTableEntities().map(basicTableProfile => basicTableProfile.idx);

    return (
      <>
        <ProfileDrawer
          pinProfile={this.pinProfile}
          unpinProfile={this.unpinProfile}
          selectedFolder={this.state.selectedFolder}
          launchProfileOrbita={this.launchProfileOrbita}
          browserUpdating={this.props.browserUpdating}
          foldersList={this.props.foldersList}
          openFoldersModal={this.props.openFoldersModal}
          foldersSelected={this.props.foldersSelected}
          setFoldersSelected={this.props.setFoldersSelected}
          addProfilesToFolder={this.props.addProfilesToFolder}
          removeProfilesFromFolder={this.props.removeProfilesFromFolder}
          manageProfileFoldersList={this.props.manageProfileFoldersList}
          updateProfilesList={this.updateProfilesList}
          openHistoryModal={this.openHistoryModal}
          openUpdateProfileModal={this.openUpdateProfileModal}
          saveProfileNotes={this.saveProfileNotes}
          renderDropDownMenu={this.renderDropDownMenu}
          toggleCookiesListModal={this.toggleCookiesListModal}
          updateProfileFingerprints={this.updateProfileFingerprints}
          changeProfilesData={this.changeProfileNames}
          changeProfilesDataMany={this.changeProfilesData}
        />
        <ProfilesTabsContainer newStyle={!!NEW_FEATURES.header}>
          {NEW_FEATURES.header ? null : (
            <TabProfiles
              foldersList={this.props.foldersList}
              changeSelectedFolder={this.changeSelectedFolder}
              defaultActiveTabKey={this.state.selectedFolder}
            />
          )}

          {NEW_FEATURES.header ? null : (
            <ContainerNameCol
              style={{ cursor: 'pointer' }}
              onClick={(): void => {
                this.setState({ modalVisiblee: true });
                sendActionAnalytics('opened edit columns').catch(() => null);
              }}
            >
              <div style={{ marginRight: 4 }}>
                <IconSetting />
              </div>
            </ContainerNameCol>
          )}
        </ProfilesTabsContainer>
        {this.props.profilesQueryLoadingStatus === 'initing' ? (
          <div style={{ textAlign: 'center' }}>
            <IconSpinner size={32} padding={0} />
          </div>
        ) : (
          <ReactDragListView.DragColumn {...this.dragProps}>
            <SimpleSortableContext
              items={tableProfileIndexes}
            >
              <InfiniteScrollProfilesTable
                dataLength={this.props.basicTableEntities.length}
                next={this.fetchMoreProfiles}
                loader={<CustomTableLoader areColumnsResizing={this.state.isResizeMode} />}
                initialScrollY={this.getInitialScrollY()}
              >
                <div onClick={(event): void => event.stopPropagation()}>
                  <ConfigProvider>
                    <ProfilesMenu
                      refreshPageByTimeout={this.refreshPageByTimeout}
                      foldersList={this.props.foldersList}
                      openFoldersModal={this.props.openFoldersModal}
                      updateProfilesList={this.updateProfilesList}
                      launchProfileOrbita={this.launchProfileOrbita}
                      manageProfileFoldersList={this.props.manageProfileFoldersList}
                      selectedFolder={this.state.selectedFolder}
                      addTag={this.props.addTag}
                      updateTag={this.props.updateTag}
                      removeTag={this.props.removeTag}
                      removeProfileTag={this.props.removeProfileTag}
                      updateProfileFingerprints={this.updateProfileFingerprints}
                      isBrowserUpdating={this.props.browserUpdating}
                      versionLoadingNow={this.props.versionLoadingNow}
                      onTransfer={this.doTransferProfile}
                      changeProfilesData={this.changeProfilesData}
                      getIsNewShareProfileModal={this.getIsNewShareProfileModal}
                    />
                    <div style={{ position: 'relative' }} onClick={(event): void => event.stopPropagation()}>
                      {NEW_FEATURES.header ? (
                        <TableCustomizationMenu openColumnsModal={(): void => {
                          this.setState({ modalVisiblee: true });
                          sendActionAnalytics('opened edit columns').catch(() => null);
                        }} />
                      ) : null}
                      {NEW_FEATURES.header ? (
                        <GologinTable
                          dataArray={tableData}
                          columns={columns}
                          rowSelection={ROW_SELECTION}
                          onResize={this.handleResizeColumn}
                          resizeStart={this.resizeStart}
                          resizeEnd={this.resizeEnd}
                        />
                      ) : (
                        <TableProfile
                          newStyle={!!NEW_FEATURES.header}
                          isMinWidth={innerWidth <= 1090}
                          rowSelection={{
                            type: 'checkbox',
                            onChange: onUserChangeSelectedProfiles,
                            selectedRowKeys: this.props.selectedProfileRowKeys,
                            columnWidth: '34px',
                          }}
                          onRow={(record: any, rowIndex): any => ({
                            onClick: (): void => {
                              onProfilesTableRowClick(rowIndex);
                            },
                            onMouseLeave: (event: SyntheticEvent): void => {
                              if (event.currentTarget.classList.contains('check-in-progress')) {
                                return;
                              }

                              event.currentTarget.classList.remove('row-visible-dot');
                            },
                          })}
                          pagination={false}
                          rowKey='idx'
                          dataSource={tableData}
                          locale={{ emptyText: NEW_FEATURES.header ? null : <NoProfilesPlaceholder quickCreateProfile={this.quickCreateProfile} /> }}
                          onChange={this.setTableDataChanges}
                          className='profiles-table'
                          tableLayout='fixed'
                          components={{
                            header: {
                              cell: ResizableTitle,
                            },
                          }}
                          columns={columns}
                        />
                      )}
                    </div>
                  </ConfigProvider>
                </div>
              </InfiniteScrollProfilesTable>
            </SimpleSortableContext>
          </ReactDragListView.DragColumn>
        )}
        {(NEW_FEATURES.header && !this.props.basicTableEntities.length) ? (
          <NoProfilesPlaceholder quickCreateProfile={this.quickCreateProfile} />
        ) : null}
        <GologinModal
          width='373px'
          isVisible={this.state.modalVisiblee}
          onClose={this.closeModall}
          title={this.props.t('profiles.profileFieldsEditor')}
        >
          {this.checkboxColumnStatus('status', this.props.t('profiles.tableColumns.status'))}
          {this.checkboxColumnStatus('notes', this.props.t('profiles.tableColumns.notes'))}
          {this.checkboxColumnStatus('tags', this.props.t('profiles.tableColumns.tags'))}
          {this.checkboxColumnStatus('customStatus', this.props.t('profiles.tableColumns.customStatus'))}
          {this.checkboxColumnStatus('lastUpdate', this.props.t('profiles.tableColumns.lastUpdate'))}
          {this.checkboxColumnStatus('lastLaunch', this.props.t('profiles.tableColumns.lastLaunch'))}
          {this.checkboxColumnStatus('createdAt', this.props.t('profiles.tableColumns.createdAt'))}
          {this.checkboxColumnStatus('sharing', this.props.t('profiles.tableColumns.sharing'))}
          {this.checkboxColumnStatus('proxyType', this.props.t('profiles.tableColumns.proxyType'))}
          {this.checkboxColumnStatus('proxyPort', this.props.t('profiles.tableColumns.proxyPort'))}
          {this.checkboxColumnStatus('proxyUsername', this.props.t('profiles.tableColumns.proxyUsername'))}
          {this.checkboxColumnStatus('proxyPassword', this.props.t('profiles.tableColumns.proxyPassword'))}
          {this.checkboxColumnStatus('proxy', this.props.t('profiles.tableColumns.proxy'))}
          {this.checkboxColumnStatus('os', this.props.t('profiles.tableColumns.os'))}
          {this.checkboxColumnStatus('orbitaVersion', this.props.t('profiles.tableColumns.orbitaVersion'))}
        </GologinModal>
        <div onClick={(event): void => event.stopPropagation()}>
          {this.getCookiesList()}
          {(!this.isNewShareProfileModal && this.state.sharingProfile) ? <ShareModal
            profile={this.state.sharingProfile}
            visible={this.state.modalVisibleSharing}
            onClose={this.toggleModalSharingOld}
          /> : null}
          {this.state.transferProfileModal ? (
            <TransferProfileModal
              onTransfer={this.doTransferProfile}
              visible={this.state.transferProfileModal}
              onClose={this.closeTransferProfileModal}
              profilesIds={[this.state.transferModalProfileId]}
            />
          ) : null}
          <HistoryModal
            visible={this.state.isProfileHistoryModalOpened}
            onClose={(): void => this.setState({
              isProfileHistoryModalOpened: false,
              profileHistoryModalProfileId: '',
            })}
            profileId={this.state.profileHistoryModalProfileId}
            afterClose={this.afterModalClose}
          />
          <UpdateProfilesModal
            visible={this.state.updateProfilesModal}
            onClose={this.closeUpdateProfileModal}
            profileId={this.state.updateProfileModalProfileId}
            afterClose={this.afterModalClose}
          />
          <OrbitaVersionModals.UpdateOrSkip
            modalType='orbitaUpdateOrRun'
            visible={this.state.isOrbitaUpdateModalVisible}
            areButtonsDisabled={this.state.areOrbitaModalButtonsDisabled}
            handleCancel={this.handleOrbitaUpdateModalCancel}
            handleSubmit={this.handleOrbitaUpdateModalSubmit}
            handleClose={this.handleOrbitaUpdateModalClose}
            handleAfterClose={this.handleOrbitaUpdateModalAfterClose}
            orbitaVersion={this.state.versionToUpdate}
          />
          <OrbitaVersionModals.IncompatibleCannotRun
            visible={this.state.isOrbitaIncompatibleCannotRunModalVisible}
            handleCancel={(): void => this.setState({ isOrbitaIncompatibleCannotRunModalVisible: false })}
          />
          {/* <AutomationManager renderButton={this.renderButtonType} /> */}
        </div>
      </>
    );
  }
}

const PTableClassComponent = withTranslation()(PTableClass);

type ProfilesTableQuickProps = Omit<IProfilesTableQuick, 'basicTableEntities'
  | 'selectedProfileRowKeys'
  | 'sortField'
  | 'sortOrder'
>

// eslint-disable-next-line react/no-multi-comp
export const ProfilesTableQuick: React.FC<ProfilesTableQuickProps> = (props)  => {
  const basicTableEntities = useBasicTableEntities();
  const selectedRowKeys = useOldProfilesTableSelectedRowIndexes();
  const { sortField, sortOrder } = useProfilesTableSorting();

  const fetchMoreProfiles = async (): Promise<void> => {
    const isMoreProfilesAvailable = doesProfilesQueryHaveMoreProfiles();

    // this paginate helps in cases, where user deletes too many profiles
    // and ends up having less than 30 - so we load more
    // however, if we group profiles table and open big group,
    // it is also being triggered and breaks stuff because of hidden groups
    // TODO: fix pagination on low profiles for grouping
    const isProfilesTableGrouped = getIsProfilesTableGrouped();
    if (!isProfilesTableGrouped && basicTableEntities.length < PAGE_SIZE && isMoreProfilesAvailable) {
      paginateProfilesQuery();
    }
  };

  useEffect(() => {
    fetchMoreProfiles();
  }, [basicTableEntities]);

  return (
    <PTableClassComponent
      basicTableEntities={basicTableEntities}
      selectedProfileRowKeys={selectedRowKeys}
      sortField={sortField}
      sortOrder={sortOrder}
      {...props}
    />
  );
};

export default ProfilesTableQuick;
