import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { E_PROFILE_SETTINGS_PAGE_ANALYTICS_EVENT } from '../../../common/constants/analytics';
import { sendActionAnalytics } from '../../features/common/api';
import { IProxy } from '../../interfaces';
import { hideProxyCheckTooltip } from './proxy-check/proxy-check-tooltip.atom';
import { proxyListAtom } from './proxy-list.atom';
import {
  closeProxyManager,
  IProxyManagerOpenParams,
  openProxyManager,
  ProxySelectorLocation,
} from './proxy-manager-modal-status.atom';

const profileSettingsProxyIdAtom = atom<string | null>(null);
const profileSettingsPreviousProxyAtom = atom<IProxy | null>(null);
const profileSettingsProxyFormAtom = atom<IProxy | null>(null);

export const onProfileSettingsProxySelect = (proxyId: string | null): void => {
  closeProxyManager();

  if (proxyId) {
    sendActionAnalytics(E_PROFILE_SETTINGS_PAGE_ANALYTICS_EVENT.selectedSavedProxy, { proxyId });
  } else {
    hideProxyCheckTooltip();
  }

  setProfileSettingsProxyId(proxyId);
};

export const getProfileSettingsProxyId = (): string | null => getDefaultStore().get(profileSettingsProxyIdAtom);
export const useProfileSettingsProxyId = (): string | null => useAtomValue(profileSettingsProxyIdAtom);
export const setProfileSettingsProxyId = (newProxyId: string | null): void => {
  getDefaultStore().set(profileSettingsProxyIdAtom, newProxyId);
};

export const getProfileSettingsPreviousProxy = (): IProxy | null =>
  getDefaultStore().get(profileSettingsPreviousProxyAtom);
export const setProfileSettingsPreviousProxy = (newPreviousProxy: IProxy | null): void =>
  getDefaultStore().set(profileSettingsPreviousProxyAtom, newPreviousProxy);

export const getProfileSettingsProxyForm = (): IProxy | null => getDefaultStore().get(profileSettingsProxyFormAtom);
export const useProfileSettingsProxyForm = (): IProxy | null => useAtomValue(profileSettingsProxyFormAtom);
export const setProfileSettingsProxyForm = (newProxyForm: IProxy | null): void =>
  getDefaultStore().set(profileSettingsProxyFormAtom, newProxyForm);

const proxyInProfileSettingsAtom = atom<IProxy | null>(null);

const proxyInProfileSettingsSyncedAtom = atom<IProxy | null>((get): IProxy | null => {
  const proxyInProfileSettings: IProxy | null = get(proxyInProfileSettingsAtom);
  if (!proxyInProfileSettings || proxyInProfileSettings.mode === 'none') {
    return null;
  }

  const listedProxy = get(proxyListAtom).find((proxyInList): boolean => {
    if (proxyInProfileSettings.id && proxyInList.id === proxyInProfileSettings.id) {
      return true;
    }

    return proxyInList.id === proxyInProfileSettings.id;
  });

  if (!listedProxy) {
    return null;
  }

  return { ...proxyInProfileSettings, ...listedProxy };
});

// TODO: the most mental atom the world is going to see (remove, when new profile settings page is introduced)
// This enables `handleNoProxyClick` to clear the new proxy form on the old profile settings page
const clearNewProxyFormCallbackAtom = atom<{ callback: () => void }>({ callback: () => void null });

const setProxyInProfileSettings = (proxy: IProxy | null): void =>
  getDefaultStore().set(proxyInProfileSettingsAtom, proxy);

export const useProxyInProfileSettings = (): IProxy | null => useAtomValue(proxyInProfileSettingsSyncedAtom);

export const selectProxyInProfileSettings = (proxy: IProxy): void => setProxyInProfileSettings(proxy);
export const unselectProxyInProfileSettings = (): void => setProxyInProfileSettings(null);

export const openProxyManagerInProfileSettings = ({
  containerElement,
  currentProxy = null,
  currentProfileId = null,
}: Pick<IProxyManagerOpenParams, 'containerElement' | 'currentProxy' | 'currentProfileId'>): void =>
  openProxyManager({
    containerElement,
    currentProxy,
    currentProfileId,
    proxySelectorLocation: ProxySelectorLocation.profileSettingsPage,
    modalView: 'proxy-list',
    handleProxySelect: onProfileSettingsProxySelect,
  });

export const resetProxyInProfileSettings = (): void => {
  closeProxyManager();
  unselectProxyInProfileSettings();
};

export const setClearNewProxyFormCallback = (callback: () => void): void =>
  getDefaultStore().set(clearNewProxyFormCallbackAtom, { callback });
export const getClearNewProxyFormCallback = (): { callback: () => void } =>
  getDefaultStore().get(clearNewProxyFormCallbackAtom);
