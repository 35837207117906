/* eslint-disable max-lines */
import { SimpleSortableContext } from '@dnd-kit-contextless/sortable';
import * as Sentry from '@sentry/react';
import { ConfigProvider, message } from 'antd';
import React, { FC, SyntheticEvent, useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import type { ResizeCallbackData } from 'react-resizable';
import { useRouteMatch } from 'react-router-dom';

import BodyContainer from './body-container';
import ContainerDashboard from './container-dashboard';
import { InfiniteScrollProfilesTable } from './infinite-scroll-profiles-table';
import { BrowserStartData, ILaunchProfileOrbita } from './interfaces/launch-profile-orbita.interface';
import PageOverlay from './page-overlay';
import { ProfilesTableLoadingSpinner } from './profiles-table-loading-spinner';
import ResizableTitle from './resizable-title';
import { ContainerContent, ContainerPreTable, ContainerTableName, TextTableName } from './styles';
import { TableProfile } from './table-custom';
import { ProxyPageAnalyticsEvent } from '../../../../common/constants/analytics';
import { PROFILE_SHARED_KEY_NAME } from '../../../../common/constants/constants';
import { PROFILE_RUN_DEEP_LINK_EVENT_NAME, PROFILE_SHARE_DEEP_LINK_EVENT_NAME } from '../../../../common/constants/events';
import { LOCAL_STORAGE_SELECTED_FOLDER } from '../../../../common/constants/local-storage';
import { DeepLinkProtocol } from '../../../../common/types/deep-link-protocol';
import { normalizeProfilesData } from '../../../../common/utils';
import { IUpdateProfileStatusEvent } from '../../../../electron/interfaces/profile.status.manager.interfaces';
import { useQuery } from '../../../hooks';
import useGlobalAntStyles from '../../../hooks/use-global-ant-styles';
import { IFolder, IProfile } from '../../../interfaces';
import { useBootstrapStatus } from '../../../object-pool/bootstrap/bootstrap-status.atom';
import { history } from '../../../services';
import PerformanceObserverService from '../../../services/performance-observer/performance-observer.service';
import {
  browserUpdaterContext,
  getSearchQuery,
  importFilesContext,
  tagsContext,
  updateSearchQuery,
  userContext,
  workspaceContext,
} from '../../../state';
import {
  getDefaultColumnsSettings,
  IProfilesTableSettings,
  saveProfilesTableColumns,
  setColumnsSettings,
  SingleProfileColumnSetting,
  updateProfilesTableSettingsState,
  useColumnsSettings,
} from '../../../state/columns-settings.atom';
import { NEW_FEATURES } from '../../../state/feature-toggle/new-features';
import { getLatestLocalOrbitaVersion, setLatestLocalOrbitaVersion } from '../../../state/latest-local-orbita-version.atom';
import { refreshLocalUserDevice } from '../../../state/local-user-device.atom';
import { getFlatProfileRunStatuses, getProfileRunStatus, updateProfileRunStatusesForWeb } from '../../../state/profile-run-statuses.atom';
import {
  getProfilesList,
  IProfileRunStatus,
  mapAndSetProfilesList,
  reloadProfilesTableGroups,
  resetProfilesList,
  setProfilesList,
} from '../../../state/profiles-list.atom';
import { updateFullProfilesMap } from '../../../state/profiles-map.atom';
import {
  getProfilesTableProxyIdFilter,
  resetProfilesTableProxyIdFilter,
  useProfilesTableProxyIdFilter,
} from '../../../state/profiles-proxy-filter.atom';
import { closeProfilesSettings, openProfilesSettingsOfSelectedIds } from '../../../state/profiles-settings-atom';
import { getBasicTableEntities, getBasicTableEntityById, useBasicTableEntities } from '../../../state/profiles-table/basic-table-entities.atom';
import { loadGroupsFromLocalStorage } from '../../../state/profiles-table/groups-local-storage';
import {
  fetchProfilesByCurrentQuery,
  HandleCheckUaFn,
  HandleDropFileLoadedFn,
  HandleFolderDeletedFn,
  HandleFullProfilesMapFn,
  HandleOrbitaVersionsFn,
  paginateProfilesQuery,
  useProfilesQueryLoadingStatus,
} from '../../../state/profiles-table/profiles-query';
import {
  initProfilesTableSorting,
  resetProfilesTableSorting,
  sortProfilesTable,
  useProfilesTableSorting,
} from '../../../state/profiles-table/profiles-sort.atom';
import {
  getProfilesTableSelectedIds,
  updateProfilesTableSelectedIds,
  useOldProfilesTableSelectedRowIndexes,
} from '../../../state/profiles-table-selected-ids.atom';
import {
  onProfilesTableRowClick,
  onUserChangeSelectedProfiles,
  updateProfileStateAtFinish,
  updateProfileStateAtLaunch,
  updateProfileStatus,
} from '../../../state/profiles-table.commands';
import { getProxyListProxyById } from '../../../state/proxy/proxy-list.atom';
import { closeProxyManager } from '../../../state/proxy/proxy-manager-modal-status.atom';
import { checkProfilesProxies } from '../../../state/proxy/proxy-operations/check-proxies.operations';
import { setSelectedFolderId } from '../../../state/selected-folder.atom';
import { setSelectedTagId } from '../../../state/tags/selected-tag.atom';
import { IconSpinner } from '../../../ui/gologin-header/icons';
import GologinTable from '../../../ui/gologin-table';
import Header from '../../../ui/Header';
import { insertProfileToListByIndex } from '../../../utils/insert-profile-to-list-by-index';
import { INewNameProfile } from '../../../utils/parse-name-formatting/interfaces';
import { ReactError } from '../../../utils/sentry-parameters/custom-errors';
import { ITransactionObject } from '../../../utils/sentry-parameters/helper.functions.interfaces';
import {
  generateBrowserIconsPromise,
  getIconsFromSessionStorage,
  getParamsToGenerateIcons,
  removeIconsFromSessionStorage,
  saveIconsToSessionStorage,
} from '../../browser-system-icons';
import { sendActionAnalytics } from '../../common/api';
import CustomTableLoader from '../../common/custom-table/components/custom-table-loader';
import { IFilter, Sorter } from '../../common/custom-table/interfaces/column.interfaces';
import { getProfileForLink, getProfileFromStorage, handleShareViaLinkFromEvent } from '../../common/deep-links';
import LoadingPage from '../../common/loading-page';
import CookiesListModal from '../../modalsComponents/components/cookies-manager/cookies-list-modal';
import FoldersManager from '../../modalsComponents/components/folders-manager';
import MultiShareModal from '../../modalsComponents/components/multi-share-modal';
import OrbitaVersionModals from '../../modalsComponents/components/orbita-version-modals';
import ProfilesTableColumnsModal from '../../modalsComponents/components/profiles-table-columns';
import HistoryModal from '../../modalsComponents/components/profile-upload-history';
import RemoveProfileModal from '../../modalsComponents/components/remove-profile-modal';
import TransferProfileModal from '../../modalsComponents/components/transfer-profile';
import UpdateProfilesModal from '../../modalsComponents/components/update-profiles-ua/update-profiles';
import ProfileSettings from '../../profile-settings';
import { requestFullProfileInfo } from '../../profile-settings/api';
import { getIsProxyArchived, restoreProxy, syncProxyTooltipWithProfileStatus } from '../../proxy/proxy-helpers';
import ProxyManager from '../../proxy/proxy-manager';
import { getAllTags } from '../../tags/api';
import TitleTag from '../../tags/components/title-tag';
import UpdateProfilePage from '../../updateProfile/components/update-profile-page';
import { modifyProfilePin } from '../actions';
import {
  getWorkspaceProfileTableSettings,
  PinAction,
  requestProfiles,
  saveWorkspaceProfileTableColumnsOrder,
  updateRunStatus,
} from '../api';
import { evaluateProfilesTableColumnsOrder } from '../columns-order';
import { DEFAULT_SORT_FIELD, DEFAULT_SORT_ORDER } from '../constants';
import DraggingProfilesOverlay from '../dragging-profiles-overlay';
import { getColumnsList } from '../get-columns-list';
import MultipleOperationsMenu from '../multiple-operations-menu';
import NoProfilesPlaceholder from '../no-profiles-placeholder';
import OldTableHeaderControls from '../old-table-header-controls';
import ProfileDropdownMenu from '../profile-dropdown-menu';
import ProfilesTableUpdater from '../profiles-table-updater';
import QuickPricing from '../quick-pricing';
import TableCustomizationMenu from '../table-customization-menu';

export const ALL_PROFILES_FOLDER = 'all';

let ipcRenderer: Electron.IpcRenderer;
const isElectron = !!window.require;

if (isElectron) {
  ({ ipcRenderer } = window.require('electron'));
}

const ROW_SELECTION = {
  columnWidth: 34,
};

let profileStatusUpdateIntervalId: NodeJS.Timeout;
const PROFILES_STATUS_UPDATE_TIMING = 10 * 1000;

const ProfilesListPage: FC = () => {
  const userCtx = useContext(userContext);
  const {
    selectedFolder: selectedFolderFromCtx = '',
    defaultWorkspace = '',
    updateSelectedFolder,
    folders: foldersListFromCtx,
    firstPlanSelected,
  } = userCtx;

  const [profilesUAChecking, setProfilesUAChecking] = useState<boolean>(false);
  const [currentOrbitaMajorV, setCurrentOrbitaMajorV] = useState<string>('');
  const [currentBrowserV, setCurrentBrowserV] = useState<string>('');
  const [profilesTableSettings, setProfilesTableSettings] = useState<IProfilesTableSettings|null>(null);
  const [tableSettingsLoaded, setTableSettingsLoaded] = useState<boolean>(false);
  const [foldersList, setFoldersList] = useState<IFolder[]>([]);
  const [initStatus, setInitStatus] = useState<'init-block'|'block-by-tags'|'block-by-folders'|'allowed'>('init-block');

  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [selectedFolder, setSelectedFolder] = useState<string>('');
  const [isOrbitaUpdateModalVisible, setIsOrbitaUpdateModalVisible] = useState<boolean>(false);
  const [isOrbitaIncompatibleCannotRunModalVisible, setIsOrbitaIncompatibleCannotRunModalVisible] = useState<boolean>(false);
  const [areOrbitaModalButtonsDisabled, setAreOrbitaModalButtonsDisabled] = useState<boolean>(false);
  const [isResizeMode, setIsResizeMode] = useState<boolean>(false);
  const [launchProfileOpts, setLaunchProfileOpts] = useState<ILaunchProfileOrbita|null>(null);
  const [versionToUpdate, setVersionToUpdate] = useState<string>('');

  const basicTableEntities = useBasicTableEntities();
  const selectedRowKeys = useOldProfilesTableSelectedRowIndexes();
  const { sortField, sortOrder } = useProfilesTableSorting();
  const profilesTableProxyIdFilter = useProfilesTableProxyIdFilter();
  const profilesQueryLoadingStatus = useProfilesQueryLoadingStatus();
  const bootstrapStatus = useBootstrapStatus();
  const isIniting = profilesQueryLoadingStatus === 'initing';

  const {
    selectedTag,
    setTags,
  } = React.useContext(tagsContext);

  const { browserUpdating, updateBrowserUpdater, isOrbitaVersionCompatible } = useContext(browserUpdaterContext);
  const {
    dropFileStep,
    setDropFileStep,
    setNeedParseName,
    errorImportMessage,
    setErrorImportMessage,
  } = useContext(importFilesContext);

  const workspaceCtx = useContext(workspaceContext);
  const {
    id: workspaceId,
    isLoaded: workspaceIsLoaded,
    allProfilesFolderId,
  } = workspaceCtx;

  const query = useQuery();
  const routeUpdateObj = useRouteMatch('/update/:profileId');
  const isUpdatePage = routeUpdateObj?.isExact;

  const { t: translation } = useTranslation();

  const { columnsSettings } = useColumnsSettings();

  const { areAntStylesLoading } = useGlobalAntStyles(true, isUpdatePage);

  const headerRef = useRef<HTMLDivElement>(null);

  const setProfilesFnWithRef = useCallback((set: (prevProfiles: IProfile[]) => IProfile[]): void => {
    mapAndSetProfilesList(prevProfiles => {
      const newProfiles = set(prevProfiles);
      updateFullProfilesMap(newProfiles);

      return newProfiles;
    });
  }, [setProfilesList, updateFullProfilesMap]);

  useEffect(() => {
    if (profilesQueryLoadingStatus !== 'loaded') {
      return;
    }

    ipcRenderer && ipcRenderer.invoke('download-all-browser-fonts').catch(() => null);
  }, [profilesQueryLoadingStatus]);

  const handleOrbitaVersions: HandleOrbitaVersionsFn = (currentOrbitaMajorV, currentBrowserV) => {
    setCurrentBrowserV(currentBrowserV);
    setCurrentOrbitaMajorV(currentOrbitaMajorV);
  };

  const handleFolderDeleted: HandleFolderDeletedFn = () => {
    const selectedFolder = NEW_FEATURES.header ? selectedFolderFromCtx : localStorage.getItem(LOCAL_STORAGE_SELECTED_FOLDER);
    updateSelectedFolder('');
    localStorage.setItem(LOCAL_STORAGE_SELECTED_FOLDER, ALL_PROFILES_FOLDER);
    const newFoldersList = foldersList.filter(folder => folder.name !== selectedFolder);
    setFoldersList(newFoldersList);
  };

  const handleFullProfilesMap: HandleFullProfilesMapFn = () => {
    updateFullProfilesMap(getProfilesList());
  };

  const handleDropFileLoaded: HandleDropFileLoadedFn = () => {
    setDropFileStep('loaded');
  };

  const checkProfilesStatus = (): Promise<void> => isElectron && ipcRenderer.invoke('check-profiles');

  const handleLoadingDone: HandleDropFileLoadedFn = () => {
    setNeedParseName(false);
    checkProfilesStatus();
  };

  const checkProfilesUA = async (
    receivedProfiles: IProfile[],
    browserV: string = currentBrowserV,
    currentUserOrbitaV: string = getLatestLocalOrbitaVersion(),
  ): Promise<void> => {
    if (profilesUAChecking || !isElectron) {
      return;
    }

    setProfilesUAChecking(true);

    if (currentUserOrbitaV === '0.0.0') {
      currentUserOrbitaV = await ipcRenderer.invoke('get-orbita-browser-version') || '0.0.0';
      setLatestLocalOrbitaVersion(currentUserOrbitaV);
    }

    if (currentUserOrbitaV === '0.0.0' || !browserV) {
      return;
    }

    receivedProfiles.forEach((profileObj: any) => {
      const profileUA = profileObj.navigator?.userAgent;
      if (profileObj.updateUALastChosenBrowserV === browserV || !profileUA) {
        return;
      }

      const profileUAMatch = profileUA.match(/Chrome\/(\d+\.\d+\.\d+\.\d+)\s/);
      const [_, profileUAVersion = ''] = profileUAMatch || [];

      if (profileUAVersion === browserV) {
        return;
      }

      profileObj.suggestUpdateUA = true;
    });

    setProfilesUAChecking(false);
  };

  const handleCheckUa: HandleCheckUaFn = ({ receivedProfiles, currentBrowserV, currentOrbitaMajorV }) =>
    checkProfilesUA(receivedProfiles, currentBrowserV);

  const callFetchProfilesByQuery = async (): Promise<void> => {
    const result = await fetchProfilesByCurrentQuery({
      dropFileStep,
      handleOrbitaVersions,
      handleFolderDeleted,
      handleFullProfilesMap,
      handleDropFileLoaded,
      handleLoadingDone,
      handleCheckUa,
    });

    const { receivedProfiles = [] } = result || {};
    const shouldCheckUa = receivedProfiles.length &&
      (currentBrowserV || currentOrbitaMajorV) &&
      NEW_FEATURES.objectPool;

    if (shouldCheckUa) {
      handleCheckUa({ receivedProfiles, currentBrowserV, currentOrbitaMajorV });
    }
  };

  useEffect(() => {
    if (dropFileStep === 'loading') {
      callFetchProfilesByQuery();

      return;
    }

    if (dropFileStep === 'loaded') {
      const selectedProfileIds = getProfilesTableSelectedIds();
      const profiles = getProfilesList();

      const newProfiles = profiles.filter(profile => selectedProfileIds.includes(profile.id));
      const profileIds = newProfiles.map(profile => profile.id);

      checkProfilesProxies(newProfiles);
      updateProfilesTableSelectedIds(profileIds);
      openProfilesSettingsOfSelectedIds();
      setDropFileStep(null);

      if (errorImportMessage) {
        setErrorImportMessage('');
        setTimeout(() => message.error(errorImportMessage), 4000);
      }
    }
  }, [dropFileStep, getProfilesList]);

  useEffect(() => {
    refreshLocalUserDevice();
    const updateExtensionsOnClose = (_: unknown, res: { profileId: string; extensionsIds: string[] }): any => {
      mapAndSetProfilesList(profiles => profiles.map(profile => {
        if (profile.id === res.profileId) {
          profile.chromeExtensions = res.extensionsIds;
        }

        return profile;
      }));
    };

    ipcRenderer?.on('update-extensions-on-close', updateExtensionsOnClose);

    return () => {
      ipcRenderer?.removeListener('update-extensions-on-close', updateExtensionsOnClose);
      // This fixes proxy manager popover appearing in the top-left corner of a page in some cases
      closeProxyManager();
    };
  }, []);

  const loadWorkspaceProfileTableSettings = async (): Promise<void> => {
    if (!(profilesTableSettings && profilesTableSettings.workspace === workspaceId)) {
      const tableSettings = await getWorkspaceProfileTableSettings(workspaceId);
      tableSettings.workspace = workspaceId;
      if (tableSettings) {
        setProfilesTableSettings(tableSettings);
      }
    }

    setTableSettingsLoaded(true);
  };

  const initPage = async (): Promise<void> => {
    if (!NEW_FEATURES.header) {
      const querySearch = query.get('q') || '';
      updateSearchQuery(querySearch);
    }

    initProfilesTableSorting();
  };

  useEffect(() => {
    if (profilesTableProxyIdFilter) {
      sendActionAnalytics(ProxyPageAnalyticsEvent.visitedProfilesPageFilteredByProxy);
    }

    if (!workspaceIsLoaded || getSearchQuery() === null) {
      return;
    }

    loadWorkspaceProfileTableSettings();

    initPage();
  }, [
    profilesTableProxyIdFilter,
    workspaceIsLoaded,
    workspaceId,
  ]);

  useEffect(() => {
    setInitStatus('init-block');
  }, [workspaceId]);

  const onFolderChange = (newFolderName: string): void => {
    localStorage.setItem('SelectedFolder', newFolderName);
    resetProfilesList();
    callFetchProfilesByQuery();
  };

  const normalizeData = (): void => {
    mapAndSetProfilesList(profiles => normalizeProfilesData(profiles));
    checkProfilesStatus();
  };

  const onUpdateProfileStatus = (_: unknown, args: IUpdateProfileStatusEvent): void => {
    const { profileId, status, message: statusMessage, proxyId } = args;

    if (proxyId) {
      syncProxyTooltipWithProfileStatus({
        profileId, statusMessage, proxyId,
      });
    }

    const performanceObserverService = PerformanceObserverService.getInstance();
    const profileStatusSpan = performanceObserverService.createProfileRunSpan(profileId, status);

    const isProfileLoadingFinished = ['profileStatuses.ready', 'profileStatuses.error', 'profileStatuses.running'].includes(status);
    if (!isProfileLoadingFinished) {
      updateProfileStatus(args);
      profileStatusSpan.finish();

      return;
    }

    if (status !== 'profileStatuses.running') {
      updateProfileStatus(args);
      profileStatusSpan.finish();

      return;
    }

    updateProfileStateAtFinish(profileId, status);

    profileStatusSpan.finish();
    performanceObserverService.finishProfileRunTransaction(profileId);
  };

  const getCurrentStatus = (profileId: string): boolean => {
    const profileRunStatus = getProfileRunStatus(profileId);

    return ['profileStatuses.ready', 'profileStatuses.error'].includes(profileRunStatus.status);
  };

  const shouldLaunchAfterVersionCheck = async (options: ILaunchProfileOrbita, transaction: ITransactionObject): Promise<boolean> => {
    const span = transaction.startChild({ op: 'check-browser-version-before-launch' });
    const { profile } = options;
    const { versionToDownload, pickedVersionIfSkipDownload } =
      await ipcRenderer.invoke('check-supported-orbita', { key: 'userAgent', value: profile.navigator.userAgent })
        .catch(() => ({ versionToDownload: null, pickedVersionIfSkipDownload: null }));

    const isBrowserVersionCompatible = await isOrbitaVersionCompatible(versionToDownload).catch(() => true);
    if (!versionToDownload) {
      span.finish();

      return true;
    }

    if (isBrowserVersionCompatible) {
      setLaunchProfileOpts({ ...options, skipOrbitaVersionCheck: true, pickedVersionIfSkipDownload });
      setVersionToUpdate(versionToDownload);
      setIsOrbitaUpdateModalVisible(true);
    } else {
      setLaunchProfileOpts({ pickedVersionIfSkipDownload });
      setIsOrbitaIncompatibleCannotRunModalVisible(true);
    }

    span.finish();

    return false;
  };

  const launchProfileOrbita = async (options: ILaunchProfileOrbita): Promise<void | null> => {
    const {
      profile,
      multipleLaunch = false,
      updateStatusToSync = true,
      skipOrbitaVersionCheck = false,
      automation,
      iconsStartData,
      deepLinkProtocol,
    } = options;

    const { id: profileId, proxy } = profile;
    const { id: proxyId = '' } = proxy || {};

    const { status = false, checkDate } = getProxyListProxyById(proxyId) || {};

    const performanceObserverService = PerformanceObserverService.getInstance();
    const transaction = performanceObserverService.createProfileRunTransaction(profileId);

    const listedProxy = getProxyListProxyById(proxyId);
    let proxyToRunInProfile: BrowserStartData['proxyInfo'] = { ...proxy, port: +proxy.port };
    if (listedProxy) {
      proxyToRunInProfile = {
        ...proxyToRunInProfile,
        ...listedProxy,
        port: +listedProxy.port,
      };
    }

    const isReady = getCurrentStatus(profileId);

    // need to check `isLinkRun`, since `isReady` does not show the relevant status for profiles, that are not loaded in the table
    if (!(deepLinkProtocol || isReady)) {
      return;
    }

    if (!skipOrbitaVersionCheck) {
      const browserIconsData = getParamsToGenerateIcons(profile);
      const [shouldLaunch, iconsPngs] = await Promise.all([
        shouldLaunchAfterVersionCheck(options, transaction),
        generateBrowserIconsPromise(browserIconsData, transaction),
      ]);

      saveIconsToSessionStorage(profileId, iconsPngs, browserIconsData.iconDesignVersion);
      if (!shouldLaunch) {
        transaction.finish();

        return null;
      }
    }

    const updateRunStatusSpan = transaction.startChild({ op: 'ui', description: 'update-run-status' });
    const finalUpdateStatusToSync = !deepLinkProtocol && updateStatusToSync;
    updateProfileStateAtLaunch(profile, finalUpdateStatusToSync);
    updateRunStatusSpan?.finish();

    const { archivedProxy } = profile;
    if (getIsProxyArchived(archivedProxy)) {
      await restoreProxy(archivedProxy);
    }

    const iconsData = iconsStartData || getIconsFromSessionStorage(profileId);
    const startData: BrowserStartData = {
      profileId,
      proxyInfo: proxyToRunInProfile,
      proxyEnabled: profile.proxyEnabled,
      automation,
      icons: iconsData,
      traceId: transaction.traceId || '',
      deepLinkProtocol,
      lastProxyCheckStatus: {
        id: proxyId,
        status,
        checkDate,
      },
    };

    removeIconsFromSessionStorage(profileId, iconsData.pngs.length >= 1);

    const loadingResult = ipcRenderer && await ipcRenderer.invoke('start-profile', startData);
    if (loadingResult === 'cantBeRunning' && !multipleLaunch) {
      message.error('Profile cannot be running. Other users are using this profile now.');
      checkProfilesStatus();
      performanceObserverService.finishProfileRunTransaction(profileId);
    }
  };

  const handleDeepLinkRun = async (
    _event: Electron.IpcRendererEvent|null,
    args: { profileIdOrName: string; deepLinkProtocol: DeepLinkProtocol },
  ): Promise<void> => {
    const { profileIdOrName = '', deepLinkProtocol = 'custom' } = args;
    let workspaceId = '';
    try {
      const workspaceInfoJson = localStorage.getItem('workspace') || '';
      const workspaceInfo = JSON.parse(workspaceInfoJson);
      workspaceId = workspaceInfo.id;
    } catch {
      return;
    }

    if (!(profileIdOrName && workspaceId)) {
      const transactionName = 'profile-or-wid-in-open-link-is-falsy';
      Sentry.captureException(new ReactError(transactionName.replaceAll('-', ' ')), (scope) => {
        scope.setLevel('error');
        scope.setTransactionName(transactionName);
        scope.setFingerprint([transactionName]);

        return scope;
      });

      return;
    }

    const profile = await getProfileForLink({
      workspaceId,
      profileIdOrName,
      requestProfileFingerprint: requestFullProfileInfo,
      requestProfiles,
    });

    if (!profile) {
      const transactionName = 'profile-in-open-link-not-found';
      Sentry.captureException(new ReactError(transactionName.replaceAll('-', ' ')), (scope) => {
        scope.setLevel('error');
        scope.setTransactionName(transactionName);
        scope.setFingerprint([transactionName]);

        return scope;
      });

      return message.error(translation('tableProfiles.notification.profileInUrlNotFound'));
    }

    await launchProfileOrbita({ profile, deepLinkProtocol }).catch(() => null);
  };

  const updateProfilesRunningInWeb = async (): Promise<void> => {
    const flatProfileRunStatuses = getFlatProfileRunStatuses();
    const profilesRunningInWeb = await updateRunStatus({
      profiles: flatProfileRunStatuses,
      isNewRoute: true,
      isPinging: true,
      shouldSendAnalytics: false,
    });

    updateProfileRunStatusesForWeb(profilesRunningInWeb);
  };

  const handleResize = (): void => setWindowWidth(window.innerWidth);

  const addProfileFirstToList = (profile: IProfile): void => mapAndSetProfilesList((prevProfiles) => {
    let profilesToUpdate: IProfile[];
    const profileExistingIndex = prevProfiles.findIndex(({ id }) => id === profile.id);
    if (profileExistingIndex < 0) {
      profilesToUpdate = [...prevProfiles];
      profilesToUpdate.unshift(profile);
    } else {
      profilesToUpdate = insertProfileToListByIndex(prevProfiles, profile, profileExistingIndex);
    }

    updateFullProfilesMap(profilesToUpdate);

    return profilesToUpdate;
  });

  useEffect(() => {
    if (!ipcRenderer) {
      return;
    }

    const profileSharedViaLinkJson = sessionStorage.getItem(PROFILE_SHARED_KEY_NAME) || '';
    if (profileSharedViaLinkJson) {
      const profileSharedViaLink = getProfileFromStorage(profileSharedViaLinkJson);
      if (profileSharedViaLink) {
        addProfileFirstToList(profileSharedViaLink);
      }

      return;
    }

    ipcRenderer.on(PROFILE_SHARE_DEEP_LINK_EVENT_NAME, async (_event, args) => {
      const {
        message: errorMessage = '',
        profile = null,
        isUpdatePage: isUpdatePageRoute = false,
      }: { message: string; profile: IProfile|null; isUpdatePage?: boolean } = args;

      if (errorMessage) {
        return message.error(translation(errorMessage));
      }

      await handleShareViaLinkFromEvent({
        profile,
        defaultWorkspace,
        addProfileFirstToList,
        updateSelectedFolder,
        userCtx,
        workspaceCtx,
        isUpdatePageRoute,
      });
    });

    return () => {
      ipcRenderer.removeAllListeners(PROFILE_SHARE_DEEP_LINK_EVENT_NAME);
    };
  }, [isUpdatePage]);

  // temporary hack to update selected folder in the atom
  // as well as unlock profiles if not grouped by custom-status
  // but it should be done from the places where folder changes and not here
  useEffect(() => {
    if (!NEW_FEATURES.dragAndDrop) {
      setInitStatus('allowed');

      return;
    }

    let folderId = allProfilesFolderId;
    const selectedFolderName = selectedFolderFromCtx;
    if (selectedFolderName && selectedFolderName !== ALL_PROFILES_FOLDER) {
      const selectedFolder = foldersListFromCtx.find(folder => folder.name === selectedFolderName);

      folderId = selectedFolder?.id;
    }

    setSelectedFolderId(folderId || null);
    const { field: localStorageGroupsField = null } = loadGroupsFromLocalStorage() || {};
    setInitStatus((prevInitStatus) => {
      if (prevInitStatus !== 'allowed' && localStorageGroupsField === 'custom-status') {
        return 'block-by-tags';
      }

      const proxyIdFilter = getProfilesTableProxyIdFilter();
      if (!proxyIdFilter) {
        reloadProfilesTableGroups();
      }

      return 'allowed';
    });
  }, [selectedFolderFromCtx, foldersListFromCtx]);

  const clearColumnFilters = (): void => {
    setSelectedTagId(null);
    resetProfilesTableProxyIdFilter();
  };

  const updateOnSearch = (queryString: string): void => {
    resetProfilesList();
    updateSearchQuery(queryString);
  };

  useEffect(() => {
    if (!(workspaceIsLoaded && NEW_FEATURES.objectPool)) {
      return;
    }

    const updateBrowserVersions = async (): Promise<void> => {
      const { currentOrbitaMajorV: newCurrentOrbitaMajorV = '', currentBrowserV: newCurrentBrowserV = '' } = await requestProfiles({
        workspaceId,
        sortField: DEFAULT_SORT_FIELD,
        sortOrder: DEFAULT_SORT_ORDER,
        limit: 1,
      });

      handleOrbitaVersions(newCurrentOrbitaMajorV, newCurrentBrowserV);
    };

    updateBrowserVersions();
  }, [workspaceIsLoaded, NEW_FEATURES.objectPool]);

  useEffect(() => {
    if (!workspaceId) {
      return;
    }

    const fetchTags = async (): Promise<void> => {
      const userTagsResponse = await getAllTags(workspaceId);
      if (userTagsResponse.tags) {
        setTags(userTagsResponse.tags);
        reloadProfilesTableGroups();
      }

      setInitStatus((prevInitStatus) => prevInitStatus === 'block-by-tags' ? 'allowed' : prevInitStatus);
    };

    fetchTags();

    return clearColumnFilters;
  }, [workspaceIsLoaded, workspaceId]);

  const navigateToUpdatePage = (profileId: string): void => {
    const tableProfileIdx = getBasicTableEntityById(profileId)?.idx;
    const el = document.querySelector(`[data-row-key="${tableProfileIdx}"]`) as HTMLElement | null;
    if (!el) {
      return;
    }

    const tableY = 224;
    const rect = el.getBoundingClientRect();
    const profileToScrollToY = el.offsetTop - (rect.top + window.scrollY) + tableY;

    sessionStorage.setItem('profileToScrollY', `${profileToScrollToY}`);

    history.push(`/update/${profileId}`);
  };

  const renderDropDownMenu = (rowInfo: IProfile & IProfileRunStatus, isInDrawer?: boolean): JSX.Element => (
    <ProfileDropdownMenu
      profile={rowInfo}
      selectedFolderName={NEW_FEATURES.header ? selectedFolderFromCtx : selectedFolder}
      navigateToUpdatePage={(): void => navigateToUpdatePage(rowInfo.id)}
      isTriggerAlwaysShown={isInDrawer || !NEW_FEATURES.header}
      modifyProfilePin={(setTo: PinAction): Promise<void> => modifyProfilePin(rowInfo.id, setTo)}
    />
  );

  useEffect(() => {
    if (isElectron && ipcRenderer) {
      ipcRenderer.on('update-profile-status', onUpdateProfileStatus);

      const urlCurrent = new URL(location.href.replace('#', ''));
      const profileIdOrName = urlCurrent.searchParams.get('profile-id-or-name');
      const deepLinkProtocol = urlCurrent.searchParams.get('protocol') === 'http' ? 'http' : 'custom';
      if (profileIdOrName) {
        handleDeepLinkRun(null, { profileIdOrName, deepLinkProtocol });
      }

      ipcRenderer.on(PROFILE_RUN_DEEP_LINK_EVENT_NAME, async (event, args) => {
        await handleDeepLinkRun(event, args);
      });
    } else {
      updateProfilesRunningInWeb();
      profileStatusUpdateIntervalId = setInterval(updateProfilesRunningInWeb, PROFILES_STATUS_UPDATE_TIMING);
    }

    normalizeData();
    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!profilesTableSettings) {
      return;
    }

    let columnsOrder: string[] = evaluateProfilesTableColumnsOrder({
      isProxyColumnShownAmongFirstColumns: NEW_FEATURES.renameProxyToLocation,
    });

    const settingsRes = getDefaultColumnsSettings();
    if (profilesTableSettings) {
      if (profilesTableSettings.columnsOrder) {
        const newColumns = ['customStatus', 'proxyPort', 'proxyUsername', 'proxyPassword', 'orbitaVersion'];
        columnsOrder = profilesTableSettings.columnsOrder.concat(newColumns.filter((item) => !profilesTableSettings.columnsOrder.includes(item)));
      }

      if (profilesTableSettings.columnsSettings) {
        for (const el of profilesTableSettings.columnsSettings) {
          if (el.title) {
            settingsRes[el.title] = {
              visible: el.visible,
              width: el.width,
            };
          }
        }
      }

      setColumnsSettings(settingsRes);
    }

    const columnsList = getColumnsList({
      translation,
      renderDropDownMenu,
      launchProfileOrbita,
      setProfiles: setProfilesFnWithRef,
    });

    const columns = columnsOrder.map((colName: string) => columnsList.find((col: any) => col.colName === colName))
      .filter(Boolean);

    setColumnsSettings(columns);
  }, [profilesTableSettings]);

  useEffect(() => () => {
    if (!isElectron) {
      clearInterval(profileStatusUpdateIntervalId);
    }

    window.removeEventListener('resize', handleResize);
    if (!ipcRenderer) {
      return;
    }

    ipcRenderer.removeAllListeners('update-profile-status');
    ipcRenderer.removeAllListeners(PROFILE_RUN_DEEP_LINK_EVENT_NAME);
  }, []);

  const downloadSpecificOrbitaVersion = async (orbitaVersion: string): Promise<void> => {
    if (!orbitaVersion) {
      return;
    }

    updateBrowserUpdater({
      initialized: true,
      browserUpdating: true,
      showOrbitaDialog: false,
    });

    ipcRenderer && ipcRenderer.invoke('download-browser', { orbitaVersion });
  };

  useEffect(() => {
    if (browserUpdating || isIniting || !isElectron) {
      return;
    }

    ipcRenderer.invoke('get-orbita-browser-version')
      .then((currentUserOrbitaV = '0.0.0') => {
        setLatestLocalOrbitaVersion(currentUserOrbitaV);
        const newProfiles = [...getProfilesList()];
        checkProfilesUA(newProfiles, currentBrowserV);
        setProfilesList(newProfiles);
        updateFullProfilesMap(newProfiles);
      });
  }, [browserUpdating, getProfilesList]);

  useEffect(() => {
    if (NEW_FEATURES.header) {
      return;
    }

    const currentSelectedFolder = localStorage.getItem('SelectedFolder') || 'all';
    setSelectedFolder(currentSelectedFolder);
  }, [NEW_FEATURES.header]);

  const changeSelectedFolder = async (targetSelectedFolder: string): Promise<void> => {
    localStorage.setItem('SelectedFolder', targetSelectedFolder);
    setSelectedFolder(targetSelectedFolder);
    onFolderChange(targetSelectedFolder);
  };

  const handleResizeCol = (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData): void => {
      const newColumns = [...columnsSettings];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width,
      };

      setColumnsSettings(newColumns);
    };

  const changeProfileNames = (newProfileData: INewNameProfile[]): void => {
    mapAndSetProfilesList(profiles => profiles.map(profile => {
      const currentProfile = newProfileData.find(({ profileId }) => profileId === profile.id);
      if (!currentProfile) {
        return profile;
      }

      return {
        ...profile,
        name: currentProfile.name || '',
      };
    }));
  };

  const changeProfilesData = (profileIds: string[], newData: Partial<IProfile>): void => {
    mapAndSetProfilesList(profiles => profiles.map(profile => {
      if (!profileIds.includes(profile.id)) {
        return profile;
      }

      return {
        ...profile,
        ...newData,
      };
    }));
  };

  const updateProfilesList = (): void => {
    changeSelectedFolder(selectedFolder);
  };

  const updateProfileFingerprints = (updatedProfiles: IProfile[]): void => {
    mapAndSetProfilesList(profiles => profiles.map((profile) => {
      const updatedProfile = updatedProfiles.find(pp => profile.id === pp.id);

      if (!updatedProfile) {
        return profile;
      }

      return {
        ...profile,
        ...updatedProfile,
        navigator: {
          ...profile.navigator,
          ...updatedProfile.navigator,
        },
      };
    }));
  };

  const fetchMoreProfiles = async (): Promise<void> => {
    if (!isResizeMode) {
      paginateProfilesQuery();
    }
  };

  const resizeStart = (): void => setIsResizeMode(true);

  const resizeEnd = (): void => {
    setTimeout(async () => {
      setIsResizeMode(false);

      const { columnsSettings: newColumnsSettings } = updateProfilesTableSettingsState(columnsSettings);
      setColumnsSettings(newColumnsSettings);

      await saveProfilesTableColumns();
    });
  };

  const handleOrbitaUpdateModalClose = (): void => {
    setIsOrbitaUpdateModalVisible(false);
    setAreOrbitaModalButtonsDisabled(false);
    setVersionToUpdate('');
    setLaunchProfileOpts(null);
  };

  const handleOrbitaUpdateModalClick = (): void => {
    setIsOrbitaUpdateModalVisible(false);
    setAreOrbitaModalButtonsDisabled(true);
  };

  const handleOrbitaUpdateModalCancel = async (): Promise<void> => {
    if (!launchProfileOpts) {
      return;
    }

    handleOrbitaUpdateModalClick();
    setAreOrbitaModalButtonsDisabled(false);
    await launchProfileOrbita(launchProfileOpts).catch(() => null);
  };

  const handleOrbitaUpdateModalSubmit = async (): Promise<void> => {
    handleOrbitaUpdateModalClick();
    await downloadSpecificOrbitaVersion(versionToUpdate).catch(() => null);
  };

  const handleOrbitaUpdateModalAfterClose = (): void => setAreOrbitaModalButtonsDisabled(false);

  const setTableDataChanges = (pagination: any, filters: IFilter, sorter: Sorter<string>): void => {
    if (!sorter.order) {
      resetProfilesTableSorting();

      return;
    }

    sortProfilesTable(sorter.field, sorter.order);
  };

  const dragProps = {
    onDragEnd: async (fromIndex: number, toIndex: number): Promise<void> => {
      if (toIndex < 0) {
        return;
      }

      const visibleColumns = columnsSettings.filter((col: SingleProfileColumnSetting) => col.visible);
      const fixedColumnsCount = NEW_FEATURES.header ? 0 : 1;
      const colToMove = visibleColumns[fromIndex + fixedColumnsCount];
      const nextCol = visibleColumns[toIndex + fixedColumnsCount];
      if (!nextCol || !colToMove) {
        return;
      }

      let newFromIndex = 0;
      let newToIndex = 0;
      for (let idx = 0; idx < columnsSettings.length; idx += 1) {
        const col = columnsSettings[idx];
        if (col.colName === colToMove.colName) {
          newFromIndex = idx;
        } else if (col.colName === nextCol.colName) {
          newToIndex = idx;
        }
      }

      const newColumns = [...columnsSettings];
      if ((!newFromIndex && newFromIndex !== 0) || (!newToIndex && newToIndex !== 0)) {
        return;
      }

      const [item] = newColumns.splice(newFromIndex, 1);
      newColumns.splice(newToIndex, 0, item);

      setColumnsSettings(newColumns);

      const { columnsOrder } = updateProfilesTableSettingsState(newColumns);

      await saveWorkspaceProfileTableColumnsOrder(workspaceId, columnsOrder);
    },
    nodeSelector: 'th.draggable, .gologin-table-header-column.draggable',
    handleSelector: '.dragHandler',
    ignoreSelector: '.react-resizable-handle, .ant-table-selection-column, .no-drag',
    lineClassName: NEW_FEATURES.header ? 'new-global-drag-line' : 'global-drag-line',
  };

  const getInitialScrollY = (): number | undefined => {
    const profileToScrollY = sessionStorage.getItem('profileToScrollY');
    if (!profileToScrollY) {
      return;
    }

    sessionStorage.removeItem('profileToScrollY');

    return +profileToScrollY;
  };

  if (isUpdatePage) {
    return <UpdateProfilePage />;
  }

  if (areAntStylesLoading) {
    return <LoadingPage />;
  }

  const isLoading = (isIniting && NEW_FEATURES.header) ||
    !tableSettingsLoaded ||
    dropFileStep ||
    initStatus !== 'allowed' ||
    (NEW_FEATURES.objectPool && bootstrapStatus !== 'idle');

  const isUpdateBlocked = initStatus !== 'allowed' ||
    (NEW_FEATURES.objectPool && ['loading-idb-cache', 'loading-remote'].includes(bootstrapStatus));

  let columns = columnsSettings;

  if (!NEW_FEATURES.header) {
    columns = columnsSettings.map((col: SingleProfileColumnSetting, index: number) => ({
      ...col,
      originalColumnIndex: index,
      sorter: (col.sortField && !isResizeMode) ? (): 0 => 0 : false,
      sortOrder: col.sortField && sortField === col.sortField && sortOrder,
      onHeaderCell: (column: SingleProfileColumnSetting) => ({
        width: column.width,
        minWidth: column.minWidth,
        onResize: handleResizeCol(index),
        resizeStart,
        resizeEnd,
      }),
    })).filter((col: SingleProfileColumnSetting) => col.visible);

    columns.push({
      render: () => null,
    });
  }

  let tableData = basicTableEntities;
  if (isResizeMode && basicTableEntities.length) {
    tableData = [basicTableEntities[0]];
  }

  const tableProfileIndexes = getBasicTableEntities().map(basicTableProfile => basicTableProfile.idx);

  const renderStickyElement = (): JSX.Element => (
    <>
      <MultipleOperationsMenu
        launchProfileOrbita={launchProfileOrbita}
        updateProfileFingerprints={updateProfileFingerprints}
        changeProfilesData={changeProfilesData}
        headerRef={headerRef}
      />
      <TableCustomizationMenu />
    </>
  );

  return (
    <>
      <ContainerDashboard
        closeDrawer={closeProfilesSettings}
      >
        <Header
          onSearch={updateOnSearch}
        />
        <BodyContainer>
          <ContainerContent newStyle={!!NEW_FEATURES.header}>
            {NEW_FEATURES.header ? null : (
              <ContainerPreTable>
                {
                  (!selectedTag) ?
                    (
                      <ContainerTableName>
                        <TextTableName>
                          {translation('profiles.title')}
                        </TextTableName>
                      </ContainerTableName>
                    ) : (
                      <>
                        <ContainerTableName>
                          <TitleTag />
                        </ContainerTableName>
                      </>
                    )
                }
              </ContainerPreTable>
            )}
            {isLoading ? (
              <ProfilesTableLoadingSpinner />
            ) : (
              <>
                <ProfileSettings
                  selectedFolder={selectedFolder}
                  launchProfileOrbita={launchProfileOrbita}
                  foldersList={foldersList}
                  updateProfilesList={updateProfilesList}
                  renderDropDownMenu={renderDropDownMenu}
                  updateProfileFingerprints={updateProfileFingerprints}
                  changeProfilesData={changeProfileNames}
                  changeProfilesDataMany={changeProfilesData}
                />
                <OldTableHeaderControls selectedFolder={selectedFolder} changeSelectedFolder={changeSelectedFolder} />
                {profilesQueryLoadingStatus === 'initing' ? (
                  <div style={{ textAlign: 'center' }}>
                    <IconSpinner size={32} padding={0} />
                  </div>
                ) : (
                  <ReactDragListView.DragColumn {...dragProps}>
                    <SimpleSortableContext
                      items={tableProfileIndexes}
                    >
                      <InfiniteScrollProfilesTable
                        dataLength={basicTableEntities.length}
                        next={fetchMoreProfiles}
                        loader={<CustomTableLoader areColumnsResizing={isResizeMode} />}
                        initialScrollY={getInitialScrollY()}
                      >
                        <div onClick={(event): void => event.stopPropagation()}>
                          <ConfigProvider>
                            <div style={{ position: 'relative' }} onClick={(event): void => event.stopPropagation()}>
                              {!NEW_FEATURES.header ? (
                                <>
                                  <MultipleOperationsMenu
                                    launchProfileOrbita={launchProfileOrbita}
                                    updateProfileFingerprints={updateProfileFingerprints}
                                    changeProfilesData={changeProfilesData}
                                    headerRef={headerRef}
                                  />
                                  <TableCustomizationMenu />
                                </>
                              ) : null}
                              {NEW_FEATURES.header ? (
                                <GologinTable
                                  dataArray={tableData}
                                  columns={columns}
                                  rowSelection={ROW_SELECTION}
                                  resizeStart={resizeStart}
                                  resizeEnd={resizeEnd}
                                  stickyElement={renderStickyElement()}
                                />
                              ) : (
                                <TableProfile
                                  newStyle={!!NEW_FEATURES.header}
                                  isMinWidth={innerWidth <= 1090}
                                  rowSelection={{
                                    type: 'checkbox',
                                    onChange: onUserChangeSelectedProfiles,
                                    selectedRowKeys,
                                    columnWidth: '34px',
                                  }}
                                  onRow={(record: any, rowIndex): any => ({
                                    onClick: (): void => {
                                      onProfilesTableRowClick(rowIndex);
                                    },
                                    onMouseLeave: (event: SyntheticEvent): void => {
                                      if (event.currentTarget.classList.contains('check-in-progress')) {
                                        return;
                                      }

                                      event.currentTarget.classList.remove('row-visible-dot');
                                    },
                                  })}
                                  pagination={false}
                                  rowKey='idx'
                                  dataSource={tableData}
                                  locale={{ emptyText: NEW_FEATURES.header ? null : <NoProfilesPlaceholder /> }}
                                  onChange={setTableDataChanges}
                                  className='profiles-table'
                                  tableLayout='fixed'
                                  components={{
                                    header: {
                                      cell: ResizableTitle,
                                    },
                                  }}
                                  columns={columns}
                                />
                              )}
                            </div>
                          </ConfigProvider>
                        </div>
                      </InfiniteScrollProfilesTable>
                    </SimpleSortableContext>
                  </ReactDragListView.DragColumn>
                )}
                {(NEW_FEATURES.header && !basicTableEntities.length) ? (
                  <NoProfilesPlaceholder />
                ) : null}
                <div onClick={(event): void => event.stopPropagation()}>
                  <OrbitaVersionModals.UpdateOrSkip
                    modalType='orbitaUpdateOrRun'
                    visible={isOrbitaUpdateModalVisible}
                    areButtonsDisabled={areOrbitaModalButtonsDisabled}
                    handleCancel={handleOrbitaUpdateModalCancel}
                    handleSubmit={handleOrbitaUpdateModalSubmit}
                    handleClose={handleOrbitaUpdateModalClose}
                    handleAfterClose={handleOrbitaUpdateModalAfterClose}
                    orbitaVersion={versionToUpdate}
                  />
                  <OrbitaVersionModals.IncompatibleCannotRun
                    visible={isOrbitaIncompatibleCannotRunModalVisible}
                    handleCancel={(): void => setIsOrbitaIncompatibleCannotRunModalVisible(false)}
                  />
                </div>
              </>
            )}
          </ContainerContent>
        </BodyContainer>
        <Helmet>
          <title>
            {translation('profiles.title')}
            {' '}
            - GoLogin
            {' '}
            {window.gologinAppVersion || ''}
          </title>
        </Helmet>
      </ContainerDashboard>
      <ProfilesTableUpdater
        isBlocked={isUpdateBlocked}
        callFetchProfilesByQuery={callFetchProfilesByQuery}
      />
      <PageOverlay />
      <FoldersManager />
      {!firstPlanSelected || NEW_FEATURES.proxyGroupsV2 ? <QuickPricing /> : null}
      <ProfilesTableColumnsModal />
      <RemoveProfileModal />
      <ProxyManager />
      <MultiShareModal />
      <HistoryModal />
      <UpdateProfilesModal />
      <CookiesListModal />
      <TransferProfileModal />
      <DraggingProfilesOverlay />
    </>
  );
};

export default ProfilesListPage;
