import { IProfile } from '../../../interfaces';
import { reconcileProfileToObjectPool } from '../../reconcile-profiles-list';
import type { ObjectPoolTransaction, ObjectPoolProfile } from '../../types';
import { handleAcceptedTransactions } from '../handle-accepted-transaction';

export const acceptCreatedProfiles = async (profiles: IProfile[]): Promise<void> => {
  const profileTransactions = profiles.map<ObjectPoolTransaction<ObjectPoolProfile>>((profile) => {
    const objectPoolProfile = reconcileProfileToObjectPool(profile, null);

    return {
      entity: objectPoolProfile,
      entityType: 'profile',
      transactionType: 'P',
    };
  });

  return handleAcceptedTransactions(profileTransactions);
};
