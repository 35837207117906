import { Folder as FolderIcon } from '@material-ui/icons';
import React from 'react';

import { AddIcon, DoneIcon, FolderName, FolderNameContainer, FolderRow } from './styles';
import { handleFolderProfiles } from '../../../../../../state/folders/profiles-folders.operations';
import { useProfilesTableModalProfiles } from '../../../../../../state/profiles-table-modal.atom';

interface IFolderItem {
  name: string;
  foldersSelected: string[];
}

export const Folder: React.FC<IFolderItem> = (props) => {
  const { name, foldersSelected } = props;

  const modalProfiles = useProfilesTableModalProfiles();

  const modalProfileIds = modalProfiles.map(profile => profile.id);

  const handleClick: React.MouseEventHandler = () => {
    const action = foldersSelected.includes(name) ? 'remove' : 'add';
    handleFolderProfiles(name, modalProfileIds, action);
  };

  return (
    <FolderRow
      onClick={handleClick}
      selected={foldersSelected.includes(name)}
    >
      <FolderNameContainer>
        <FolderIcon />
        <FolderName>
          {name}
        </FolderName>
      </FolderNameContainer>
      {foldersSelected.includes(name) ? <DoneIcon /> : <AddIcon />}
    </FolderRow>
  );
};
