import { UserProxyFormMode } from "../../../../interfaces";

export const PROXY_EDIT_FORM_TABS = <const>{
  geoProxy: 'geolocation',
  customProxy: 'custom',
};

export const PROXY_EDIT_FORM_TABS_LIST = Object.values(PROXY_EDIT_FORM_TABS);

export type ProxyEditFormTab = typeof PROXY_EDIT_FORM_TABS_LIST[number];

export const PROXY_FORM_TAB_INDEX_MAP: Record<ProxyEditFormTab, number> = {
  geolocation: 0,
  custom: 1,
};
export const USER_PROXY_FORM_MODES: UserProxyFormMode[] = [
  {
    value: 'http',
    label: 'http',
  },
  {
    value: 'socks5',
    label: 'socks 5',
  },
  {
    value: 'socks4',
    label: 'socks 4',
  },
];
