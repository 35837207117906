import css, { SerializedStyles } from '@emotion/css';
import styled from '@emotion/styled/macro';

import { RelativeDropPosition } from './use-profile-sortable';
import { showCheckbox } from '../../../ui/gologin-checkbox/styles';
import { IconWrapperWithDescription } from '../../../ui/gologin-header/icons/wrapper';
import { GologinTableItemCell } from '../../../ui/gologin-table/styles';
import { textFont } from '../../../ui/style-templates';
import { CheckProxyButtonWrapper } from '../../proxy/check-proxy-button/styles';
import { ProxySelectorContentWrapper } from '../../proxy/proxy-selector/styles';
import { AddTagTxt } from '../../tags/components/add-tag-placeholder';
import { IconMeatballsContainer } from '../components/styles';
import { AddNotesContainer } from '../notes-cell/styles';

export const ContainerNameCol = styled('div')<{ isProxy?: boolean; newStyle?: boolean }>`
  flex-direction: row;
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;

  padding: ${(props): string => props.newStyle && props.isProxy ? '0 16px' : '0'};
`;

export const SharedValueContainer = styled(IconWrapperWithDescription)`
  ${textFont}
`;

export const Row = styled('div')`
  display: flex;
  flex-direction: row;
  font-size: 12px;
`;

export const RowNameFolder = styled('div')`
  display: flex;
  align-items: center;
  height: 22px;
`;

export const TextNameFolder = styled('div')`
  font-size: 12px;
  line-height: 14px;
  color: var(--000000-profile-table);
  opacity: 0.8;
  position: absolute;
`;

export const MarginIcon = styled('div')`
  margin-left: 96%;
  cursor: pointer;
`;

export const ColProxyTxt = styled('span')<{ fontColor: string }>`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 4px;
  color: ${(props): string => props.fontColor};
`;

export const ProfilesTabsContainer = styled('section')<{ newStyle: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${(props): string => props.newStyle ? 'end' : 'space-between'};
`;

export const RawProfileRow = styled('div')<{ dropPosition?: RelativeDropPosition; isDragging?: boolean }>`
  display: flex;
  position: relative;

  width: max-content;

  &:hover {
    background: var(--F9F9F9);

    ${showCheckbox()}

    ${IconMeatballsContainer} {
      visibility: visible;
    }

    ${CheckProxyButtonWrapper} {
      display: flex;
      opacity: 1;
    }

    ${ProxySelectorContentWrapper} {
      z-index: 102;
      background: var(--F9F9F9-proxy-manager-selector);
    }
  }

  &.row-selected {
    background: var(--F9F9F9);

    ${ProxySelectorContentWrapper} {
      background: var(--F9F9F9-proxy-manager-selector);
    }
  }

  ${GologinTableItemCell}:hover {
    ${AddTagTxt} {
      opacity: 1;
    }

    ${AddNotesContainer} {
      opacity: 1;
    }
  }

  &::after, &::before {
    position: absolute;
    display: block;
    left: 0px;
    right: 0px;
    height: 0px;
    width: 100%;
    transition: background-color 0.3s;
    content: "";
    z-index: 140;
  }

  &::after {
    border-bottom: 0.5px solid var(--E5E5E6-profile-table);
    bottom: 0px;
    z-index: 123;
    ${(props): SerializedStyles|'' => props.dropPosition === 'bottom' ? css`
      border-bottom: 3px solid var(--535358);
    ` : ''}
  }

  &::before {
    top: 0px;
    visibility: hidden;

    ${(props): SerializedStyles|'' => props.dropPosition === 'top' ? css`
      visibility: visible;
      border-bottom: 3px solid var(--535358);
    ` : ''}
  }

  ${(props): SerializedStyles|'' => props.isDragging ? css`
    & > div, & > section, & > span {
      opacity: 0.3;
     }
  `: ''}
`;

export const TextCell = styled('div')`
  text-overflow: ellipsis;
  overflow-x: clip;
`;
