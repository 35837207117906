import React from 'react';
import { Trans } from 'react-i18next';

import {
  toggleGeoProxyAdvancedSettingsVisibility,
  useAreGeoProxyAdvancedSettingsVisible,
} from '../../../../../../state/proxy/proxy-form-tabs.atom';
import { IconArrow } from '../../../../../../ui/gologin-header/icons';
import {
  AdvancedGeoProxySettingsButtonIconWrapper,
  AdvancedGeoProxySettingsButtonText,
  AdvancedGeoProxySettingsButtonWrapper,
} from './styles';

const AdvancedGeoProxySettingsButton: React.FC = () => {
  const areGeoProxyAdvancedSettingsVisible = useAreGeoProxyAdvancedSettingsVisible();
  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();
    toggleGeoProxyAdvancedSettingsVisibility();
  };

  return (
    <AdvancedGeoProxySettingsButtonWrapper onClick={handleButtonClick}>
      <AdvancedGeoProxySettingsButtonText>
        <Trans i18nKey='proxyManager.proxyForm.geoProxy.moreSettings' />
      </AdvancedGeoProxySettingsButtonText>
      <AdvancedGeoProxySettingsButtonIconWrapper>
        <IconArrow
          padding={0}
          iconColor='var(--B5B5BA)'
          transform={areGeoProxyAdvancedSettingsVisible ? 'rotate(90deg)' : 'rotate(0deg)'}
        />
      </AdvancedGeoProxySettingsButtonIconWrapper>
    </AdvancedGeoProxySettingsButtonWrapper>
  );
};

export default AdvancedGeoProxySettingsButton;
