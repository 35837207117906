import * as Sentry from '@sentry/react';
import { atom, getDefaultStore, useAtomValue } from 'jotai';

import { calculateRemainingAllTypesTrafficLimitsObject } from '../../../common/proxy/traffic/utils';
import { COUNTRIES_WITH_FLAGS } from '../../../flag-icons';
import { getAllVpnUfoCountries } from '../../features/profileSettingsComponents/proxyTab/api';
import { VpnUfoCountryWithTypes } from '../../features/profileSettingsComponents/proxyTab/vpn-ufo.types';
import {
  COUNTRY_HAS_NO_FLAG_ERROR,
  COUNTRY_HAS_NO_FLAG_ERROR_MESSAGE,
  LOAD_GEOPROXY_COUNTRIES_TRANSACTION,
  PROXY_COUNTRY_TAG,
} from '../../features/proxy/constants';
import { sendReactErrorToSentry } from '../../utils/sentry.helper';
import { trafficDataAtom } from './traffic-data.atom';

type CountryCode = string;
type CityName = string;
type CitiesByCountry = Record<CountryCode, CityName[]>;

export const availableProxiesAtom = atom(get => {
  const countries = get(geoProxyCountriesAtom);
  const trafficData = get(trafficDataAtom);

  const remainingTraffic = calculateRemainingAllTypesTrafficLimitsObject(trafficData);

  return countries.filter(country => country.types.some(type => remainingTraffic[type]));
});

export const unavailableProxiesAtom = atom(get => {
  const countries = get(geoProxyCountriesAtom);
  const trafficData = get(trafficDataAtom);

  const remainingTraffic = calculateRemainingAllTypesTrafficLimitsObject(trafficData);

  return countries.filter(country => !country.types.some(type => remainingTraffic[type]));
});

export const allCitiesListByCountryAtom: Record<CountryCode, CityName[]> = {};
export const citiesByCountryAtom = atom(get => {
  const geoProxyCountries = get(geoProxyCountriesAtom);

  return geoProxyCountries.reduce((acc, country) => {
    if (country.cityList && country.cityList.length > 0) {
      acc[country.countryCode] = country.cityList;
    }
    return acc;
  }, {} as CitiesByCountry);
});

export const geoProxyCountriesAtom = atom<VpnUfoCountryWithTypes[]>([]);

export const getCitiesByCountry = (): CitiesByCountry => getDefaultStore().get(citiesByCountryAtom);

export const useAvailableProxies = (): VpnUfoCountryWithTypes[] => useAtomValue(availableProxiesAtom);
export const useUnavailableProxies = (): VpnUfoCountryWithTypes[] => useAtomValue(unavailableProxiesAtom);
export const useCitiesByCountry = (): CitiesByCountry => useAtomValue(citiesByCountryAtom);
export const useIsProxyAvailable = (proxyCountryCode: string): boolean => {
  const availableProxies = useAvailableProxies();
  return availableProxies.some(proxy => proxy.countryCode === proxyCountryCode);
};

export const setGeoProxyCountries = (countries: VpnUfoCountryWithTypes[]): void =>
  getDefaultStore().set(geoProxyCountriesAtom, countries);

export const useGeoProxyCountries = (): VpnUfoCountryWithTypes[] => useAtomValue(geoProxyCountriesAtom);

const updateCitiesByCountry = (countries: VpnUfoCountryWithTypes[]): void => {
  countries.forEach(country => {
    if (country.cityList && country.cityList.length > 0) {
      allCitiesListByCountryAtom[country.countryCode] = country.cityList;
    }
  });
};

export const useGeoProxyCountriesAvailability = (): {
  availableProxies: VpnUfoCountryWithTypes[];
  unavailableProxies: VpnUfoCountryWithTypes[];
  citiesByCountry: CitiesByCountry;
} => {
  const availableProxies = useAvailableProxies();
  const unavailableProxies = useUnavailableProxies();
  const citiesByCountry = useCitiesByCountry();

  return { availableProxies, unavailableProxies, citiesByCountry };
};

export const updateGeoProxyCountries = (countries: VpnUfoCountryWithTypes[]): void => {
  const countriesWithFlags = countries.filter(({ countryCode }) => {
    if (COUNTRIES_WITH_FLAGS.includes(countryCode.toLowerCase())) {
      return true;
    }

    sendReactErrorToSentry({
      transactionName: COUNTRY_HAS_NO_FLAG_ERROR,
      message: COUNTRY_HAS_NO_FLAG_ERROR_MESSAGE,
      tags: [[PROXY_COUNTRY_TAG, countryCode]],
    });

    return false;
  });

  setGeoProxyCountries(countriesWithFlags);
  updateCitiesByCountry(countriesWithFlags);
};

export const loadGeoProxyCountries = async (): Promise<void> => {
  const transaction = Sentry.startTransaction({ name: LOAD_GEOPROXY_COUNTRIES_TRANSACTION });

  const countries = await getAllVpnUfoCountries();
  updateGeoProxyCountries(countries);

  transaction.finish();
};

export const clearGeoProxyCountries = (): void => {
  setGeoProxyCountries([]);
};
