import React from 'react';
import { Trans } from 'react-i18next';

import { ProxySection } from '../../../../../state/proxy/proxy-groups/types';
import { ProxySectionContextMenu } from './proxy-section-context-menu';
import { HorizontalLine, ProxySectionItemTitle, ProxySectionItemWrapper } from './styles';

const PROXY_SECTION_TEXT = {
  availableProxies: 'proxyManager.groupsSection.available',
  unavailableProxies: 'proxyManager.groupsSection.unavailable',
} as const;

type ProxySectionItemProps = Pick<ProxySection, 'id' | 'title' | 'isContextMenuEnabled'> & {
  style: React.CSSProperties;
};

const ProxySectionItem: React.FC<ProxySectionItemProps> = props => {
  const { title, isContextMenuEnabled, style } = props;

  const finalStyle: React.CSSProperties = {
    ...style,
    height: 30,
    margin: '1px 4px',
  };

  return (
    <ProxySectionItemWrapper style={finalStyle} >
      {title === 'unavailableProxies' ? <HorizontalLine /> : null}
      <ProxySectionItemTitle>
        <Trans i18nKey={PROXY_SECTION_TEXT[title]} />
      </ProxySectionItemTitle>
      {isContextMenuEnabled ? <ProxySectionContextMenu /> : null}
    </ProxySectionItemWrapper>
  );
};

export default ProxySectionItem;
