import { setBootstrapStatus } from './bootstrap-status.atom';
import { performCacheBootstrap } from './perform-cache-bootstrap';
import { performRemoteBootstrap } from './perform-remote-bootstrap';
import { setLastSyncDate } from '../delta-sync/last-sync-date.atom';
import { initCacheDBName } from '../idb-cache/cache-db-name.atom';

const CACHE_DB_VERSION = 1;

// seconds to always opt for remote boostrap instead of cache
// TODO: somehow decide on the backend
const REMOTE_BOOTSTRAP_THRESHOLD_MS = 1000 * 3600 * 24 * 7; // week

export const performBootstrap = async (userId: string, workspaceId: string): Promise<void> => {
  const { lastSync } = await initCacheDBName({ userId, workspaceId, version: CACHE_DB_VERSION });
  const now = new Date();
  const lastSyncDate = new Date(lastSync.lastSyncedTimestamp);
  if (+now - lastSync.lastSyncedTimestamp > REMOTE_BOOTSTRAP_THRESHOLD_MS) {
    setBootstrapStatus('loading-remote');
    await performRemoteBootstrap();
    setBootstrapStatus('idle');

    return;
  }

  setBootstrapStatus('loading-idb-cache');
  const { status } = await performCacheBootstrap();
  if (status === 'success') {
    setLastSyncDate(lastSyncDate);
    setBootstrapStatus('catch-up-remote');
  } else {
    setBootstrapStatus('loading-remote');
  }

  await performRemoteBootstrap();
  setBootstrapStatus('idle');
};
