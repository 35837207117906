import React, { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { CellMeasurer, CellMeasurerCache, List, ListRowRenderer, WindowScroller } from 'react-virtualized';

import 'react-virtualized/styles.css';
import GologinTableGroupHeader from './gologin-table-group-header';
import GologinTableHeader from './gologin-table-header';
import { IResizeProps } from './gologin-table-header-column';
import { GologinTableLoaderRow } from './gologin-table-loader-row';
import GologinTableRow from './gologin-table-row';
import GologinTableRowSelectionCell from './row-selection-cell';
import { GologinTableContainer, GologinTableItemList, HeaderWrapper, RadiusHeaderWrapper, TableCustomizationWrapper } from './styles';
import overscanIndicesGetter from './virtual-overscan-indices-getter';
import TableCustomizationSettingsButton from '../../features/quickProfiles/table-customization-menu/settings-button';
import useElementWidth from '../../hooks/use-element-width.hook';
import {
  SingleProfileColumnSetting,
  useColumnsSettings,
  setColumnsSettings,
} from '../../state/columns-settings.atom';
import { useLayoutHeights } from '../../state/layout-heights.atom';
import {
  IBasicTableEntity,
  isBasicTableEntityGroupHeader,
  isBasicTableEntityLoaderRow,
} from '../../state/profiles-table/basic-table-entities-subtypes';
import { useIsProfilesTableGrouped } from '../../state/profiles-table/profiles-table-group-field.atom';
import { handleToggleProfileIsSelectedByIdx, useIsProfileSelected, useProfilesTableSelectedIds } from '../../state/profiles-table-selected-ids.atom';

const cellMeasurerCache = new CellMeasurerCache({
  defaultHeight: 52,
  fixedWidth: true,
});

interface IGologinTableRowSelection {
  columnWidth: number;
}

type IDataArrayItem = IBasicTableEntity;

const FIRST_COL_NAME: SingleProfileColumnSetting['colName'] = 'name';

interface IGologinTable extends IResizeProps {
  dataArray: IDataArrayItem[];
  columns: SingleProfileColumnSetting[];
  rowSelection: IGologinTableRowSelection;
  stickyElement?: ReactElement;
}

const GologinTable: FC<IGologinTable> = props => {
  const { dataArray, rowSelection, stickyElement, ...columnProps } = props;

  const rootElem = document.getElementById('root');
  const [hasBorderRadius, setHasBorderRadius] = useState(true);

  const { header } = useLayoutHeights();
  const { columnsSettings } = useColumnsSettings();
  const headerContainerRef = useRef<HTMLDivElement>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableContainerWidth = useElementWidth(tableContainerRef);
  const isProfilesTableGrouped = useIsProfilesTableGrouped();
  const selectedProfilesIds = useProfilesTableSelectedIds();
  const headerHeightTop = header + 1;

  let rowIndent = 0;
  if (isProfilesTableGrouped) {
    rowIndent = 12;
  }

  const selectionColumnWidth = rowSelection.columnWidth + rowIndent;

  const selectionColumn: SingleProfileColumnSetting = useMemo<SingleProfileColumnSetting>(
    () => ({
      colName: 'selection' as any,
      render: (_: unknown, record: IBasicTableEntity) => (
        <GologinTableRowSelectionCell
          useIsSelected={useIsProfileSelected}
          rowKey={record.idx}
          onToggle={handleToggleProfileIsSelectedByIdx}
          rowIndent={rowIndent}
        />
      ),
      width: selectionColumnWidth,
      minWidth: selectionColumnWidth,
      headerWidth: rowSelection.columnWidth,
      label: '',
      visible: true,
      className: 'new-selection-cell',
    }),
    [rowSelection, rowIndent],
  );

  const shownColumns = useMemo(() => {
    const preparedColumns = columnsSettings.map((col, idx) => ({
      ...col,
      originalColumnIndex: idx,
      headerWidth: rowIndent && col.colName === FIRST_COL_NAME ? (col.headerWidth || col.width) + rowIndent : null,
    }));

    return [selectionColumn, ...preparedColumns].filter(column => column.visible);
  }, [selectionColumn, columnsSettings]);

  const totalWidth = useMemo(() => {
    const columnsWidth = shownColumns.reduce<number>((acc, column) => acc + column.width, 0);

    return Math.max(columnsWidth, tableContainerWidth);
  }, [shownColumns, tableContainerWidth]);

  const rowRenderer: ListRowRenderer = rowProps => {
    const { index, key, style, parent } = rowProps;
    const dataItem = dataArray[index];
    if (!dataItem) {
      return null;
    }

    if (isBasicTableEntityGroupHeader(dataItem)) {
      return (
        <CellMeasurer key={key} cache={cellMeasurerCache} columnIndex={0} rowIndex={index} parent={parent}>
          {({ measure }): JSX.Element => (
            <GologinTableGroupHeader groupHeaderItem={dataItem} measure={measure} style={style} rowIdx={index} />
          )}
        </CellMeasurer>
      );
    }

    if (isBasicTableEntityLoaderRow(dataItem)) {
      return (
        <CellMeasurer key={key} cache={cellMeasurerCache} columnIndex={0} rowIndex={index} parent={parent}>
          {({ measure }): JSX.Element => (
            <GologinTableLoaderRow measure={measure} style={style} tableContainerWidth={tableContainerWidth} />
          )}
        </CellMeasurer>
      );
    }

    return (
      <CellMeasurer key={key} cache={cellMeasurerCache} columnIndex={0} rowIndex={index} parent={parent}>
        {({ measure }): JSX.Element => (
          <GologinTableRow
            columns={shownColumns}
            dataItem={dataItem}
            useIsSelected={useIsProfileSelected}
            index={index}
            key={key}
            style={style}
            measure={measure}
          />
        )}
      </CellMeasurer>
    );
  };

  const handleResizeColumn: IResizeProps['onResize'] = (columnIndex, data, colName?: string) => {
    if (!colName) {
      return;
    }

    const column = columnsSettings[columnIndex];
    let properData = data;
    if (column.colName === FIRST_COL_NAME && rowIndent) {
      const properWidth = data.size.width - rowIndent;
      properData = {
        ...properData,
        size: { ...properData.size, width: properWidth },
      };
    }

    const newColumns = columnsSettings.map(col => {
      if (col.colName !== column.colName) {
        return col;
      }

      return {
        ...col,
        width: properData.size.width,
      };
    });

    setColumnsSettings(newColumns);
  };

  useEffect(() => {
    const subHeader = document.getElementById('promobar');
    const mainHeader = document.getElementById('main-header');

    if (!subHeader || !mainHeader) {
      return;
    }

    const handleScroll = (): void => {
      const subHeaderRect = subHeader.getBoundingClientRect();
      const headerRect = mainHeader.getBoundingClientRect();
      if (selectedProfilesIds?.length) {
        setHasBorderRadius(false);
      } else {
        setHasBorderRadius(subHeader.hasChildNodes() ? subHeaderRect.bottom < headerRect.bottom : true);
      }
    };

    rootElem?.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      rootElem?.removeEventListener('scroll', handleScroll);
    };
  }, [selectedProfilesIds?.length]);

  useEffect(() => {
    const syncScrollPositions = (source: HTMLElement | null, target: HTMLElement | null): void => {
      if (source && target) {
        target.scrollLeft = source.scrollLeft;
      }
    };

    const handleTableScroll = (): void => {
      syncScrollPositions(tableContainerRef.current, headerContainerRef.current);
    };

    const handleHeaderScroll = (): void => {
      syncScrollPositions(headerContainerRef.current, tableContainerRef.current);
    };

    const tableRef = tableContainerRef.current;
    const headerRef = headerContainerRef.current;
    tableRef?.addEventListener('scroll', handleTableScroll);
    headerRef?.addEventListener('scroll', handleHeaderScroll);

    return () => {
      tableRef?.removeEventListener('scroll', handleTableScroll);
      headerRef?.removeEventListener('scroll', handleHeaderScroll);
    };
  }, []);

  return (
    <>
      <RadiusHeaderWrapper data-sentry-unmask headerHeight={headerHeightTop} hasBorderRadius={hasBorderRadius}>
        {stickyElement}
        <HeaderWrapper ref={headerContainerRef} hasBorderRadius={hasBorderRadius}>
          <GologinTableHeader {...columnProps} columns={shownColumns} onResize={handleResizeColumn} />
        </HeaderWrapper>
        <TableCustomizationWrapper>
          <TableCustomizationSettingsButton />
        </TableCustomizationWrapper>
      </RadiusHeaderWrapper>
      <GologinTableContainer ref={tableContainerRef}>
        <GologinTableItemList role='treegrid'>
          {rootElem ? (
            <WindowScroller scrollElement={rootElem}>
              {({ height, isScrolling, onChildScroll, scrollTop }: any): JSX.Element => (
                <List
                  tabIndex={null}
                  autoHeight={true}
                  height={height}
                  isScrolling={isScrolling}
                  onScroll={onChildScroll}
                  width={totalWidth}
                  rowCount={dataArray.length}
                  deferredMeasurementCache={cellMeasurerCache}
                  rowHeight={cellMeasurerCache.rowHeight}
                  rowRenderer={rowRenderer}
                  scrollTop={scrollTop}
                  overscanRowCount={8}
                  overscanIndicesGetter={overscanIndicesGetter}
                />
              )}
            </WindowScroller>
          ) : null}
        </GologinTableItemList>
      </GologinTableContainer>
    </>
  );
};

export default React.memo(GologinTable);
