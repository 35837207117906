import { atom, useAtomValue } from 'jotai';

import { findProxyInList } from '../../features/proxy/utils/find-proxy-in-list';
import { IProxy } from '../../interfaces';
import { getProfilesList } from '../profiles-list.atom';
import { proxyListAtom } from './proxy-list.atom';
import { proxyManagerStateAtom } from './proxy-manager-modal-status.atom';

export const currentProfileProxyAtom = atom<IProxy | null>(get => {
  const { currentProxy, currentProfileId } = get(proxyManagerStateAtom);
  if (!currentProxy || currentProxy.mode === 'none' || !currentProfileId) {
    return null;
  }

  const proxyList = get(proxyListAtom);
  const currentProfileProxy = findProxyInList(currentProxy, proxyList);
  if (currentProfileProxy) {
    return currentProfileProxy;
  }

  const currentProfile = getProfilesList().find(profile => profile.id === currentProfileId);
  if (currentProfile && currentProfile.role !== 'owner') {
    return { ...currentProxy, isInvisible: true };
  }

  return currentProxy;
});

export const useCurrentProfileProxy = (): IProxy | null => useAtomValue(currentProfileProxyAtom);
