import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Folders from './folders';
import FolderCreate from './folders/folder-create';
import { useFoldersList } from '../../../../state/folders/folders-list.atom';
import {
  closeProfilesTableModal,
  FOLDERS_MANAGER_MODAL_NAME,
  useProfilesTableModalIsVisible,
} from '../../../../state/profiles-table-modal.atom';
import GologinModal from '../../../../ui/gologin-modal';

const FoldersManager = (): JSX.Element => {
  const [searchFolderName, setSearchFolderName] = useState('');
  const { t: translation } = useTranslation();

  const isModalVisible = useProfilesTableModalIsVisible(FOLDERS_MANAGER_MODAL_NAME);
  const foldersList = useFoldersList();

  const handleCancel = (): void => {
    closeProfilesTableModal();
    setSearchFolderName('');
    sessionStorage.removeItem('profilesDeleted');
  };

  const handleSearchInputChange: React.FormEventHandler<HTMLInputElement> = (event) => setSearchFolderName(event.currentTarget.value);

  return (
    <GologinModal
      bodyStyle={{
        height: 253,
        overflow: 'auto',
        paddingTop: 16,
        zIndex: 1001,
      }}
      onClose={handleCancel}
      title={translation('foldersManagerModal.title')}
      isVisible={isModalVisible}
      width='416px'
    >
      {foldersList.length ? (
        <Folders
          searchFolderName={searchFolderName}
          handleSearchInputChange={handleSearchInputChange}
        />
      ) : <FolderCreate />}
    </GologinModal>
  );
};

export default FoldersManager;
