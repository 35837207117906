import { css, SerializedStyles } from '@emotion/core';
import styled from '@emotion/styled';

import { GologinPopoverItemRowBasic } from '../../gologin-popover/gologin-popover-item-row';
import { textFont } from '../../style-templates';

export const ItemRow = styled(GologinPopoverItemRowBasic)<{ hasPadding?: boolean }>`
  width: 204px;
  height: 32px;

  ${(props): SerializedStyles | null => props.hasPadding ? css`
    padding: 6px 16px;

    :hover {
      background: var(--account-menu-item-hovered-background);
    }
  ` : null}
`;

export const MenuHeaderItem = styled('div')<{ isActive?: boolean; isFromOldHeader?: boolean }>`
  width: 204px;
  border-radius: 4px;

  ${(props): SerializedStyles | null => !props.isFromOldHeader ? css`
    padding: 6px 16px 0;
    background: var(--FFFFFF-header-account-menu);
    ${props.isActive ? css`
      cursor: pointer;

      :hover {
        background: var(--account-menu-item-hovered-background);
      }
    ` : null}
  ` : null}
`;

export const MenuRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DescriptionPlan = styled('div')`
  ${textFont};
  color: var(--81818A-header-account-menu);
  line-height: 20px;
  letter-spacing: 0.2px;
  white-space: nowrap;
  display: grid;
`;

export const ContainerDescription = styled.div`
  display: grid;
  padding: 6px 16px 6px 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
`;

export const WorkspaceContainerDescription = styled(ContainerDescription)`
  padding-top: 0;
`;

export const MenuRowText = styled.span`
  ${textFont};
  color: var(--2B2B31-header-account-menu);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
