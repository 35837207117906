import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { message } from 'antd';
import { GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS } from '../../../../../../common/constants/analytics';
import { GeoProxyType } from '../../../../../../common/constants/types';
import { NEW_FEATURES } from '../../../../../state/feature-toggle/new-features';
import { hideProxyContextMenu } from '../../../../../state/proxy-select-menu.atom';
import { useDefaultGeoProxy } from '../../../../../state/proxy/default-geoproxy.atom';
import { updateGeoProxyForm } from '../../../../../state/proxy/geoproxy-form-data.atom';
import { PROXY_CHECK_TOOLTIP_LOCATIONS } from '../../../../../state/proxy/proxy-check/constants';
import { useProxyContextMenuProxy } from '../../../../../state/proxy/proxy-context-menu.atom';
import { updateProxyFormTab } from '../../../../../state/proxy/proxy-form-tabs.atom';
import { openProxyManager, useProxyManagerState } from '../../../../../state/proxy/proxy-manager-modal-status.atom';
import { createGeoProxy } from '../../../../../state/proxy/proxy-operations/create-geoproxy.operations';
import { useTrafficData } from '../../../../../state/proxy/traffic-data.atom';
import { DivAnchor } from '../../../../../types';
import IconProxyPlus from '../../../../../ui/gologin-header/icons/icon-proxy-plus';
import { sendActionAnalytics } from '../../../../common/api';
import { DEFAULT_SELECTED_GEOPROXY_COUNTRY } from '../../../constants';
import { generateProxyAnalyticsData } from '../../../proxy-helpers';
import ProxyGroupAddButtonMenu from './proxy-group-add-button-menu';
import { ProxyGroupAddButtonWrapper } from './styles';

export type ProxyGroupAddButtonProps = {
  country: string;
  groupId: string;
  availableTypes: GeoProxyType[];
  style: React.CSSProperties;
};

const ProxyGroupAddButton: React.FC<ProxyGroupAddButtonProps> = props => {
  const { country = DEFAULT_SELECTED_GEOPROXY_COUNTRY, groupId, availableTypes, style } = props;

  const proxyContextMenuProxy = useProxyContextMenuProxy();
  const trafficData = useTrafficData();
  const { connectionType: geoProxyLastSelectedType } = useDefaultGeoProxy();
  const { currentProfileId, containerElement, proxySelectorLocation } = useProxyManagerState();

  const [isArrowHovered, setIsArrowHovered] = useState<boolean>(false);
  const [proxyGroupAddButtonMenuAnchorEl, setProxyGroupAddButtonMenuAnchorEl] = useState<DivAnchor>(null);

  const toggleProxyGroupAddButtonMenuAnchorEl = (newAnchorEl: DivAnchor): void => {
    const updatedAnchorEl = proxyGroupAddButtonMenuAnchorEl ? null : newAnchorEl;
    setProxyGroupAddButtonMenuAnchorEl(updatedAnchorEl);
  };

  const closeProxyGroupAddButtonMenu = (): void => {
    setProxyGroupAddButtonMenuAnchorEl(null);
  };

  const isHighlighted = !!proxyGroupAddButtonMenuAnchorEl;

  const handleClick: React.MouseEventHandler<HTMLDivElement> = async () => {
    if (proxyContextMenuProxy) {
      hideProxyContextMenu();
    }

    if (!!NEW_FEATURES.proxyEditForm) {
      updateGeoProxyForm({ country, city: '' });
      updateProxyFormTab('geolocation');

      openProxyManager({
        containerElement: containerElement,
        modalView: 'proxy-add',
        currentProxy: null,
        modalEditingProxyId: null,
        currentProfileId: currentProfileId,
        proxySelectorLocation: proxySelectorLocation,
      });

      return;
    }

    const geoProxyFormSubmitResult = await createGeoProxy({
      groupId,
      country,
      selectedConnectionType: geoProxyLastSelectedType,
      availableConnectionTypes: availableTypes,
      profileId: '',
      trafficData,
      checkTooltipView: PROXY_CHECK_TOOLTIP_LOCATIONS.proxyGroupedListItem,
    });

    if (!geoProxyFormSubmitResult) {
      return;
    }

    if (typeof geoProxyFormSubmitResult === 'string') {
      return message.error(<Trans i18nKey={geoProxyFormSubmitResult} />);
    }

    const proxyAnalyticsData = generateProxyAnalyticsData(geoProxyFormSubmitResult);
    sendActionAnalytics(
      GROUPED_PROXY_MANAGER_ANALYTICS_EVENTS.createdLocationViaBuiltInProxyClickedOnAddServerInExpandedGroup,
      proxyAnalyticsData,
    );
  };

  return (
    <ProxyGroupAddButtonWrapper
      onClick={handleClick}
      isAlwaysOpaque={true}
      isTextInactive={isArrowHovered}
      isHighlighted={isHighlighted}
      style={style}
    >
      <IconProxyPlus padding={0} iconColor='var(--98989F-header)' margin='0 8px 0 0' />
      <span>
        <Trans i18nKey='proxyManager.proxyGroupAddButton' />
      </span>
      {!!NEW_FEATURES.proxyEditForm ? null : (
        <ProxyGroupAddButtonMenu
          groupId={groupId}
          country={country}
          defaultType={geoProxyLastSelectedType}
          availableTypes={availableTypes}
          proxyGroupAddButtonMenuAnchorEl={proxyGroupAddButtonMenuAnchorEl}
          toggleProxyGroupAddButtonMenuAnchorEl={toggleProxyGroupAddButtonMenuAnchorEl}
          closeProxyGroupAddButtonMenu={closeProxyGroupAddButtonMenu}
          setIsArrowHovered={setIsArrowHovered}
        />
      )}
    </ProxyGroupAddButtonWrapper>
  );
};

export default ProxyGroupAddButton;
